import * as React from "react";
import { CircularProgress, makeStyles, Theme, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sbmloader: {
      color: "#0982d2"
    }
  })
);

const Loader = () => {
  const classes = useStyles({});
  return (
    <div className='loading-div'>
      <CircularProgress className={classes.sbmloader}></CircularProgress>
      <div style={{ marginLeft: "20px" }}>Laster...</div>
    </div>
  );
};

export default Loader as any;
