import { SlideTemplate } from "../store/models/SlideTemplate";
import { SortableList } from "./SortableItem";
import React from "react";

export class SortableComponent extends React.Component {
  render() {
    let slides = [];
    let sections = [];
    if (this.props.isMeetingTemplate) {
      sections = this.props.sections.sort(function(a, b) {
        return a.positionInMeetingTemplate - b.positionInMeetingTemplate;
      });

      sections.forEach((section) => {
        if (!section.slides.some((s) => s.isSectionSlide)) {
          let secSlide = new SlideTemplate();
          secSlide.header = section.topic.name;
          secSlide.meetingTemplateSectionId = section.id;
          secSlide.isSectionSlide = true;
          secSlide.mark = false;
          slides.push(secSlide);
        }
        slides = slides.concat(section.slides);
        slides[slides.length - 1].isLastSlide = true;
      });
    } else {
      sections = this.props.sections.sort(function(a, b) {
        return a.positionInMeeting - b.positionInMeeting;
      });

      sections.forEach((section) => {
        if (!section.slides.some((s) => s.isSectionSlide)) {
          let secSlide = {};
          secSlide.header = section.topic.name;
          secSlide.meetingSectionId = section.id;
          secSlide.isSectionSlide = true;
          secSlide.mark = false;
          slides.push(secSlide);
        }
        slides = slides.concat(section.slides);
        slides[slides.length - 1].isLastSlide = true;
      });
    }

    let counter = 0;
    slides.forEach((s) => {
      s.dragId = counter;
      counter++;
    });
    
    return (
      <SortableList
        activeId={this.props.activeId}
        updateState={this.props.updateState}
        state={this.props.state}
        distance={5}
        items={slides}
        isMeetingTemplate={this.props.isMeetingTemplate}
        meetingId={this.props.meetingId}
        onSortEnd={this.props.onSortEnd}
        onSortStart={this.props.onSortStart}
        deleteClick={this.props.deleteClick}
        redirect={this.props.redirect}
        axis='xy'
      />
    );
  }
}
