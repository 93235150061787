import React, { useEffect, useState, forwardRef } from "react";
import { withStyles } from "@material-ui/core";
import { Modal, Typography, Box, List, ListItem, IconButton, Button, ListItemIcon, ListItemText, Grid, ListItemSecondaryAction, Divider, TextField, ButtonGroup, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import { connect } from "react-redux";
import * as MeetingsStore from "../../../store/Meeting/MeetingsStore";
import api from "../../../utils/api";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Edit from "@material-ui/icons/Edit";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Remove from "@material-ui/icons/Remove";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import PlayCircleFilled from "@material-ui/icons/PlayCircleFilled";
import ViewCarousel from "@material-ui/icons/ViewCarousel";
import CheckCircle from "@material-ui/icons/CheckCircle";
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import Search from "@material-ui/icons/Search";
import { useHistory } from "react-router-dom";
import moment from "moment";
import TextFieldsIcon from '@material-ui/icons/TextFields';
import CloseIcon from '@material-ui/icons/Close';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Remove: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
};

const Blue_ViewCarousel = withStyles({
  root: { color: '#0066FF' }
})(ViewCarousel);

const BPlayCircleFilled = withStyles({
  root: { color: '#54C88E' }
})(PlayCircleFilled);

const BPlayCircleFilledStarted = withStyles({
  root: { color: '#03a9f4' }
})(PlayCircleFilled);

const BCheckCircle = withStyles({
  root: { color: '#A39576' }
})(CheckCircle);
const BPlaylistAddIcon = withStyles({
  root: { color: '#3d87a7' }
})(PlaylistAddIcon)

const tableCols = [
  {
    title: "Kunde",
    field: "customerName",
  },
  {
    title: "Møtemal",
    field: "meetingTemplateName",
    editable: 'never',
  },
  {
    title: "Dato",
    field: "meetingDate",
    type: "datetime",
    render: rowData => moment(rowData.meetingDate).format("DD.MM.YYYY HH:mm")
  },
  {
    title: "Rådgiver",
    field: "userName",
  },
  {
    title: "Kontor",
    field: "office",
  },
  {
    title: "Tittel",
    field: "title",
  },
  {
    title: "E-post",
    field: "email",
  },
  {
    title: "Tlf.",
    field: "phone",
  },
];

const UpcommingComponent = (props) => {
  const history = useHistory();

  const getMeetings = () => {
    props.requestUpcomingMeetings();
  };

  useEffect(() => {
    props.requestUpcomingMeetings();
  }, []);

  const endMeeting = (id) => {
    const url = `${process.env.REACT_APP_API}/meetings/endmeeting/${id}`;
    api
      .get(url)
      .then((response) => response.data)
      .then(() => {
        getMeetings();
        props.requestPastMeetings();
      });
  };

  const goToMap = (meeting) => {
    history.push("/meeting/" + meeting.id);
  };

  const startMeeting = (meeting) => {
    history.push("/presentation/" + meeting.id);
  }

  const deleteMeetingHandler = (id) => {
    props.deleteMeeting(id);
  }

  // Agenda modal xxx 
  function getModalStyle() {
    return {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#ffffff',
      padding: '10px 10px 10px',
    };
  }
  const [currentAgendaItems, setAgendaItems] = useState([]);
  const [currentAgendaItem, setAgendaItem] = useState("");
  const [currentMeeting, setMeeting] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);

  const handleOpen = (id) => {
    const meeting = props.meetings.find((m) => m.id == id);
    setMeeting(meeting);
    if (meeting.agendaItems != null) {
      if (!Array.isArray(meeting.agendaItems)) {
        setAgendaItems(JSON.parse(meeting.agendaItems));
      } else {
        setAgendaItems(meeting.agendaItems);
      }
    }
    setOpen(true);
  };
  const handleClose = () => {
    setAgendaItems([]);
    setAgendaItem("");
    setMeeting(null);
    setOpen(false);
  };
  const handleAgendaItemNameChange = (e) => {
    setAgendaItem(e.target.value);
  };
  const addAgendaItem = () => {
    currentAgendaItems.push(currentAgendaItem);
    const newAgendaItems = [...currentAgendaItems];
    setAgendaItems(agendaItems => newAgendaItems);
    currentMeeting.agendaItems = JSON.stringify(newAgendaItems)
    props.saveMeeting(currentMeeting);
    setAgendaItem("");
  };
  const removeAgendaItemByIndex = (index) => {
    const newAgendaItems = currentAgendaItems.filter((item, i) => i !== index);
    setAgendaItems(agendaItems => newAgendaItems);
    currentMeeting.agendaItems = JSON.stringify(newAgendaItems)
    props.saveMeeting(currentMeeting);
  };

  function renderMeetingAgendaModal() {
    return (
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle style={{ width: "450px" }} id='draggable-dialog-title'>
          Dagens møte
        </DialogTitle>
        <DialogContent  >
          <TextField autoFocus label="Agenda for møte" variant="outlined" fullWidth
            value={currentAgendaItem}
            onChange={(e) => handleAgendaItemNameChange(e)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                addAgendaItem()
              }
            }} />
        </DialogContent>
        <DialogContent>
          <List>
            {currentAgendaItems?.map((agendaItem, index) => (
              <ListItem key={index}>
                <ListItemIcon> <TextFieldsIcon /> </ListItemIcon>
                <ListItemText style={{ overflow: 'hidden' }} primary={agendaItem} />
                <ListItemSecondaryAction> <IconButton onClick={() => removeAgendaItemByIndex(index)}> <CloseIcon /> </IconButton> </ListItemSecondaryAction >
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" className="sbmPrimary" onClick={addAgendaItem}>Legg til</Button>
          <Button variant="contained" className="sbmSecondary" onClick={handleClose}>Lukk</Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <>
      <div style={{ marginTop: '30px' }}>
        <MaterialTable
          title='Kommende møter'
          columns={tableCols}
          icons={tableIcons}
          data={props.meetings}
          options={{ paging: true, search: true, actionsColumnIndex: -1 }}
          localization={{
            header: { actions: "" },
            body: { emptyDataSourceMessage: "Ingen møter", editTooltip: "Endre", editRow: { deleteText: "Er du sikker på at du vil slette møtet?" } },
            pagination: { labelDisplayedRows: "{from}-{to} av {count}", labelRowsSelect: "rader" },
            toolbar: { searchPlaceholder: "Søk", searchTooltip: "Søk" }
          }}
          actions={[
            rowData => (
              {
                icon: rowData.hasStarted ? BPlayCircleFilledStarted : BPlayCircleFilled,
                tooltip: rowData.hasStarted ? "Start møte på nytt" : "Start møte",
                onClick: (event, rowData) => startMeeting(rowData),
              }),
            {
              icon: Blue_ViewCarousel,
              tooltip: "Sider",
              onClick: (event, rowData) => goToMap(rowData),
            },
            {
              icon: BPlaylistAddIcon,
              tooltip: "Endre agenda",
              onClick: (event, rowData) => handleOpen(rowData.id),
            },
            {
              icon: BCheckCircle,
              tooltip: "Avslutt møte",
              onClick: (event, rowData) => endMeeting(rowData.id),
            },
          ]}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  if (oldData) {
                    oldData = newData;
                    props.saveMeeting(newData);
                  }
                }, 250);
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  deleteMeetingHandler(oldData.id);
                }, 600);
              })
          }}
        />
        {renderMeetingAgendaModal()}
      </div>
    </>
  );
};

export default connect(
  (state) => ({
    templates: state.templates.meetingTemplates,
    meetings: state.meetings.upcomingMeetings,
  }),
  Object.assign(MeetingsStore.actionCreators)
)(UpcommingComponent);
