import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";

const Home = () => (
  <>
    <div className='home-main'>
      <div className='home-header'>MØTE-lab</div>
      <div className='home-meeting-links'>
        <Link className='main-link' component={RouterLink} to='/meetings'>
          Mine møter
        </Link>
        {localStorage.getItem("isAdmin") === "true" ? (
          <>
            <Link className='main-link' component={RouterLink} to='/admin/meetingtemplates'>
              Administrasjon
            </Link>
            {/* <Link className='main-link' component={RouterLink} to='/statistics'>
              Statistikk
            </Link> */}
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
    <div className='logo-footer'>
      <Link className='main-link' component={RouterLink} to='/'>
        <img alt='logo' src={require("../img/logo.png")} width='60px' height='60px' />
      </Link>
    </div>
    {localStorage.getItem("username") && localStorage.getItem("username") !== "" ? (
      <div className='user-top-div'>
        {localStorage.getItem("isAdmin") === "true" ? (
          <span>{localStorage.getItem("username")}&nbsp;(Administrator)</span>
        ) : (
          <span>{localStorage.getItem("username")}</span>
        )}
      </div>
    ) : (
      <></>
    )}
  </>
);

export default Home;
