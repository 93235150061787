import Button from "@material-ui/core/Button/Button";
import * as React from "react";
import { connect } from "react-redux";
import * as TemplateStore from "../../store/TemplateStore";
import Loader from "../Loader";
import { SortableComponent } from "../SortableComponent";
import "./MeetingTemplateLayout.scss";
import SlideTemplateEdit from "./SlideTemplateEdit";
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, TextField } from "@material-ui/core";

class MeetingTemplateLayout extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { hoverId: undefined, open: false, newTemplateName: null, openDelete: false, openDeleteSlide: false, currentDragId: undefined };
    this.setHoverId = this.setHoverId.bind(this);
    this.deleteSlideOrSectionAndSave = this.deleteSlideOrSectionAndSave.bind(this);
    this.handleDeleteSlideOrSection = this.handleDeleteSlideOrSection.bind(this);
    this.handleSaveBtn = this.handleSaveBtn.bind(this);
    this.redirect = this.redirect.bind(this);
    this.handleTemplateCopyName = this.handleTemplateCopyName.bind(this);
    this.copyTemplate = this.copyTemplate.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.deleteTemplate = this.deleteTemplate.bind(this);
  }

  componentDidMount() {
  }

  handleClose() {
    this.setState({ open: false, newTemplateName: null });
  }

  handleCloseDelete() {
    this.setState({ openDelete: false });
  }

  handleCloseDeleteSlide() {
    this.setState({ openDeleteSlide: false });
  }

  redirect(slide) {
    async function getSlide(cont) {
      if (slide.isSectionSlide) return;
      if (slide.id) return await cont.requestSlideTemplate(slide.id);
      else
        return cont.setSlideTemplate({
          id: 0,
          title: "",
          meetingTemplateSectionId: slide.meetingTemplateSectionId,
          estimatedDuration: 0,
          positionInSection: slide.positionInSection,
          media: [],
          zones: [
            { zoneId: "1", backgroundColor: "rgba(255, 255, 255, 1.0)", backgroundImage: null, backgroundImageName: null },
            { zoneId: "2", backgroundColor: "rgba(255, 255, 255, 1.0)", backgroundImage: null, backgroundImageName: null },
          ],
          texts: [],
          links: [],
          images: [],
          layout: [
            {
              i: "1",
              x: 0,
              y: 50,
              w: 6,
              h: 50,
              maxH: 100,
            },
            {
              i: "2",
              x: 6,
              y: 50,
              w: 6,
              h: 50,
              maxH: 100,
            },
          ],
        });
    }

    getSlide(this.props);
  }

  setHoverId(id) {
    this.setState({ hoverId: id });
  }

  handleDeleteSlideOrSection(e, dragId) {
    e.stopPropagation();
    this.setState({ openDeleteSlide: true, currentDragId: dragId });
  }

  deleteSlideOrSectionAndSave() {
    this.props.removeSlideOrSectionTemplate(this.state.currentDragId);
    this.setState({ openDeleteSlide: false, currentDragId: undefined });
  }

  handleSaveBtn() {
    this.props.saveMeetingTemplate(this.props.meetingTemplate);
  }

  handleTemplateCopyName(e) {
    this.setState({ newTemplateName: e.target.value });
  }

  copyTemplate() {
    this.props.copyTemplate(this.props.meetingTemplate.id, this.state.newTemplateName);
    this.setState({ open: false });
  }

  deleteTemplate() {
    this.props.deleteMeetingTemplate(this.props.meetingTemplate.id);
    this.setState({ openDelete: false });
  }

  render() {
    let durationSum = 0;
    return (
      <>
        <div className='main-meeting-layout'>
          <div className='header'>Sider i presentasjon</div>
          {this.props.loadingMeetingTemplate ? (
            <Loader></Loader>
          ) : this.props.meetingTemplate &&
            this.props.meetingTemplate.sections &&
            this.props.meetingTemplate.sections.length > 0 &&
            !this.props.slideTemplate ? (
            <>
              <div className='meetingTemplateButtonsDiv'>
                <Button
                  variant='contained'
                  type='submit'
                  onClick={() => {
                    this.setState({ open: true });
                  }}
                  style={{ marginRight: "15px" }}
                  className='sbmPrimary wide add-margin-top'>
                  Kopier møtemal
                </Button>
                <Button
                  variant='contained'
                  onClick={() => {
                    this.setState({ openDelete: true });
                  }}
                  type='submit'
                  className='sbmDelete wide add-margin-top'>
                  Slett
                </Button>
              </div>
              <SortableComponent
                updateState={this.setHoverId}
                sections={this.props.meetingTemplate.sections}
                state={this.state}
                isMeetingTemplate={true}
                deleteClick={this.handleDeleteSlideOrSection}
                redirect={this.redirect}
                onSortEnd={this.props.reorderSlideTemplates}
                onSortStart={this.props.markAffectedSlideTemplates}></SortableComponent>

              {this.props.meetingTemplate && this.props.meetingTemplate.sections.length > 0 ? (
                <>
                  <div className='meeting-duration'>
                    <div className='meeting-duration-label'>Antatt varighet:</div>
                    <div className='duration-table-container'>
                      <table className='duration-table-hidden'>
                        <tbody>
                          <tr>
                            {this.props.meetingTemplate.sections.map((s) => {
                              durationSum += s.duration || 0;
                              return (
                                <td
                                  key={s.id + "hidden_section"}
                                  className='hidden-row'
                                  style={{ width: (s.duration / this.props.meetingTemplate.sumDuration) * 100 + "%" }}>
                                  <div className='duration-table-label'>{durationSum} min</div>
                                  <div className='duration-table-label-line'></div>
                                </td>
                              );
                            })}
                          </tr>
                        </tbody>
                      </table>
                      <table className='duration-table'>
                        <tbody>
                          <tr>
                            {this.props.meetingTemplate.sections.map((s) => {
                              return (
                                <td
                                  key={"section_" + s.id}
                                  style={{ width: ((s.duration || 0) / this.props.meetingTemplate.sumDuration) * 100 + "%" }}></td>
                              );
                            })}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </>
          ) : this.props.slideTemplate ? (
            <>
              <SlideTemplateEdit props={this.props.slideTemplate}></SlideTemplateEdit>
            </>
          ) : (
            <></>
          )}
        </div>
        <Dialog
          open={this.state.open}
          onClose={() => {
            this.handleClose();
          }}
          aria-labelledby='draggable-dialog-title'>
          <DialogTitle style={{ width: "450px" }} id='draggable-dialog-title'>
            Angi navn på møtemal
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <TextField id='name' label='Navn' onChange={(e) => this.handleTemplateCopyName(e)} maxLength='255' />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant='contained'
              onClick={() => {
                this.copyTemplate();
              }}
              className='sbmPrimary'>
              Kopier mal
            </Button>
            <Button
              variant='contained'
              autoFocus
              onClick={() => {
                this.handleClose();
              }}
              className='sbmSecondary'>
              Avbryt
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.openDelete}
          onClose={() => {
            this.handleCloseDelete();
          }}
          aria-labelledby='draggable-dialog-title'>
          <DialogTitle style={{ width: "450px" }} id='draggable-dialog-title'>
            Slette møtemal
          </DialogTitle>
          <DialogContent>
            <DialogContentText>Vil du virkelig slette møtemalen?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant='contained'
              onClick={() => {
                this.deleteTemplate();
              }}
              className='sbmDelete'>
              Ja
            </Button>
            <Button
              variant='contained'
              autoFocus
              onClick={() => {
                this.handleCloseDelete();
              }}
              className='sbmSecondary'>
              Avbryt
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.openDeleteSlide}
          onClose={() => {
            this.handleCloseDeleteSlide();
          }}
          aria-labelledby='draggable-dialog-title'>
          <DialogTitle style={{ width: "450px" }} id='draggable-dialog-title'>
            Slette slide eller seksjon
          </DialogTitle>
          <DialogContent>
            <DialogContentText>Vil du virkelig slette sliden/seksjonen?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant='contained'
              onClick={() => {
                this.deleteSlideOrSectionAndSave();
              }}
              className='sbmDelete'>
              Ja
            </Button>
            <Button
              variant='contained'
              autoFocus
              onClick={() => {
                this.handleCloseDeleteSlide();
              }}
              className='sbmSecondary'>
              Avbryt
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default connect(
  (state) => state.templates, // Selects which state properties are merged into the component's props
  TemplateStore.actionCreators // Selects which action creators are merged into the component's props
)(MeetingTemplateLayout);
