import { createStyles, makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState, useRef } from "react";
import { setSlideNote } from "../../store/configureStore";
import Notes from "./UiComponents/NotesComponent";
import { toast } from "react-toastify";
import "../../../node_modules/react-grid-layout/css/styles.css";
import "../../../node_modules/react-resizable/css/styles.css";
import JsxParser from "react-jsx-parser";
import ParagraphComponent from "../ParagraphComponent";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import api from "../../utils/api";
import { useHistory } from "react-router-dom";
import { setCurrentSlideForMeeting } from "../../store/configureStore";
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";

const useKeyPress = (targetKey) => {
  const [keyPressed, setKeyPressed] = useState(false);

  function downHandler({ key }) {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }

  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  });

  return keyPressed;
};

const useStyles = makeStyles(() =>
  createStyles({
    columns: {
      display: "flex",
      flexDirection: "column",
      minWidth: "100vw",
      minHeight: "100vh",
      justifyContent: "space-between",
    },
    rows: {
      display: "flex",
      flexDirection: "row",
      flex: "1",
      justifyContent: "space-between",
      height: "75vh",
    },
    imageContainer: {
      backgroundSize: "cover",
      display: "flex",
      minWidth: "100%",
    },
    contentWrapper: {
      width: "100%",
      height: "100%",
      background: "linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 60, 77, 0.8))",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    footer: {
      display: "flex",
      flex: "0 0 10%",
      zIndex: 1,
    },
    leftColumn: {
      display: "flex",
      flex: "0 0 5%",
      cursor: "pointer",
      zIndex: 1,
    },
    rightColumn: {
      display: "flex",
      flex: "0 0 5%",
      cursor: "pointer",
      zIndex: 1,
      marginTop: "200px",
    },
    content: {
      display: "flex",
      flex: "0 0 80%",
      justifyContent: "center",
      alignItems: "center",
    },
    fullscreen: {
      cursor: "pointer",
      height: "30px",
      zIndex: 1,
    },
    map: {
      cursor: "pointer",
      marginRight: "2.6rem",
      marginTop: "-0.3rem",
      zIndex: 1,
    },
  })
);

const SlideLayout = function (props) {
  const history = useHistory();
  const classes = useStyles({});
  const [currentSlide, setCurrentSlide] = useState(undefined);

  const getSignalrResult = (result) => {
    console.info("signalRResult", result || "Ok");
  };

  useEffect(() => {
    async function getSlide() {
      const url = `${process.env.REACT_APP_API}/slides/${props.match.params.slideId}`;
      await api
        .get(url)
        .then((response) => response.data)
        .then((data) => {
          setCurrentSlide(data);
        });
    }

    async function getPreviousAndNextMeetingSlide() {
      const url = `${process.env.REACT_APP_API}/slides/previousAndNext/${props.match.params.id}/${props.match.params.slideId}`;
      await api
        .get(url)
        .then((response) => response.data)
        .then((data) => {
          setPreviousSlideId(data[0]);
          setNextSlideId(data[1]);
        });
    }

    getSlide();
    getPreviousAndNextMeetingSlide();

    setCurrentSlideForMeeting(parseInt(props.match.params.id), parseInt(props.match.params.slideId), getSignalrResult);

    if (document.fullscreenElement) {
      setFullScreen(true);
    }
    // eslint-disable-next-line
  }, [props.match.params.slideId]);

  async function isAgendaDefined() {
   const url = `${process.env.REACT_APP_API}/meetings/isAgendaDefined/${props.match.params.id}`;
   let result = false;
   await api.get(url).then((response) => response.data).then((data) => {
     result = data;
   });
   return result;
  }

  const [noteText] = useState("");
  const [nextSlideId, setNextSlideId] = useState(props.match.params.slideId);
  const [previousSlideId, setPreviousSlideId] = useState(props.match.params.slideId);
  const [fullScreen, setFullScreen] = useState(false);
  const notesRef = useRef();

  const leftPress = useKeyPress("ArrowLeft");
  const rightPress = useKeyPress("ArrowRight");

  const goToNextSlide = () => {
    // go to next slide if notes container not open
    if (nextSlideId === -1) {
      history.push(`/presentation/${props.match.params.id}/end`);
    } else {
      if (!notesRef.current || notesRef.current.style.opacity === "0") history.push("/presentation/" + props.match.params.id + "/" + nextSlideId);
    }
  };

  const goToPreviousSlide = async () => {
    // go to previous slide if notes container not open
    if (previousSlideId === -1) {
      const agendaDefined = await isAgendaDefined();
      if (agendaDefined) {
        history.push(`/presentation/${props.match.params.id}/agenda`);
      } else {
        history.push("/presentation/" + props.match.params.id);
      }
    } else {
      if (!notesRef.current || notesRef.current.style.opacity === "0") history.push("/presentation/" + props.match.params.id + "/" + previousSlideId);
    }
  };

  if (leftPress && currentSlide && notesRef.current.style.opacity === "0") {
    setTimeout(() => {
      goToPreviousSlide();
    }, 300);
  }

  if (rightPress && currentSlide && notesRef.current.style.opacity === "0") {
    setTimeout(() => {
      goToNextSlide();
    }, 300);
  }

  const logNoteIsSaved = () => {
    toast.success("Notat lagret", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
      hideProgressBar: true,
    });
  };

  const onNoteChange = (val) => {
    setSlideNote(currentSlide.id, val, logNoteIsSaved);
  };

  const goToMap = () => {
    history.push("/presentation/" + props.match.params.id + "/map");
  };

  const toggleFullscreen = () => {
    let elem = document.documentElement;
    if (fullScreen) {
      setFullScreen(false);
      document.exitFullscreen();
    } else {
      elem.requestFullscreen();
      setFullScreen(true);
    }
  };

  return (
    <>
      <div style={{ width: "100vw", height: "100vh", backgroundColor: "#01052F" }}>
        {currentSlide ? (
          <div style={{ width: "100vw", height: "100vh", display: "content", backgroundColor: "#01052F" }}>
            <div className='slide-layout-header'>
              <div className='sl-header-container'>
                <div className={classes.map}>
                  <ViewCarouselIcon onClick={goToMap} style={{ color: "rgba(255, 255, 255, 0.6)", fontSize: "2.5rem" }} />
                </div>
                {fullScreen ? (
                  <div onClick={toggleFullscreen} className={classes.fullscreen} title='Avslutt fullskjerm'>
                    <FullscreenExitIcon style={{ color: "white", fontSize: "30px", marginRight: "20px" }}></FullscreenExitIcon>
                  </div>
                ) : (
                  <div onClick={toggleFullscreen} className={classes.fullscreen} title='Fullskjerm'>
                    <FullscreenIcon style={{ color: "white", fontSize: "30px", marginRight: "20px" }}></FullscreenIcon>
                  </div>
                )}
              </div>
            </div>
            <div style={{ position: "absolute", right: "20px", top: "32px", zIndex: 2 }}>
              <Notes ref={notesRef} slide={currentSlide} noteText={noteText} onNoteChange={onNoteChange} />
            </div>

            {/* NOTE:
                The currentSlide.layout needs to be in a valid jsx syntax for it to render.  
                This means for example that if a value of a text field has special characters in it, the parsing could fail.
                  A example of a text value that would make the parsing fail would be -> 'Vi <3 SBM'.
                  The '<' sign would be read by the parser as a begining of a tag which would make the syntax invalid.
            */}
            <JsxParser showWarnings={true} jsx={currentSlide.layout} components={{ ParagraphComponent }} />

            <div className={classes.rows}>
              <div className={classes.leftColumn} onClick={goToPreviousSlide} title='Gå til forrige'></div>
              <div className={classes.rightColumn} onClick={goToNextSlide} title='Gå til neste'></div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className='logo-footer'>
          <Link className='main-link' component={RouterLink} to='/'>
            <img alt='logo' src={require("../../img/logo.png")} width='60px' height='60px' />
          </Link>
        </div>
      </div>
    </>
  );
};

export default SlideLayout;
