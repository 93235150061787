import * as signalR from "@microsoft/signalr";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { History } from "history";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { actionCreators as userStoreActions } from "../store/UserStore";
import { actionCreators as meetingStoreActions } from "../store/Meeting/MeetingsStore";
import { ApplicationState, reducers } from "./";
import { HubConnectionState } from "@microsoft/signalr";

export default function configureStore(history: History, initialState?: ApplicationState) {
  const middleware = [thunk, routerMiddleware(history), SignalrInvokeMiddleware];

  const rootReducer = combineReducers({
    ...reducers,
    router: connectRouter(history)
  });

  const enhancers = [];
  const windowIfDefined = typeof window === "undefined" ? null : (window as any);
  if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
  }

  return createStore(rootReducer, initialState, compose(applyMiddleware(...middleware), ...enhancers));
}

const connection = new signalR.HubConnectionBuilder()
  .withUrl(process.env.REACT_APP_API + "/mainHub", {
    skipNegotiation: true,
    transport: signalR.HttpTransportType.WebSockets
  })
  .configureLogging(signalR.LogLevel.Information)
  .build();

//from server
export function SignalrInvokeMiddleware() {
  return (next: any) => (action: any) => {
    switch (action.type) {
      case "SIGNALR_GET_CONNECTIONID":
        const user = JSON.parse(localStorage.getItem("user"));
        connection.invoke("getConnectionId", user.userid).then((id: any) => {
          action.callback(id);
        });
        break;
      case "SIGNALR_USER_JOIN_REQUEST":
        let args = action.joinRequest;
        connection.invoke("userJoinRequest", args.clubId, args.userId);
        break;
      default:
    }
    return next(action);
  };
}

// to server
export function setCurrentSlideForMeeting(meetingId: number, slideId: number, callback: any) {
  if (connection.state === HubConnectionState.Connected) {
    connection
      .invoke("setCurrentSlideForMeeting", meetingId, slideId)
      .then(() => {
        callback(null);
      })
      .catch((err) => {
        callback(err.toString());
      });
  } else {
    setTimeout(() => {
      if (connection.state !== HubConnectionState.Connected) {
        connection
          .start()
          .then(() => {
            setCurrentSlideForMeeting(meetingId, slideId, callback);
          })
          .catch((err) => console.error("SignalR Connection Error: ", err));
      } else {
        setCurrentSlideForMeeting(meetingId, slideId, callback);
      }
    }, 5000);
  }
}

export function setSlideNote(slideId: number, note: string, callback: any) {
  if (connection.state === HubConnectionState.Connected) {
    connection.invoke("setSlideNote", slideId, note).then((res) => {
      callback();
    });
  } else {
    setTimeout(() => {
      if (connection.state !== HubConnectionState.Connected) {
        connection
          .start()
          .then(() => {
            setSlideNote(slideId, note, callback);
          })
          .catch((err) => console.error("SignalR Connection Error: ", err));
      } else {
        setSlideNote(slideId, note, callback);
      }
    }, 5000);
    console.error("connection state not open");
  }
}

export function signalrStartMeeting(meetingId: string) {
  if (connection.state === HubConnectionState.Connected) {
    connection.invoke("meetingStart", parseInt(meetingId)).then(() => {
      console.info("start meeting log message sent for meeting" + meetingId + ".");
    });
  } else {
    setTimeout(() => {
      if (connection.state !== HubConnectionState.Connected) {
        connection
          .start()
          .then(() => {
            signalrStartMeeting(meetingId);
          })
          .catch((err) => console.error("SignalR Connection Error: ", err));
      } else {
        signalrStartMeeting(meetingId);
      }
    }, 5000);

    console.error("connection state not open");
  }
}

export function signalrEndMeeting(meetingId: string) {
  if (connection.state === HubConnectionState.Connected) {
    connection.invoke("meetingPause").then(() => {
      console.info("end meeting log message sent for meeting" + meetingId + ".");
    });
  } else {
    setTimeout(() => {
      if (connection.state !== HubConnectionState.Connected) {
        connection
          .start()
          .then(() => {
            signalrEndMeeting(meetingId);
          })
          .catch((err) => console.error("SignalR Connection Error: ", err));
      } else {
        signalrEndMeeting(meetingId);
      }
    }, 5000);
    console.error("connection state not open");
  }
}

export function signalrRegisterCommands(store: any, callback: any) {
  connection.on("NotifyUserJoinRequest", (data) => {
    store.dispatch(userStoreActions.setSignalRConnectionId(data));
  });

  connection.on("NotifyUserConnectionEnded", (data) => {
    // console.log("User ended connection", data);
  });
  
  connection.on("PdfForMeetingDone", (data) => {
    store.dispatch(meetingStoreActions.setPdfDoneForMeeting(data));
  });


  connection
    .start()
    .then(() => {
      callback();
    })
    .catch((err) => console.error("SignalR Connection Error: ", err));
}

export function getUserInfo(store: any) {
  store.dispatch(userStoreActions.setUserInfo());
}
