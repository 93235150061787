import { Container, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { SortableComponent } from "../../SortableComponent";
import * as MeetingsStore from "../../../store/Meeting/MeetingsStore";
import Loader from "../../Loader";
import { useHistory } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";

const MeetingMap = (props) => {
  const [hoverId, setHoverId] = useState(undefined);
  const history = useHistory();

  useEffect(() => {
    props.requestMeeting(parseInt(props.match.params.id));
    // eslint-disable-next-line
  }, [props.match.params.id]);

  const handleStateChange = (id) => {
    setHoverId(id);
  };

  const handleRedirect = (slide) => {
    if (slide.isSectionSlide) return;
    history.push("/presentation/" + props.match.params.id + "/" + slide.id);
  };

  const goBack = () => {
    history.goBack();
  };

  return (
    <div className='meetings-main'>
      <Container maxWidth={false}>
        <div className='main-content'>
          <div onClick={goBack} style={{ position: "absolute", right: "10rem", color: "#888888", cursor: "pointer" }}>
            <CloseIcon style={{ fontSize: "2.5rem" }}></CloseIcon>
          </div>
          <Grid container direction='row' justify='flex-start' alignItems='flex-start'>
            <div className='main-meeting-layout'>
              <div className='header'>Møteoversikt</div>
              {props.loadingMeeting ? (
                <Loader></Loader>
              ) : (
                <SortableComponent
                  updateState={handleStateChange}
                  state={{ hoverId: hoverId }}
                  isMeetingTemplate={false}
                  activeId={props.meetings.currentSlideId}
                  meetingId={props.meetings.currentMeeting ? props.meetings.currentMeeting.id : 0}
                  sections={props.meetings.currentMeeting ? props.meetings.currentMeeting.sections : []}
                  redirect={handleRedirect}
                  onSortEnd={props.reorderMeetingSlides}
                  onSortStart={props.markAffectedMeetingSlides}></SortableComponent>
              )}
            </div>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default connect(
  (state) => ({
    meetings: state.meetings,
  }),
  Object.assign(MeetingsStore.actionCreators)
)(MeetingMap);
