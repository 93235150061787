import { MediaModel, ChartTypeModel } from "./Slide";

export class SlideTemplate {
  public id: number | undefined;
  public slideTypeId: number | undefined;
  public header: string | undefined;
  public subheader: string | undefined;
  public paragraph: string | undefined;
  public meetingTemplateSectionId: number | null | undefined;
  public estimatedDuration: number | null | undefined;
  public chartTypeId: number | null | undefined;
  public changedByUser: number | null | undefined;
  public createdByUser: number | null | undefined;
  public created: Date | string | null | undefined;
  public lastChanged: Date | string | null | undefined;
  public deleted: boolean | null | undefined;
  public imageId: number | null | undefined;
  public videoId: number | null | undefined;
  public dragId: number | null;
  public positionInSection: number;
  public isSectionSlide: boolean | undefined;
  public isLastSlide: boolean | undefined;
  public mark: boolean | undefined;
  public bulletPoints: SlideTemplateBulletPoint[];
  public image?: MediaModel;
  public video?: MediaModel;
  public chartType?: ChartTypeModel;
}

export class SlideTemplateBulletPoint {
  public id?: number;
  public slideTemplateId?: number;
  public value?: string;
  public inputField?: boolean;
  public label?: string;
  public position?: number;
}
