import React, {useState} from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { Checkbox } from "@material-ui/core";
import api from "../utils/api";
import JsxParser from "react-jsx-parser";
import ParagraphComponent from "../components/ParagraphComponent";
import { withStyles } from "@material-ui/core/styles";


function useForceUpdate(){
  // eslint-disable-next-line
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => ++value); // update the state to force render
}

const WhiteBackgroundCheckbox = withStyles(theme => ({
  root: {
    color: "white",
    "& .MuiIconButton-label": {
      position: "relative",
      zIndex: 0,
    },
    "&:not($checked) .MuiIconButton-label:after": {
      content: '""',
      left: 4,
      top: 4,
      height: 15,
      width: 15,
      position: "absolute",
      backgroundColor: "#0072ff",
      zIndex: -1
    },
    "&$checked .MuiIconButton-label:after": {
      content: '""',
      left: 4,
      top: 4,
      height: 15,
      width: 15,
      position: "absolute",
      color: "#0072ff",
      backgroundColor: "white",
      zIndex: -1
    },
    "&$checked": {
      color: "#0072ff"
    },
    "&.Mui-disabled": {
      color: 'transparent',
    },
    "&.Mui-disabled .MuiIconButton-label:after": {
      content: '""',
      left: 4,
      top: 4,
      height: 15,
      width: 15,
      position: "absolute",
      backgroundColor: "transparent",
      zIndex: -1
    }
  },
  checked: {}
}))(Checkbox);

const SortableItem = SortableElement((props) => {
  props.val.showRemove = false;

  const forceUpdate = useForceUpdate();

  function toggleHover(val) {
    props.updateState(val);
  }

  const handleMandatoryChange = (e, id, mandatory) => {
    e.stopPropagation();
    if (mandatory) return;

    const url = `${process.env.REACT_APP_API}/meetings/toggleIncludeSlide`;
    const payload = { meetingId: props.meetingId, slideId: id };

    api
      .put(url, payload)
      .then((data) => {
        props.val.include = data.data;
        forceUpdate();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <li
      onClick={() => props.redirect(props.val)}
      className='sortableHelper'
      onMouseEnter={() => toggleHover(props.val.dragId)}
      onMouseLeave={() => toggleHover(undefined)}>
      {props.isMeetingTemplate ? (
        <div
          onClick={(e) => props.deleteClick(e, props.val.dragId)}
          className={(props.val.dragId === props.state.hoverId ? "show" : "hide") + " remove"}>
          X
        </div>
      ) : !props.val.isSectionSlide ? (
        <div
          onClick={(e) => handleMandatoryChange(e, props.val.id, props.val.mandatory)}
          className={(props.val.dragId === props.state.hoverId ? "show" : "show") + " remove"}>
          <WhiteBackgroundCheckbox disabled={props.val.mandatory} checked={props.val.include} style={{ top: "12px", right: "-15px", zIndex: 50 }} />
        </div>
      ) : (
        <div className={(props.val.dragId === props.state.hoverId ? "hide" : "hide") + " remove"}>
          <WhiteBackgroundCheckbox style={{ top: "12px", right: "-15px", display: "hidden" }} />
        </div>
      )}
      <div
        className={(props.val.mark ? " marked " : " ") + (props.val.isSectionSlide ? "slide section" : "slide ") + (props.isActive ? "active" : "")}>
        <div className={props.val.isSectionSlide ? "sortable-item-header-white" : "sortable-item-header"}>
          {props.val.header ? props.val.header : props.val.title ? props.val.title : ""}
        </div>
        <div className='sortable-item-duration'>
          {props.val.isSectionSlide ? "" : props.val.estimatedDuration ? props.val.estimatedDuration + " min" : "0 min"}
        </div>
        <div className={!props.val.isLastSlide ? "slide-right-dot" : ""}></div>
        <div className={!props.val.isLastSlide ? "slide-right-dot-line" : ""}></div>
        <div className={!props.val.isSectionSlide ? "slide-left-dot" : ""}></div>
        <div className={!props.val.isSectionSlide ? "slide-left-dot-line" : ""}></div>
        {props.val.jsx || props.val.layout ? <JsxParser showWarnings={true} jsx={props.val.jsx ? props.val.jsx : props.val.layout} className='preview-slide' components={{ ParagraphComponent }} /> : <></>}
      </div>
    </li>
  );
});

export const SortableList = SortableContainer((props) => {
  let arr = [];
  props.items.map((value, index) => {
    return arr.push(
      <SortableItem
        // disabled={!props.isMeetingTemplate}
        state={props.state}
        updateState={props.updateState}
        key={`item-${value.dragId}`}
        index={index}
        val={value}
        meetingId={props.meetingId}
        isMeetingTemplate={props.isMeetingTemplate}
        deleteClick={props.deleteClick}
        style={{ visibility: "visible" }}
        redirect={props.redirect}
        isActive={props.activeId && value.id === props.activeId}
      />
    );
  });
  return <ul className='row'>{[...arr]}</ul>;
});
