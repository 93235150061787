import React, { useEffect, useState } from "react";
import { convertFromRaw } from "draft-js";
import api from "../../utils/api";
import { stateToHTML } from "draft-js-export-html";
import moment from "moment";
import "moment/locale/nb";

const NotesPrint = (props) => {
  const id = props.match.params.id;
  const [htmlArray, setHtmlArray] = useState([]);

  useEffect(() => {
    const url = `${process.env.REACT_APP_API}/meetings/notes/${id}`;
    api
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        data.forEach((note) => {
          let html = {
            slideId: note.slideId,
            slideTitle: note.slideTitle,
            position: note.position,
            customerName: note.customerName,
            meetingDate: note.meetingDate,
            html: stateToHTML(convertFromRaw(JSON.parse(note.text))),
          };
          setHtmlArray((htmlArray) => [...htmlArray, html]);
        });
      });
      // eslint-disable-next-line
  }, []);

  const createMarkup = (note) => {
    return { __html: note };
  };

  return (
    <>
      {htmlArray.length > 0 ? (
        <div className='meeting-notes-container'>
          <div className='meeting-notes-header'>
            Notater for møte med {htmlArray[0].customerName}
            <br />
            {moment(htmlArray[0].meetingDate).format("D")}. {moment(htmlArray[0].meetingDate).format("MMMM")}{" "}
            {moment(htmlArray[0].meetingDate).format("YYYY")}
          </div>
          {htmlArray.map((note, i) => {
            return (
              <div key={i + "w"}>
                <div className='meeting-notes-slide-title' key={i + "_s"}>
                  Slide: {note.slideTitle}
                </div>
                <div className='meeting-notes-note' key={i} dangerouslySetInnerHTML={createMarkup(note.html)}></div>
              </div>
            );
          })}
        </div>
      ) : (
        <>
          <div className='meeting-notes-container'>
            <div className='meeting-notes-header'>Ingen notater funnet.</div>
          </div>
        </>
      )}
    </>
  );
};

export default NotesPrint;
