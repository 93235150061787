import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import WebIcon from '@material-ui/icons/Web';
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import TreeItem from "@material-ui/lab/TreeItem";
import TreeView from "@material-ui/lab/TreeView";
import React, { useState, useEffect }  from "react";
import "./SlideGroupTreeView.scss";
import _ from "lodash";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import CreatableSelect from "react-select/creatable";
import ParagraphComponent from "../ParagraphComponent";
import JsxParser from "react-jsx-parser";
import SubjectOutlinedIcon from '@material-ui/icons/SubjectOutlined';
import PlusOneOutlinedIcon from '@material-ui/icons/PlusOneOutlined';


const useTreeItemStyles = makeStyles((theme) =>
  createStyles({
    root: {
      color: theme.palette.text.secondary,
      "&:focus > $content": {
        backgroundColor: `#eae7e7`,
        color: "var(--tree-view-color)",
      },
    },
    content: {
      color: theme.palette.text.primary,
      paddingRight: theme.spacing(1),
      fontWeight: theme.typography.fontWeightMedium,
      "$expanded > &": {
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
    group: {
      marginLeft: 0,
      "& $content": {
        paddingLeft: theme.spacing(2),
      },
    },
    expanded: {},
    label: {
      fontWeight: "inherit",
      color: "inherit",
    },
    labelRoot: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
      marginRight: theme.spacing(1),
      height: "40px",
      width: "40px",
      color: "#bbb",
    },
    postLabelIcon: {
      height: "20px",
      width: "20px",
      color: "#000",
    },
    labelText: {
      fontWeight: "inherit",
      flexGrow: 0,
    },
    iconContainer: {
      position: "fixed",
      left: "calc(24%)",
    },
  })
);

const StyledTreeItem = (props) => {
  const classes = useTreeItemStyles({});
  const { labelText, preLabelIcon: LabelIcon, postLabelIcon: PostLabelIcon, labelInfo, color, bgColor, ...other } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          {props.thumb ? <div className="list-thumb">{props.thumb}</div> : <></>}
          {LabelIcon ? <LabelIcon color='inherit' className={classes.labelIcon} /> : <></>}
          <Typography component={"span"} variant='body2' className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography component={"span"} variant='caption' color='inherit'>
            {labelInfo}
          </Typography>
          <div style={{ width: "50%", paddingTop: "8px", textAlign: "right" }}>
            {PostLabelIcon ? <PostLabelIcon color='inherit' className={classes.postLabelIcon} /> : <></>}
          </div>
        </div>
      }
      style={{
        "--tree-view-color": color,
        "--tree-view-bg-color": bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
};

const useStyles = makeStyles(
  createStyles({
    root: {
      flexGrow: 1,
      overflowX: "hidden",
    },
    form_control: {
      "min-width": "100%",
      "margin-top": "1rem",
    },
  })
);

const SlideGroupTreeView = (_props) => {
  useEffect(() => {
    _props.meetingTemplate && _props.meetingTemplate.expandedNodes ? setExpandedNodes(_props.meetingTemplate.expandedNodes) : setExpandedNodes([]);
    // eslint-disable-next-line
  },[])

  const classes = useStyles({});
  const [open, setOpen] = React.useState(false);
  const [currentTopicId, setCurrentTopicId] = React.useState(0);
  const [expandedNodes, setExpandedNodes] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentTopicId(0);
  };

  const addTopic = () => {
    let selectedTopic = _props.topics.find((x) => x.id === currentTopicId);
    _props.addNewSectionTemplate(selectedTopic);
    setOpen(false);
  };

  const addSlideToSection = (sectionId, _props) => {
    let newSlide = {};
    let template = _.cloneDeep(_props.meetingTemplate);

    let slidePositions = template.sections
      .find((x) => x.id === sectionId)
      .slides.map((s) => {
        return s.positionInSection || 0;
      });

    let maxPos = 1;
    if (slidePositions.length > 0) {
      maxPos = Math.max(...slidePositions) + 1;
    }

    newSlide.meetingTemplateSectionId = sectionId;
    newSlide.positionInSection = maxPos;
    newSlide.images = [];
    newSlide.texts = [];
    newSlide.links = [];
    newSlide.title = "";
    newSlide.zones = [
      { zoneId: "1", backgroundColor: "rgba(255, 255, 255, 1.0)", backgroundImage: null, backgroundImageName: null },
      { zoneId: "2", backgroundColor: "rgba(255, 255, 255, 1.0)", backgroundImage: null, backgroundImageName: null },
    ];
    newSlide.layout = [
      { i: "1", x: 0, y: 0, w: 6, h: 50, maxH: 100 },
      { i: "2", x: 6, y: 0, w: 6, h: 50, maxH: 100 },
    ];
    newSlide.media = [];

    _props.addNewSlideToTemplateSection(sectionId, newSlide);
    _props.saveSlideTemplate(newSlide, expandedNodes);
  };

  const redirect = (slide) => {
    _props.clearSlideTemplate();
    async function getSlide(cont) {
      if (slide.isSectionSlide) return;
      if (slide.id && slide.id !== 0) return await cont.requestSlideTemplate(slide.id);
      else
        return cont.setSlideTemplate({
          id: 0,
          title: "",
          meetingTemplateSectionId: slide.meetingTemplateSectionId,
          estimatedDuration: 0,
          positionInSection: slide.positionInSection,
          media: [],
          zones: [
            { zoneId: "1", backgroundColor: "rgba(255, 255, 255, 1.0)", backgroundImage: null, backgroundImageName: null },
            { zoneId: "2", backgroundColor: "rgba(255, 255, 255, 1.0)", backgroundImage: null, backgroundImageName: null },
          ],
          texts: [],
          links: [],
          images: [],
          layout: [
            {
              i: "1",
              x: 0,
              y: 50,
              w: 6,
              h: 50,
              maxH: 100,
            },
            {
              i: "2",
              x: 6,
              y: 50,
              w: 6,
              h: 50,
              maxH: 100,
            },
          ],
        });
    }

    getSlide(_props);
  };

  const handleTopicChange = (newValue, actionMeta) => {
    if(actionMeta.action === "select-option") {
      setCurrentTopicId(newValue.value);
    }

    if(actionMeta.action === "create-option") {
      _props.addNewTopic(newValue.label);
    }

  };

  const handleTopicInputChange = () => {
    
  };

  const handleNodeToggle = (event, nodeIds) => {
    setExpandedNodes(nodeIds);
  }

  return (
    <>
      <TreeView
        className={classes.root}
        defaultCollapseIcon={<KeyboardArrowDownIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        defaultEndIcon={<div style={{ width: 24 }} />}
        onNodeToggle={handleNodeToggle}
        expanded={expandedNodes}
        >
        <>
          {_props.meetingTemplate && _props.meetingTemplate.sections && _props.meetingTemplate.sections.length > 0 ? (
            _props.meetingTemplate.sections
              .sort(function (a, b) {
                return a.positionInMeetingTemplate - b.positionInMeetingTemplate;
              })
              .map((s) => {
                return (
                  <StyledTreeItem
                    key={s.id.toString()}
                    nodeId={s.id.toString()}
                    labelText={s.topic.name}
                    preLabelIcon={SubjectOutlinedIcon}
                    postLabelIcon={null}>
                    {s.slides.length > 0 ? (
                      s.slides
                        .filter((sl) => !sl.isSectionSlide)
                        .map((slide) => {
                          return (
                            <StyledTreeItem
                              key={"slide_" + slide.id}
                              onClick={() => redirect(slide)}
                              nodeId={"slide_" + slide.id}
                              labelText={slide.title ? slide.title : ""}
                              thumb={<JsxParser showWarnings={true} jsx={slide.jsx ? slide.jsx : slide.layout} className='preview-slide icon' components={{ ParagraphComponent }} />}
                              postLabelIcon={null}
                            />
                          );
                        })
                    ) : (
                      <></>
                    )}
                    <StyledTreeItem
                      preLabelIcon={WebIcon}
                      key={"slide_" + s.id + "0"}
                      nodeId={"slide_" + s.id + "0"}
                      onClick={() => addSlideToSection(s.id, _props)}
                      labelText='Legg til slide'
                      
                    />
                  </StyledTreeItem>
                );
              })
          ) : (
            <></>
          )}
        </>
        {_props.meetingTemplate && _props.meetingTemplate.id ? (
          <div>
            <StyledTreeItem
              preLabelIcon={PlusOneOutlinedIcon}
              key={"slide_0"}
              nodeId={"slide_0"}
              onClick={handleClickOpen}
              labelText='Legg til tema'
              
            />
            <div>
              <Dialog open={open} onClose={handleClose} aria-labelledby='draggable-dialog-title'>
                <DialogTitle style={{ width: "450px" }} id='draggable-dialog-title'>
                  Velg tema å legge til
                </DialogTitle>
                <DialogContent style={{ maxHeight: "500px", minHeight: "370px" }}>
                  <CreatableSelect isClearable onChange={handleTopicChange} onInputChange={handleTopicInputChange} options={_props.topics.map((t) => {return { value: t.id, label: t.name };})} />
                </DialogContent>
                <DialogActions>
                  <Button disabled={currentTopicId === 0} variant='contained' onClick={addTopic} className='sbmPrimary'>
                    Velg
                  </Button>
                  <Button variant='contained' autoFocus onClick={handleClose} className='sbmSecondary'>
                    Avbryt
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        ) : (
          <></>
        )}
      </TreeView>
    </>
  );
};
export default SlideGroupTreeView;
