export class SlideModel {
  dragId: any;
  id: number | undefined;
  slideGroupId: number | undefined;
  slideType: SlideTypeModel | undefined;
  positionInSection: number | undefined;
  estimatedDuration: number | undefined;
  loading: boolean | undefined;
  meetingSectionId: number | undefined;
  isSectionSlide: boolean | undefined;
  isLastSlide: boolean | undefined;
  mark: boolean | undefined;
  header: string; //SlideType: image / chart
  subHeader: string; //SlideType: image / chart
  paragraph: string; //SlideType: image / chart
  image: MediaModel | undefined; //SlideType: image / chart
  bulletPoints: BulletPoint[]; //SlideType: image / chart
  inputFields: BulletPoint[]; //SlideType: chart
  chartType: ChartTypeModel | undefined; //SlideType: chart
  video: MediaModel | undefined; //SlideType: video
  note: string | undefined;
  layout: string | undefined;
}

export interface SlideTypeModel {
  id: number;
  name: string;
}

export interface InfoTextModel {
  header: string;
  subHeader: string;
  paragraph: string;
}

export interface MediaModel {
  id: number;
  path: string;
  thumbnail: string;
  isVideo: boolean;
}

export interface ChartTypeModel {
  id: number;
  name: string;
}

export interface BulletPoint {
  id: number;
  position: number;
  value: string;
  inputField: boolean;
  label: string;
}

export interface CustomerModel {
  id: number;
  name: string;
  age: number;
}
