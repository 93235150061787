import React, { useState, useEffect } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import "moment/locale/nb";
import { useHistory } from "react-router-dom";
import { setCurrentSlideForMeeting } from "../../store/configureStore";
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";

const useKeyPress = (targetKey) => {
  const [keyPressed, setKeyPressed] = useState(false);

  function downHandler({ key }) {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }

  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  });

  return keyPressed;
};

const useStyles = makeStyles(() =>
  createStyles({
    columns: {
      display: "flex",
      flexDirection: "column",
      minWidth: "100vw",
      minHeight: "100vh",
      justifyContent: "space-between",
    },
    rows: {
      display: "flex",
      flexDirection: "row",
      flex: "1",
      justifyContent: "space-between",
      height: "75vh",
    },
    imageContainer: {
      backgroundSize: "cover",
      display: "flex",
      minWidth: "100%",
    },
    contentWrapper: {
      width: "100%",
      height: "100%",
      background: "linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 60, 77, 0.8))",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    footer: {
      display: "flex",
      flex: "0 0 10%",
      zIndex: 1,
    },
    leftColumn: {
      display: "flex",
      flex: "0 0 5%",
      cursor: "pointer",
      zIndex: 1,
    },
    rightColumn: {
      display: "flex",
      flex: "0 0 5%",
      cursor: "pointer",
      zIndex: 1,
      marginTop: "200px",
    },
    content: {
      display: "flex",
      flex: "0 0 80%",
      justifyContent: "center",
      alignItems: "center",
    },
    fullscreen: {
      cursor: "pointer",
      height: "30px",
      zIndex: 1,
    },
    map: {
      cursor: "pointer",
      marginRight: "2.6rem",
      marginTop: "-0.3rem",
      zIndex: 1,
    },
  })
);

const StartSlide = function (props) {
  const history = useHistory();

  const leftPress = useKeyPress("ArrowLeft");
  const rightPress = useKeyPress("ArrowRight");
  const [fullScreen, setFullScreen] = useState(false);
  const classes = useStyles({});

  useEffect(() => {
    setCurrentSlideForMeeting(props.meeting.id, 0, getSignalrResult);
    if (document.fullscreenElement) {
      setFullScreen(true);
    }
  }, [props.meeting]);

  const getSignalrResult = (result) => {
    console.info("signalRResult", result || "Ok");
  };

  if (rightPress && props.meeting) {
    setTimeout(() => {
      goToNextSlide();
    }, 300);
  }

  if (leftPress) {
    setTimeout(() => {
      goToMeetings();
    }, 300);
  }

  const toggleFullscreen = () => {
    let elem = document.documentElement;
    if (fullScreen) {
      setFullScreen(false);
      document.exitFullscreen();
    } else {
      elem.requestFullscreen();
      setFullScreen(true);
    }
  };

  const goToMap = () => {
    history.push("/presentation/" + props.meeting.id + "/map");
  };

  const goToMeetings = () => {
    history.push("/meetings");
  };
  
  const goToNextSlide = () => {
    if (props.meeting.agendaItems == null || props.meeting.agendaItems == "" || props.meeting.agendaItems == "[]")
      goToFirstSlide();
    else 
      goToMeetingAgenda();
  };

  const goToMeetingAgenda = () => {
      history.push("/presentation/" + props.meeting.id + "/agenda");
  }

  const goToFirstSlide = () => {
    if(props.meeting.firstSlideId == 0)
      history.push("/presentation/" + props.meeting.id + "/end");
    else
      history.push("/presentation/" + props.meeting.id + "/" + props.meeting.firstSlideId);
  };


  return (
    <>
      <div style={{ backgroundColor: "#01052F", color: "#fff", width: "100vw", height: "100vh", display: "flex", zIndex: 2, position: "absolute" }}>
        <div className='slide-layout-header'>
          <div className='sl-header-container'>
            <div className={classes.map}>
              {/* <SlideCarouselComponent /> */}
              <ViewCarouselIcon onClick={goToMap} style={{ color: "rgba(255, 255, 255, 0.6)", fontSize: "2.5rem" }} />
            </div>
            {fullScreen ? (
              <div onClick={toggleFullscreen} className={classes.fullscreen} title='Avslutt fullskjerm'>
                <FullscreenExitIcon style={{ color: "white", fontSize: "30px", marginRight: "20px" }}></FullscreenExitIcon>
              </div>
            ) : (
              <div onClick={toggleFullscreen} className={classes.fullscreen} title='Fullskjerm'>
                <FullscreenIcon style={{ color: "white", fontSize: "30px", marginRight: "20px" }}></FullscreenIcon>
              </div>
            )}
          </div>
        </div>
        <div className='start-slide-left'>
          <div className='start-slide-left-logo'>
            <img src={require("../../img/logo-start.png")} alt='' style={{ width: "22%", height: "auto", display: "flex" }} />
          </div>
          <div className='start-slide-left-middle' style={{ cursor: "pointer" }} onClick={() => goToMeetings()}>
            <div className='start-slide-left-text'>
              Velkommen <br />
              {props.meeting.customerName}
            </div>
          </div>
          <div className='start-slide-left-bottom'>
            <div className='start-slide-left-signature'>
              <p>
                {props.meeting.office} {moment(props.meeting.meetingDate).format("D")}. {moment(props.meeting.meetingDate).format("MMMM")}{" "}
                {moment(props.meeting.meetingDate).format("YYYY")}
                <br></br>
                {props.meeting.userName + ", " + props.meeting.title}
                <br></br>
                {props.meeting.email}, tlf. {props.meeting.phone}
                <br></br>
              </p>
            </div>
          </div>
        </div>
        <div className='start-slide-right'>
          <div style={{ display: "flex", flexDirection: "column", height: "100%", width: "50%" }}>
            <div className='start-slide-right-1'></div>
            <div className='start-slide-right-1-bottom'></div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", height: "100%", width: "50%", cursor: "pointer" }} onClick={() => goToNextSlide()}>
            <div className='start-slide-right-2'></div>
            <div className='start-slide-right-2-middle'></div>
            <div className='start-slide-right-2-bottom'></div>
          </div>
        </div>
      </div>
      <div className='logo-footer' style={{ zIndex: 2 }}>
        <Link className='main-link' component={RouterLink} to='/'>
          <img alt='logo' src={require("../../img/logo.png")} width='60px' height='60px' />
        </Link>
      </div>
    </>
  );
};

export default StartSlide;
