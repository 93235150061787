import api from "../../utils/api";
import { SlideModel } from "../models/Slide";
import arrayMove from "array-move";
import _ from "lodash";

const REQUEST_UPCOMING_MEETINGS = "REQUEST_MEETINGS";
const RECEIVED_UPCOMING_MEETINGS = "RECEIVE_MEETINGS";
const REQUEST_PAST_MEETINGS = "REQUEST_PAST_MEETINGS";
const RECEIVED_PAST_MEETINGS = "RECEIVED_PAST_MEETINGS";

//  ACTION CREATORS
export const actionCreators = {
  setPdfDoneForMeeting: (id) => (dispatch) => {
    dispatch({ type: "SET_MEETING_PDF_DONE", meetingId: id });
  },
  requestMeetingLog: (id) => (dispatch) => {
    const url = `${process.env.REACT_APP_API}/meetings/log/${id}`;
    api
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        dispatch({ type: "RECEIVE_MEETING_LOG", log: data });
      });
    dispatch({ type: "REQUEST_MEETING_LOG", id: id });
  },
  endMeeting: (meetingId) => (dipatch) => {
    const url = `${process.env.REACT_APP_API}/meetings/endmeeting/${meetingId}`;
    api
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        // console.log("endMeeting returned", data);
      });
  },
  setCurrentSlide: (slide) => (dispatch, getState) => {
    const appState = getState();
    if (appState && appState.meetings) {
      dispatch({ type: "SET_CURRENT_SLIDE", slide: slide });
    }
  },
  setCurrentSlideId: (slideId) => (dispatch) => {
    dispatch({ type: "SET_CURRENT_SLIDE_ID", slideId: slideId });
  },
  reorderMeetingSlidesAndSave: (sortEnd) => (dispatch, getState) => {
    dispatch({ type: "REORDER_MEETING_SLIDES", oldIndex: sortEnd.oldIndex, newIndex: sortEnd.newIndex });
    setTimeout(() => {
      dispatch(actionCreators.saveMeeting(getState().meetings.currentMeeting));
    }, 500);
  },
  reorderMeetingSlides: (sortEnd) => (dispatch) => {
    dispatch({ type: "REORDER_MEETING_SLIDES", oldIndex: sortEnd.oldIndex, newIndex: sortEnd.newIndex });
  },
  markAffectedMeetingSlides: (sortStart) => (dispatch) => {
    dispatch({ type: "MARK_AFFECTED_MEETING_SLIDES", index: sortStart.index });
  },
  requestUpcomingMeetings: () => (dispatch) => {
    const url = `${process.env.REACT_APP_API}/meetings/upcoming`;
    api
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        dispatch({ type: RECEIVED_UPCOMING_MEETINGS, meetings: data });
      });
    dispatch({ type: REQUEST_UPCOMING_MEETINGS });
  },
  requestPastMeetings: () => (dispatch) => {
    const url = `${process.env.REACT_APP_API}/meetings/past`;
    api
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        dispatch({ type: RECEIVED_PAST_MEETINGS, meetings: data });
      });
    dispatch({ type: REQUEST_PAST_MEETINGS });
  },
  requestTopicsForMeeting: (id) => (dispatch, getState) => {
    const appState = getState();
    if (!appState.meetings.isLoadingTopicsForMeeting) {
      const url = `${process.env.REACT_APP_API}/Topic/topicsForMeeting/${id}`;

      api
        .get(url)
        .then((response) => response.data)
        .then((data) => {
          dispatch({ type: "RECEIVE_TOPICS_FOR_MEETING", topics: data });
        });
      dispatch({ type: "REQUEST_TOPICS_FOR_MEETING", id: id });
    }
  },
  requestMeeting: (meetingId) => (dispatch, getState) => {
    const appState = getState();
    if (!appState.meetings.isLoadingMeeting) {
      const url = `${process.env.REACT_APP_API}/meetings/${meetingId}`;
      api
        .get(url)
        .then((response) => response.data)
        .then((data) => {
          dispatch({ type: "RECEIVE_MEETING", meeting: data });
        });
      dispatch({ type: "REQUEST_MEETING", meetingId: meetingId });
    }
  },
  createMeeting: (templateId, name, meetingDate, agendaItems) => (dispatch) => {
    const url = `${process.env.REACT_APP_API}/meetings`;
    const payload = { templateId: templateId, customerName: name, meetingDate: meetingDate, agendaItems: agendaItems };
    api.post(url, payload).then((data) => {
      dispatch({ type: "RECEIVE_NEW_MEETING", meeting: data.data });
    });

    dispatch({ type: "CREATE_MEETING", templateId: templateId, name: name, meetingDate: meetingDate, agendaItems: agendaItems });
  },
  applyMeetingTemplate: (meetingId, templateId) => (dispatch) => {
    const url = `${process.env.REACT_APP_API}/meetings/applyTemplateToMeeting`;
    const payload = { meetingId: meetingId, templateId: templateId };
    api.put(url, payload).then((data) => {
      // console.log("applied template to meeting");
    });
    dispatch({ type: "APPLY_MEETING_TEMPLATE", meetingId, templateId });
  },
  setMeetingFrontText: (frontText, meetingId) => (dispatch) => {
    dispatch({ type: "SET_MEETING_FRONT_TEXT", frontText, meetingId });
  },
  setMeetingUserName: (userName, meetingId) => (dispatch) => {
    dispatch({ type: "SET_MEETING_USERNAME", userName, meetingId });
  },
  setMeetingTitle: (title, meetingId) => (dispatch) => {
    dispatch({ type: "SET_MEETING_TITLE", title, meetingId });
  },
  setMeetingCustomerName: (customerName, meetingId) => (dispatch) => {
    dispatch({ type: "SET_MEETING_CUSTOMER_NAME", customerName, meetingId });
  },
  setMeetingEmail: (email, meetingId) => (dispatch) => {
    dispatch({ type: "SET_MEETING_EMAIL", email, meetingId });
  },
  setMeetingPhone: (phone, meetingId) => (dispatch) => {
    dispatch({ type: "SET_MEETING_PHONE", phone, meetingId });
  },
  saveMeeting: (meeting) => (dispatch) => {
    const url = `${process.env.REACT_APP_API}/meetings/${meeting.id}`;
    const payload = meeting;
    api.put(url, payload).then((data) => {
      dispatch({ type: "RECEIVE_SAVED_MEETING", meeting: data.data });
    });
    dispatch({ type: "SAVE_MEETING", meeting });
  },
  goPastFirstSlide: () => (dispatch) => {
    dispatch({ type: "GO_PAST_FIRST_SLIDE" });
  },
  deleteMeeting: (meetingId) => (dispatch) => {
    const url = `${process.env.REACT_APP_API}/meetings/${meetingId}`;

    api.delete(url).then((data) => {
      dispatch({ type: "MEETING_DELETED", meetingId: meetingId });
    });
    dispatch({ type: "DELETING_MEETING", meetingId });
  },
  getStatistics: (offices, fromDate, toDate) => (dispatch) => {
    const url = `${process.env.REACT_APP_API}/stats/meetings`;
    const payload = { offices: offices, from: fromDate, to: toDate };
    api.post(url, payload).then((data) => {
      dispatch({ type: "RECEIVE_STATS", stats: data.data });
    });
    dispatch({ type: "GET_STATS" });
  },
};

//  INIT STATE
const unloadedState = {
  currentMeeting: undefined,
  currentSlideId: undefined,
  isLoading: false,
  isLoadingMeeting: false,
  isLoadingMeetingLog: false,
  isLoadingUpcomingMeetings: false,
  isLoadingTopicsForMeeting: false,
  upcomingMeetings: [],
  pastMeetings: [],
  meetingTemplates: [],
  topicsForMeeting: [],
  currentSlide: undefined,
  meetingLog: undefined,
  lastImage: undefined,
  statistics: undefined,
};

//  REDUCERS
export const reducer = (state, incomingAction) => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction;
  switch (action.type) {
    case "GET_STATS": {
      return {
        ...state,
      };
    }
    case "RECEIVE_STATS": {
      return {
        ...state,
        statistics: action.stats,
      };
    }
    case "REQUEST_MEETING_LOG": {
      return {
        ...state,
        meetingLog: undefined,
        isLoadingMeetingLog: true,
      };
    }
    case "RECEIVE_MEETING_LOG": {
      return {
        ...state,
        meetingLog: action.log,
        isLoadingMeetingLog: false,
      };
    }
    case REQUEST_UPCOMING_MEETINGS: {
      return {
        ...state,
        isLoading: true,
        isLoadingUpcomingMeetings: true,
      };
    }
    case "RECEIVE_NEW_MEETING": {
      const meetings = state.upcomingMeetings.concat(action.meeting);
      return {
        ...state,
        upcomingMeetings: meetings,
      };
    }
    case RECEIVED_UPCOMING_MEETINGS: {
      return {
        ...state,
        upcomingMeetings: action.meetings ? action.meetings : [],
        isLoading: false,
        isLoadingUpcomingMeetings: false,
      };
    }
    case REQUEST_PAST_MEETINGS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case RECEIVED_PAST_MEETINGS: {
      return {
        ...state,
        pastMeetings: action.meetings ? action.meetings : [],
        isLoading: false,
      };
    }
    case "GO_PAST_FIRST_SLIDE": {
      return {
        ...state,
        showStartSlide: false,
      };
    }
    case "REQUEST_TOPICS_FOR_MEETING": {
      return {
        ...state,
        isLoadingTopicsForMeeting: true,
      };
    }
    case "RECEIVE_TOPICS_FOR_MEETING": {
      return {
        ...state,
        topicsForMeeting: action.topics,
        isLoadingTopicsForMeeting: false,
      };
    }
    case "REQUEST_MEETING": {
      return {
        ...state,
        currentMeeting: undefined,
        isLoadingMeeting: true,
      };
    }
    case "APPLY_MEETING_TEMPLATE": {
      return {
        ...state,
      };
    }
    case "RECEIVE_MEETING": {
      return {
        ...state,
        isLoadingMeeting: false,
        currentMeeting: action.meeting,
        showStartSlide: true,
      };
    }
    case "SET_MEETING_FRONT_TEXT": {
      let meetings = _.cloneDeep(state.upcomingMeetings);
      let meeting = meetings.find((m) => {
        return m.id === action.meetingId;
      });
      meeting.frontText = action.frontText;

      return {
        ...state,
        upcomingMeetings: meetings,
      };
    }
    case "SET_MEETING_USERNAME": {
      let bbb = _.cloneDeep(state.upcomingMeetings);
      let meeting = bbb.find((m) => {
        return m.id === action.meetingId;
      });
      meeting.userName = action.userName;

      return {
        ...state,
        upcomingMeetings: bbb,
      };
    }
    case "SET_MEETING_TITLE": {
      let meetings = _.cloneDeep(state.upcomingMeetings);
      let meeting = meetings.find((m) => {
        return m.id === action.meetingId;
      });
      meeting.title = action.title;

      return {
        ...state,
        upcomingMeetings: meetings,
      };
    }
    case "SET_MEETING_CUSTOMER_NAME": {
      let aaa = _.cloneDeep(state.upcomingMeetings);
      let meeting = aaa.find((m) => {
        return m.id === action.meetingId;
      });
      meeting.customerName = action.customerName;

      return {
        ...state,
        upcomingMeetings: aaa,
      };
    }
    case "SET_MEETING_EMAIL": {
      let meetings = _.cloneDeep(state.upcomingMeetings);
      let meeting = meetings.find((m) => {
        return m.id === action.meetingId;
      });
      meeting.email = action.email;

      return {
        ...state,
        upcomingMeetings: meetings,
      };
    }
    case "SET_MEETING_PHONE": {
      let meetings = _.cloneDeep(state.upcomingMeetings);
      let meeting = meetings.find((m) => {
        return m.id === action.meetingId;
      });
      meeting.phone = action.phone;

      return {
        ...state,
        upcomingMeetings: meetings,
      };
    }
    case "REORDER_MEETING_SLIDES":
      let tmpMeeting = JSON.parse(JSON.stringify(state.currentMeeting));

      let slides = [];

      tmpMeeting.sections.forEach((section) => {
        if (!section.slides.some((s) => s.isSectionSlide)) {
          let secSlide = new SlideModel();
          secSlide.header = section.topic.name;
          secSlide.meetingSectionId = section.id;
          secSlide.isSectionSlide = true;
          slides.push(secSlide);
        }
        slides = slides.concat(section.slides);
      });

      let c = 0;
      slides.forEach((s) => {
        s.dragId = c;
        s.isLastSlide = false;
        s.mark = false;
        c++;
      });

      let slideToMove = slides.find((s) => s.dragId === action.oldIndex);

      if (slideToMove.isSectionSlide) {
        // MOVE ALL SLIDES IN THE SECTION
        let slidesToMove = slides
          .filter((s) => s.meetingSectionId === slideToMove.meetingSectionId)
          .sort(function (a, b) {
            return a.dragId - b.dragId;
          });

        let newIx = action.newIndex;
        let oldIx = action.oldIndex;

        if (newIx > oldIx) {
          slidesToMove.forEach((s) => {
            slides = arrayMove(slides, oldIx, newIx);
          });
        } else {
          slidesToMove.forEach((s) => {
            slides = arrayMove(slides, oldIx, newIx);
            newIx++;
          });
        }

        let posCounter = 1;
        let dragCounter = 0;
        slides.forEach((s) => {
          if (s.isSectionSlide) {
            tmpMeeting.sections.find((x) => x.id === s.meetingSectionId).positionInMeeting = posCounter;
            posCounter++;
          }
          s.dragId = dragCounter;
          dragCounter++;
        });
      } else {
        // MOVE SINGLE SLIDE
        if (action.newIndex === 0)
          return {
            ...state,
            currentMeeting: tmpMeeting,
          };

        let prevSlide;
        if (action.newIndex < slides.length - 1) {
          if (action.newIndex > action.oldIndex + 1) {
            prevSlide = slides.find((s) => s.dragId === action.newIndex);
            slideToMove.meetingSectionId = prevSlide.meetingSectionId;
          } else if (action.newIndex < action.oldIndex) {
            prevSlide = slides.find((s) => s.dragId === action.newIndex - 1);
            slideToMove.meetingSectionId = prevSlide.meetingSectionId;
          } else {
            prevSlide = slides.find((s) => s.dragId === action.newIndex);
            slideToMove.meetingSectionId = prevSlide.meetingSectionId;
          }
        } else {
          prevSlide = slides.find((s) => s.dragId === action.newIndex);
          slideToMove.meetingSectionId = prevSlide.meetingSectionId;
        }

        slides = arrayMove(slides, action.oldIndex, action.newIndex);

        let slidesInSection = slides.filter((sl) => sl.meetingSectionId === slideToMove.meetingSectionId && sl.isSectionSlide !== true);

        let counter = 1;
        slidesInSection.forEach((s) => {
          s.positionInSection = counter;
          counter++;
        });

        tmpMeeting.sections.forEach((s) => {
          let slidesInSection = slides.filter((sl) => sl.meetingSectionId === s.id);
          s.slides = slidesInSection;
          s.mark = false;
        });
      }
      tmpMeeting.hasChanged = true;

      return {
        ...state,
        currentMeeting: tmpMeeting,
      };

    case "MARK_AFFECTED_MEETING_SLIDES": {
      let meeting = JSON.parse(JSON.stringify(state.currentMeeting));
      let _slides = [];

      meeting.sections.forEach((section) => {
        if (!section.slides.some((s) => s.isSectionSlide)) {
          let secSlide = new SlideModel();
          secSlide.header = section.topic.name;
          secSlide.meetingSectionId = section.id;
          secSlide.isSectionSlide = true;
          _slides.push(secSlide);
        }
        _slides = _slides.concat(section.slides);
      });

      let co = 0;
      _slides.forEach((s) => {
        s.dragId = co;
        co++;
      });

      let affectedSlide = _slides.find((x) => x.dragId === action.index);
      let affectedSection = null;

      if (affectedSlide && affectedSlide.isSectionSlide) {
        affectedSection = meeting.sections.find((x) => x.id === affectedSlide.meetingSectionId);
        affectedSection.mark = true;

        affectedSection.slides.forEach((s) => {
          s.mark = true;
        });
      }

      meeting.changed = true;

      return {
        ...state,
        currentMeeting: meeting,
      };
    }
    case "SET_CURRENT_SLIDE_ID": {
      return {
        ...state,
        currentSlideId: action.slideId,
      };
    }
    case "SET_CURRENT_SLIDE": {
      let curslide = JSON.parse(JSON.stringify(action.slide));

      if (curslide.imageId) {
        if (curslide.image) curslide.image.id = curslide.imageId;
        else curslide.image = { id: curslide.imageId };
      }

      return {
        ...state,
        currentSlide: curslide,
      };
    }
    case "SAVE_MEETING": {
      return {
        ...state,
        isLoading: true,
      };
    }
    case "RECEIVE_SAVED_MEETING": {
      let updated = state.upcomingMeetings.map((meeting) => (meeting.id === action.meeting.id ? action.meeting : meeting));
      return {
        ...state,
        upcomingMeetings: updated,
        isLoading: false,
      };
    }
    case "SET_MEETING_PDF_DONE": {
      let meetingToUpdate = state.pastMeetings.find((meeting) => meeting.id === action.meetingId);
      if (meetingToUpdate) {
        meetingToUpdate.pdfDone = true;
        let updated = state.pastMeetings.map((meeting) => (meeting.id === action.meetingId ? meetingToUpdate : meeting));
        return {
          ...state,
          pastMeetings: updated,
        };
      }

      return {
        ...state,
      };
    }

    case "DELETING_MEETING": {
      return {
        ...state,
      };
    }

    case "MEETING_DELETED": {
      let _pastMeetings = state.pastMeetings.filter((m) => m.id !== action.meetingId);
      let _upcomingMeetings = state.upcomingMeetings.filter((m) => m.id !== action.meetingId);

      return {
        ...state,
        pastMeetings: _pastMeetings,
        upcomingMeetings: _upcomingMeetings,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
