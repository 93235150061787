import React, { useEffect } from "react";
import api from "../../utils/api";
const LinkHandler = (props) => {
    useEffect(() => {
        const url = `${process.env.REACT_APP_API}/LinkHandler/${props.match.params.id}/${props.match.params.meetingId}`;
        api
        .get(url)
        .then((response) => response.data)
        .then((data) => {
          window.location = data;
        });
    }, [props.match.params]);
    return (
        <div className="all-white"></div>
    )
}
export default LinkHandler;