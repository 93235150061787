import { Button, Container, Grid, TextField, withStyles } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import * as React from "react";
import { connect } from "react-redux";
import * as TemplateStore from "../../store/TemplateStore";
import Loader from "../Loader";
import MeetingTemplateLayout from "./MeetingTemplateLayout";
import SlideGroupTreeView from "./SlideGroupTreeView";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import "./MeetingTemplates.scss";
import DynamicFeedOutlinedIcon from "@material-ui/icons/DynamicFeedOutlined";
import PlusOneIcon from "@material-ui/icons/PlusOne";

const handlePublishChange = (template, props) => (event) => {
  props.togglePublishMeetingTemplate(template.id);
};

const BlueSwitch = withStyles({
  switchBase: {
    color: "#cdd1d4",
    "&$checked": {
      color: "#0982d2",
    },
    "&$checked + $track": {
      backgroundColor: "#2196f3",
    },
  },
  checked: {},
  track: {},
})(Switch);

class MeetingTemplates extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.addTemplateClick = this.addTemplateClick.bind(this);
    this.state = {
      error: "",
      formValid: false,
    };
    this.submitForm = this.submitForm.bind(this);
    this.validateName = this.validateName.bind(this);
  }

  // This method is called when the component is first added to the document
  componentDidMount() {
    this.ensureDataFetched();
  }

  componentWillUnmount() {
    this.props.clearMeetingTemplate();
  }

  ensureDataFetched() {
    const getMaritalStatuses = new Promise((resolve) => {
      this.props.requestMaritalStatuses();
      resolve();
    });

    const getTopics = new Promise((resolve) => {
      this.props.requestTopics();
      resolve();
    });

    const getMeetingTemplates = new Promise((resolve) => {
      this.props.requestMeetingTemplates();
      resolve();
    });

    getMaritalStatuses
      .then(() => {
        getTopics.then(() => {
          getMeetingTemplates.then(() => {});
        });
      })
      .then(() => {
        if (this.props.selectedTemplateId) {
          this.props.requestTemplate(this.props.selectedTemplateId);
        }
      });
  }

  submitForm(e) {
    e.preventDefault();
    console.info("Saving meeting template...");
    if (this.props.meetingTemplate.id) this.props.changeTemplateName(this.props.meetingTemplate.id, this.props.meetingTemplate.name);
    else {
      this.props.meetingTemplate.sections = [];
      this.props.saveMeetingTemplate(this.props.meetingTemplate);
    }
  }

  render() {
    return (
      <div className='meetings-main'>
        <Container maxWidth={false}>
          <div className='main-content'>
            <Grid container direction='row' justify='flex-start' alignItems='flex-start'>
              <Grid item xs={12} sm={3}>
                <div className='header'>Møtemaler</div>
                <Grid item xs={12} className='bordered-grid top-grid'>
                  <div className='grid-title'>Maler</div>
                  {this.renderMeetingTemplatesTable()}
                </Grid>
                <Grid item xs={12} className='bordered-grid bottom-grid' style={{ marginTop: "-1px" }}>
                  <div className='grid-title'>Temaer</div>
                  {this.renderMeetingTemplateSections()}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={9}>
                {this.props.showMeetingTemplateForm && this.props.meetingTemplate ? (
                  <>
                    <div className='header pad-left'>{this.props.meetingTemplate.name ? "Rediger møtemal" : "Ny møtemal"}</div>
                    <div className='meeting-template-form-div'>
                      <form className='meeting-template-form' noValidate autoComplete='off' onSubmit={this.submitForm}>
                        <div className='form-div'>
                          <TextField
                            id='name'
                            className='meeting-template-name'
                            required
                            name='name'
                            label='Navn'
                            inputProps={{ maxLength: 255 }}
                            value={this.props.meetingTemplate.name || ""}
                            onChange={(e) => {
                              this.handleInput(e);
                            }}
                          />
                        </div>
                        {this.state.error !== "" ? <div className='field-error'>{this.state.error}</div> : <></>}
                        <Button
                          variant='contained'
                          type='submit'
                          disabled={!this.state.formValid}
                          className='sbmPrimary add-margin-top add-margin-right'>
                          Lagre
                        </Button>

                        <Button variant='contained' className='sbmSecondary add-margin-top' onClick={(e) => this.cancelNewMeetingTemplate(e)}>
                          Avbryt
                        </Button>
                      </form>
                    </div>
                  </>
                ) : (
                  <MeetingTemplateLayout></MeetingTemplateLayout>
                )}
              </Grid>
            </Grid>
          </div>
        </Container>
        <div className='logo-footer'>
          <Link className='main-link' component={RouterLink} to='/'>
            <img alt='logo' src={require("../../img/logo.png")} width='60px' height='60px' />
          </Link>
        </div>
        {localStorage.getItem("username") && localStorage.getItem("username") !== "" ? (
          <div className='user-top-div'>
            {localStorage.getItem("isAdmin") ? <span>{localStorage.getItem("username")}&nbsp;(Administrator)</span> : <></>}
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }

  handleMeetingTypeChange(e) {
    let val = e.target.value;
    let _meetingTemplate = { ...this.props.meetingTemplate };
    _meetingTemplate.meetingTypeId = val;
    this.props.setMeetingTemplate(_meetingTemplate, undefined);
  }

  cancelNewMeetingTemplate(e) {
    this.props.setShowNewMeetingTemplateForm(false);
  }

  handleSelectChange(e) {
    let val = e.target.value;
    let _meetingTemplate = { ...this.props.meetingTemplate };
    _meetingTemplate.gender = val;
    this.props.setMeetingTemplate(_meetingTemplate, undefined);
  }

  handleMaritalStatusChange(e) {
    let val = e.target.value;
    let _meetingTemplate = { ...this.props.meetingTemplate };
    _meetingTemplate.maritalStatusId = val;
    this.props.setMeetingTemplate(_meetingTemplate, undefined);
  }

  handleInput(e) {
    if (e.target.name === "name") {
      let _meetingTemplate = { ...this.props.meetingTemplate };
      _meetingTemplate.name = e.target.value;

      this.props.setMeetingTemplate(_meetingTemplate, this.validateName);
    }

    if (e.target.name === "ageFrom") {
      let _meetingTemplate = { ...this.props.meetingTemplate };
      _meetingTemplate.aboveAge = parseInt(e.target.value);
      this.props.setMeetingTemplate(_meetingTemplate, undefined);
    }

    if (e.target.name === "ageTo") {
      let _meetingTemplate = { ...this.props.meetingTemplate };
      _meetingTemplate.belowAge = parseInt(e.target.value);
      this.props.setMeetingTemplate(_meetingTemplate, undefined);
    }

    if (e.target.name === "income") {
      let _meetingTemplate = { ...this.props.meetingTemplate };
      _meetingTemplate.income = parseInt(e.target.value);
      this.props.setMeetingTemplate(_meetingTemplate, undefined);
    }
  }

  validateName(val) {
    if (!val || val.length < 3) {
      this.setState({ error: "Navn må være minimum 3 bokstaver." }, this.validateForm);
    } else {
      this.setState({ error: "" }, this.validateForm);
    }
  }

  validateForm() {
    this.setState({ formValid: this.state.error.length === 0 });
  }

  renderMeetingTemplateSections() {
    return this.props.loadingMeetingTemplates || this.props.loadingMeetingTemplate ? (
      <Loader></Loader>
    ) : (
      <div>
        <SlideGroupTreeView {...this.props}></SlideGroupTreeView>
      </div>
    );
  }

  handleRowClick(e) {
    this.props.requestTemplate(e.target.parentElement.id);
  }

  addTemplateClick(e) {
    this.props.setShowNewMeetingTemplateForm(true);
  }

  showTemplateForm(id) {
    this.props.setShowEditMeetingTemplateForm(true);
  }

  renderMeetingTemplatesTable() {
    return this.props.loadingMeetingTemplates ? (
      <Loader />
    ) : (
      <table className='table is-hoverable' style={{ width: "100%" }} aria-labelledby='tabelLabel'>
        <tbody>
          {this.props.meetingTemplates.map((template) => (
            <tr
              key={template.id ? template.id.toString() : "i"}
              id={template.id ? template.id.toString() : "i"}
              className={
                template.id && this.props.meetingTemplate && this.props.meetingTemplate.id && this.props.meetingTemplate.id === template.id
                  ? "is-selected"
                  : ""
              }>
              <td onClick={this.handleRowClick}>
                <div style={{ textAlign: "center" }}>
                  <DynamicFeedOutlinedIcon />
                </div>
              </td>
              <td onClick={this.handleRowClick}>{template.name}</td>
              <td className='grey-small' onClick={this.handleRowClick}>
                {template.sumDuration} min
              </td>
              <td>
                <BlueSwitch checked={template.publishStatusId === 2} onChange={handlePublishChange(template, this.props)} value='published' />
              </td>
              <td>
                <EditOutlinedIcon color='primary' onClick={() => this.showTemplateForm(template.id.toString())} />
              </td>
            </tr>
          ))}
          <tr key='addProfile' onClick={this.addTemplateClick}>
            <td>
              <div style={{marginLeft: '15px'}}>{<PlusOneIcon />}</div>
            </td>
            <td><b>Ny møtemal</b></td>
            <td className='plus-column'></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    );
  }
}

export default connect(
  (state) => state.templates, // Selects which state properties are merged into the component's props
  TemplateStore.actionCreators // Selects which action creators are merged into the component's props
)(MeetingTemplates);
