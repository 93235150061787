import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as MeetingStore from "../../store/Meeting/MeetingsStore";
import * as SlideStore from "../../store/slides/SlidesStore";
import { setCurrentSlideForMeeting } from "../../store/configureStore";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import api from "../../utils/api";

const useKeyPress = (targetKey) => {
  const [keyPressed, setKeyPressed] = useState(false);

  function downHandler({ key }) {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }

  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  });

  return keyPressed;
};

const useStyles = makeStyles(() =>
  createStyles({
    columns: {
      display: "flex",
      flexDirection: "column",
      minWidth: "100vw",
      minHeight: "100vh",
      justifyContent: "space-between",
    },
    rows: {
      display: "flex",
      flexDirection: "row",
      flex: "1",
      justifyContent: "space-between",
      height: "75vh",
    },
    imageContainer: {
      backgroundSize: "cover",
      display: "flex",
      minWidth: "100%",
    },
    contentWrapper: {
      width: "100%",
      height: "100%",
      background: "linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 60, 77, 0.8))",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    footer: {
      display: "flex",
      flex: "0 0 10%",
      zIndex: 1,
    },
    leftColumn: {
      display: "flex",
      flex: "0 0 5%",
      cursor: "pointer",
      zIndex: 2,
    },
    rightColumn: {
      display: "flex",
      flex: "0 0 5%",
      cursor: "pointer",
      zIndex: 2,
      marginTop: "200px",
    },
    content: {
      display: "flex",
      flex: "0 0 80%",
      justifyContent: "center",
      alignItems: "center",
    },
    fullscreen: {
      cursor: "pointer",
      height: "30px",
      zIndex: 1,
    },
    map: {
      cursor: "pointer",
      marginRight: "2.6rem",
      marginTop: "-0.3rem",
      zIndex: 1,
    },
  })
);

const AgendaSlide = (props) => {
  const history = useHistory();
  const classes = useStyles({});
  const leftPress = useKeyPress("ArrowLeft");
  const rightPress = useKeyPress("ArrowRight");
  const [fullScreen, setFullScreen] = useState(false);
  const [meeting, setMeeting] = useState(undefined);
  const presentationId = props.match.params.id;

  useEffect(() => {
    const url = `${process.env.REACT_APP_API}/meetings/agendaInfo/${presentationId}`;
    api
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        data.agendaItems = JSON.parse(data.agendaItems);
        setMeeting(data);
      });

      setCurrentSlideForMeeting(parseInt(props.match.params.id), -1, getSignalrResult);

    // unmount replacement
    // eslint-disable-next-line
  }, [presentationId]);

  const getSignalrResult = (result) => {
    console.info("signalRResult", result || "Ok");
  };

  const toggleFullscreen = () => {
    let elem = document.documentElement;
    if (fullScreen) {
      setFullScreen(false);
      document.exitFullscreen();
    } else {
      elem.requestFullscreen();
      setFullScreen(true);
    }
  };

  if (leftPress) {
    setTimeout(() => {
      goToStart();
    }, 300);
  }

  if (rightPress) {
     setTimeout(() => {
      goToFirstSlide();
    }, 300);
  }

  const goToMap = () => {
    history.push("/presentation/" + presentationId + "/map");
  };

  const goToStart = () => {
    history.push("/presentation/" + meeting.id);
  };
  
  const goToFirstSlide = () => {
    if(meeting.firstSlideId == 0)
      history.push("/presentation/" + meeting.id + "/end");
    else
      history.push("/presentation/" + meeting.id + "/" + meeting.firstSlideId);
  };

  return meeting ? (
    <>
      <div style={{ backgroundColor: "#01052F", color: "#fff", width: "100vw", height: "100vh", display: "flex", zIndex: 2, position: "absolute" }}>
        <div className='slide-layout-header'>
          <div className='sl-header-container'>
            <div className={classes.map}>
              <ViewCarouselIcon onClick={goToMap} style={{ color: "rgba(255, 255, 255, 0.6)", fontSize: "2.5rem" }} />
            </div>
            {fullScreen ? (
              <div onClick={toggleFullscreen} className={classes.fullscreen} title='Avslutt fullskjerm'>
                <FullscreenExitIcon style={{ color: "white", fontSize: "30px", marginRight: "20px" }}></FullscreenExitIcon>
              </div>
            ) : (
              <div onClick={toggleFullscreen} className={classes.fullscreen} title='Fullskjerm'>
                <FullscreenIcon style={{ color: "white", fontSize: "30px", marginRight: "20px" }}></FullscreenIcon>
              </div>
            )}
          </div>
        </div>
        <div className='end-slide-dark'>
          <div className='end-slide-nice-day'>Dagens møte</div>
        </div>
        <div className='end-slide-brighter'>
          <ul>
          { meeting.agendaItems?.map((agendaItem, index) => (
                <li key={index} className='agenda-slide-item'>{agendaItem}</li>
          ))}
         </ul>
        </div>
        <div className='end-slide-white'>
          <div className='end-slide-logo'>
            <img alt='logo' src={require("../../img/logo_slutt.png")} width='50%' height='auto' />
          </div>
        </div>
      </div>

      <div className={classes.rows}>
        <div className={classes.leftColumn} onClick={goToStart} title='Gå til forrige'></div>
        <div className={classes.rightColumn} onClick={goToFirstSlide} title='Avslutt'></div>
      </div>

      <div className='logo-footer' style={{ zIndex: 2 }}>
        <Link className='main-link' component={RouterLink} to='/'>
          <img alt='logo' src={require("../../img/logo.png")} width='60px' height='60px' />
        </Link>
      </div>
    </>
  ) : (
    <></>
  );
};

export default connect(
  (state) => ({
    isLoading: state.slides.isLoading,
    slides: state.slides,
    meetings: state.meetings,
  }),
  Object.assign(SlideStore.actionCreators, MeetingStore.actionCreators)
)(AgendaSlide);
