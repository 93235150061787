import React from "react";

const AgendaSlidePrint = (props) => {
  const queryString = require("query-string");
  const parsed = queryString.parse(props.location.search);
  let agendaItems = JSON.parse(parsed.agendaItems);

  return (
    <>
      <div style={{ backgroundColor: "#01052F", color: "#fff", width: "100vw", height: "100vh", display: "flex", zIndex: 2, position: "absolute" }}>
        <div className='slide-layout-header'></div>
        <div className='end-slide-dark'>
          <div className='end-slide-nice-day'>Møteagenda</div>
        </div>
        <div className='end-slide-brighter'>
          <ul>
          { agendaItems?.map((agendaItem, index) => (
                <li key={index} className='agenda-slide-item'>{agendaItem}</li>
          ))}
         </ul>
        </div>
        <div className='end-slide-white'>
          <div className='end-slide-logo'>
            <img alt='logo' src={require("../../img/logo_slutt.png")} width='50%' height='auto' />
          </div>
        </div>
      </div>
    </>
  ); 
}

export default AgendaSlidePrint;
