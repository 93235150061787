import { createStyles, Fade } from "@material-ui/core";
import BookmarkTwoToneIcon from "@material-ui/icons/BookmarkTwoTone";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import Button from "@material-ui/core/Button/Button";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const useStyles = makeStyles((theme) =>
  createStyles({
    content: {
      display: "flex",
    },
    noteContainer: {
      display: "flex",
      flexDirection: "column",
      border: "0.5rem white solid",
      borderRadius: "0.25rem",
      width: "670px",
      height: "500px",
      background: "white",
      overflow: "hidden",
      position: "absolute",
      right: "20px",
      top: "90px",
    },
    noteContainerButtons: {
      display: "flex",
      borderTop: "1px solid lightgrey",
      padding: "15px",
      bottom: 0,
      position: "absolute",
      width: "100%",
    },
    icon: {
      color: "white",
      cursor: "pointer",
    },
  })
);


const NotesComponent = React.forwardRef((props, ref) => {
  const [isOpen, toggleOpen] = useState(props.startOpen);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const classes = useStyles({});
  const [editorReference, setEditorReference] = useState(ref);

  useEffect(() => {
    if (props.slide.note) {
      setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(props.slide.note))));
    } else {
      setEditorState(EditorState.createEmpty());
    }
    // eslint-disable-next-line
  }, [props.slide.id]);

  function handleClick() {
    toggleOpen(!isOpen);
    editorReference.focusEditor();
  }

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const saveNote = () => {
    props.onNoteChange(JSON.stringify(convertToRaw(editorState.getCurrentContent())));
  };

  return (
    <React.Fragment>
      <div className={classes.content}>
        {
          <Fade ref={ref} in={isOpen} timeout={500}>
            <div className={classes.noteContainer}>
              <Editor
                editorState={editorState}
                ref={setEditorReference}
                onEditorStateChange={onEditorStateChange}
                toolbarClassName='toolbar-class'
                toolbar={{
                  options: ["inline", "blockType", "fontSize", "fontFamily", "list", "textAlign", "history"],
                  inline: { inDropdown: true },
                  list: { inDropdown: true },
                  textAlign: { inDropdown: true },
                  history: { inDropdown: true },
                }}
              />

              <div className={classes.noteContainerButtons}>
                <Button className='sbmPrimary' onClick={saveNote}>
                  Lagre
                </Button>
                <Button className='sbmSecondary' onClick={handleClick}>
                  Lukk
                </Button>
              </div>
            </div>
          </Fade>
        }
        <div onClick={handleClick}>
          <BookmarkTwoToneIcon style={{ fontSize: "30px" }} onClick={() => handleClick()} className={classes.icon} />
        </div>
      </div>
    </React.Fragment>
  );
});

export default NotesComponent;
