import { Container, Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import MeetingLayout from "./MeetingLayout";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { connect } from "react-redux";
import * as MeetingsStore from "../../../store/Meeting/MeetingsStore";

function GetNothing (){
  console.log("asdasd");
}

const MeetingDetails = (props) => {
  const meetingId = props.match.params.id;

  useEffect(() => {
    props.requestMeeting(parseInt(meetingId));
    // eslint-disable-next-line
  }, [meetingId]);

  return (
    <div className='meetings-main'>
      <Container maxWidth={false}>
        <div className='main-content'>
          <Grid container direction='row' justify='flex-start' alignItems='flex-start'>
            <MeetingLayout id={meetingId}></MeetingLayout>
          </Grid>
        </div>
      </Container>
      <div className='logo-footer'>
        <Link className='main-link' component={RouterLink} to='/'>
          <img alt='logo' src={require("../../../img/logo.png")} width='60px' height='60px' />
        </Link>
      </div>
      {localStorage.getItem("username") && localStorage.getItem("username") !== "" ? (
        <div className='user-top-div'>
          {localStorage.getItem("isAdmin") ? <span>{localStorage.getItem("username")}&nbsp;(Administrator)</span> : <></>}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default connect(
  (state) => ({
    meetings: state.meetings,
  }),
  Object.assign(MeetingsStore.actionCreators)
)(MeetingDetails);
