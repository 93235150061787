import { Button, Slider, TextField, withStyles } from "@material-ui/core";
import reactCSS from "reactcss";
import StopIcon from "@material-ui/icons/Stop";
import React, { useState, forwardRef, useEffect, useRef } from "react";
import { connect } from "react-redux";
import * as TemplateStore from "../../store/TemplateStore";
import RGL, { WidthProvider } from "react-grid-layout";
import "../../../node_modules/react-grid-layout/css/styles.css";
import "../../../node_modules/react-resizable/css/styles.css";
import _ from "lodash";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Edit from "@material-ui/icons/Edit";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import { SketchPicker } from "react-color";
import ParagraphComponent from "../ParagraphComponent";
import Remove from "@material-ui/icons/Remove";
import {
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import api from "../../utils/api";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Remove: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
};

const fontSizes = {
  10: "10",
  14: "14",
  18: "18",
  20: "20",
  24: "24",
  28: "28",
  30: "30",
  34: "34",
  38: "38",
  40: "40",
  45: "45",
  50: "50",
  55: "55",
  60: "60",
  65: "65",
  70: "70",
  75: "75",
  80: "80",
  85: "85",
  90: "90",
  95: "95",
};

const fonts = {
  SBMSansWeb: "SBM",
  Arial: "Arial",
};

const alignments = {
  Left: "V",
  Center: "M",
  Right: "H",
};

const fontStyles = {
  Normal: "Norm",
  Italic: "Kurs",
};

const fontWeights = {
  Normal: "N",
  Bold: "F",
};

const presetColors = [
  {
    color: "#01052F",
    title: "Midnatt",
  },
  {
    color: "#000E5A",
    title: "Byge",
  },
  {
    color: "#00149E",
    title: "Marine",
  },
  {
    color: "#0066FF",
    title: "Hav",
  },
  {
    color: "#26A9FB",
    title: "Korall",
  },
  {
    color: "#7EC8F8",
    title: "Himmel",
  },
  {
    color: "#DCF3FD",
    title: "Sky",
  },
  {
    color: "#54C88E",
    title: "Havgrønn",
  },
  {
    color: "#A2E2B6",
    title: "Vårgrønn",
  },
  {
    color: "#A39576",
    title: "Skumring",
  },
  {
    color: "#E5DACF",
    title: "Giskesand",
  },
  {
    color: "#F2EAE2",
    title: "Sommersnø",
  },
  {
    color: "#F2F2F2",
    title: "Tåkedis",
  },
  {
    color: "#FFFFFF",
    title: "Snøhvit",
  },
];

const textTableColumns = [
  {
    title: "Tekst",
    field: "text",
    emptyValue: "",
    editComponent: (props) => (
      <textarea
        rows="5"
        cols="34"
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
      />
    ),
  },
  {
    title: "URL",
    field: "externalURL",
    emptyValue: "",
    editComponent: (props) => (
      <textarea
        rows="5"
        cols="34"
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
      />
    ),
  },
  {
    title: "Åpne i ny fane",
    field: "openInNewTab",
    initialEditValue: false,
    type: "boolean",
  },
  { title: "i",
    field: "isParagraph",
    initialEditValue: false,
    type: "boolean" 
  },
  {
    title: "Font",
    field: "font",
    initialEditValue: fonts.SBMSansWeb,
    lookup: fonts,
  },
  {
    title: "Vekt",
    field: "fontWeight",
    initialEditValue: fontWeights.Normal,
    lookup: fontWeights,
  },
  {
    title: "Stil",
    field: "fontStyle",
    initialEditValue: fontStyles.Normal,
    lookup: fontStyles,
  },
  {
    title: "Just.",
    field: "alignment",
    initialEditValue: alignments.Left,
    lookup: alignments,
  },
  {
    title: "Str",
    field: "fontSize",
    lookup: fontSizes,
    initialEditValue: "24",
  },
  {
    title: "Farge",
    field: "fontColor",
    render: (rowData) => {
      return (
        <>
          <div
            style={{
              width: "36px",
              height: "14px",
              borderRadius: "2px",
              border: "1px solid black",
              backgroundColor: rowData.fontColor,
            }}
          />
        </>
      );
    },
    editComponent: (props) => {
      return (
        <div
          style={styles.swatch}
          onClick={() => {
            let data = { ...props.rowData };
            data.displayPicker = !data.displayPicker;
            props.onRowDataChange(data);
          }}
        >
          <div
            style={{
              width: "36px",
              height: "14px",
              borderRadius: "2px",
              backgroundColor: props.value,
            }}
          />
        </div>
      );
    },
  },
  {
    title: "",
    field: "fontColor",
    initialEditValue: "#000000",
    render: (rowData) => <div style={{ width: "1px" }}></div>,
    editComponent: (props) => (
      <>
        {props.rowData.displayPicker ? (
          <div>
            <SketchPicker
              color={props.value}
              onChange={(e) => {
                props.onChange(e.hex);
              }}
              presetColors={presetColors}
            />
          </div>
        ) : null}
      </>
    ),
  },
  {
    title: "displayPicker",
    field: "picker",
    type: "boolean",
    hidden: true,
    emptyValue: false,
  },
  { title: "V%", field: "marginLeft", type: "numeric" },
  { title: "H%", field: "marginRight", type: "numeric" },
  { title: "T%", field: "marginTop", type: "numeric" },
];

const imageTableColumns = [
  {
    title: "",
    field: "f",
    editComponent: ({ onChange, rowData, onRowDataChange, ...props }) => (
      <input
        type="file"
        onChange={(e) => {
          const formData = new FormData();
          formData.append("file", e.target.files[0]);
          const apiUrl = process.env.REACT_APP_API + "/image";

          const config = {
            headers: {
              "content-type": "multipart/form-data",
            },
          };

          api
            .post(apiUrl, formData, config)
            .then((response) => {
              const newRowData = { ...rowData, imageId: response.data };
              onRowDataChange(newRowData);
            })
            .catch((err) => {
              console.log("upload error", err);
            });
        }}
      />
    ),
  },
  {
    title: "Just.",
    field: "alignment",
    initialEditValue: "Left",
    lookup: alignments,
  },
  {
    title: "% Bredde",
    field: "width",
    type: "numeric",
    initialEditValue: "100",
  },
  {
    title: "% Fra topp",
    initialEditValue: "0",
    field: "marginTop",
    type: "numeric",
  },
  {
    title: "% Fra venstre",
    initialEditValue: "0",
    field: "marginLeft",
    type: "numeric",
  },
  {
    title: "% Fra høyre",
    initialEditValue: "0",
    field: "marginRight",
    type: "numeric",
  },
  {
    title: "bilde",
    editable: "never",
    field: "image",
    render: (rowData) => (
      <img
        alt=""
        src={
          rowData ? `${process.env.REACT_APP_API}/image/${rowData.imageId}` : ""
        }
        style={{ width: 50 }}
      />
    ),
  },
];

const marks = [
  {
    value: 0,
    label: "0 min",
  },
  {
    value: 20,
    label: "20 min",
  },
];

const valuetext = (value) => {
  return `${value} min`;
};

const SMBSlider = withStyles({
  root: {
    color: "#919292",
    height: 3,
    padding: "13px 0",
  },
  markLabel: {
    fontSize: "0.7rem",
    color: "#919292",
  },
  thumb: {
    height: 25,
    width: 25,
    backgroundColor: "#fff",
    border: "none",
    marginTop: -12,
    marginLeft: -12,
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 11px)",
    top: -5,
    "& *": {
      background: "transparent",
      color: "#000",
    },
  },
  track: {
    height: 2,
  },
  rail: {
    color: "#d8d8d8",
    opacity: 0.5,
    height: 2,
  },
  mark: {
    backgroundColor: "#bfbfbf",
    height: 8,
    width: 1,
    marginTop: -3,
  },
  markActive: {
    opacity: 1,
    backgroundColor: "currentColor",
  },
})(Slider);

function SliderThumb(props) {
  return (
    <span {...props}>
      <StopIcon style={{ transform: "rotate(45deg)" }}></StopIcon>
    </span>
  );
}

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} id={`simple-tabpanel-${index}`} {...other}>
      {value === index && <div>{children}</div>}
    </div>
  );
};

const ReactGridLayout = WidthProvider(RGL);

const styles = reactCSS({
  default: {
    color: {
      width: "36px",
      height: "14px",
      borderRadius: "2px",
    },
    swatch: {
      padding: "5px",
      background: "#fff",
      borderRadius: "1px",
      boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
      display: "inline-block",
      cursor: "pointer",
    },
    popover: {
      position: "absolute",
      marginTop: "-190px",
      marginLeft: "200px",
      zIndex: "2",
    },
    cover: {
      position: "fixed",
      marginTop: "-120px",
      marginLeft: "150px",
    },
  },
});

const SlideTemplateEdit = (props) => {
  const [open, setOpen] = useState(false);
  const [meetingTemplate, setMeetingTemplate] = useState(0);

  const handleClose = () => {
    setOpen(false);
  };

  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [counter, setCounter] = useState();
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [selectedZone, setSelectedZone] = useState(undefined);
  const [selectedTab, setSelectedTab] = useState(0);

  const addItem = () => {
    let layout = addLayoutItem(slideTemplate, counter);

    let zones = addZone(slideTemplate, counter);

    setCounter(counter + 1);
    setSlideTemplate({ ...slideTemplate, zones, layout });
  };

  const removeItem = (id) => {
    let layout = _.cloneDeep(slideTemplate.layout);
    layout = layout.filter((i) => i.i !== id);

    let zones = _.cloneDeep(slideTemplate.zones);
    zones = zones.filter((z) => z.zoneId !== id);

    setSlideTemplate({ ...slideTemplate, zones, layout });
    setCounter(counter - 1);
  };

  const removeStyle = {
    position: "absolute",
    right: "2px",
    top: 0,
    cursor: "pointer",
  };

  const [slideTemplate, setSlideTemplate] = useState(undefined);

  const [height, setHeight] = useState(0);

  const layoutChange = (layout) => {
    if (slideTemplate) {
      setSlideTemplate({ ...slideTemplate, layout });
    }
  };

  useEffect(() => {
    setHeight(ref.current.clientHeight);
    setSlideTemplate(props.slideTemplate);
    layoutChange(props.slideTemplate.layout);
    setCounter(props.slideTemplate.zones.length + 1);
    // eslint-disable-next-line
  }, [props.slideTemplate.id]);

  useEffect(() => {
    setSlideTemplate(props.slideTemplate);
    // eslint-disable-next-line
  }, [props.slideTemplate.media]);

  const ref = useRef(null);

  const toggleSelected = (i) => {
    if (selectedItem !== undefined && selectedItem.i === i) {
      setSelectedItem(undefined);
      setSelectedZone(undefined);
    } else {
      setSelectedItem(slideTemplate.layout.find((x) => x.i === i));
      setSelectedZone(slideTemplate.zones.find((x) => x.zoneId === i));
    }
  };

  const handleTabChange = (event, newVal) => {
    setSelectedTab(newVal);
  };

  const toggleShowPicker = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const hidePicker = () => {
    setDisplayColorPicker(false);
  };

  const setZoneColor = (color) => {
    let _zones = _.cloneDeep(slideTemplate.zones);
    _zones.find((x) => x.zoneId === selectedZone.zoneId).backgroundColor =
      "rgba(" +
      color.rgb.r +
      ", " +
      color.rgb.g +
      ", " +
      color.rgb.b +
      ", " +
      color.rgb.a +
      ")";
    setSelectedZone({
      ...selectedZone,
      backgroundColor:
        "rgba(" +
        color.rgb.r +
        ", " +
        color.rgb.g +
        ", " +
        color.rgb.b +
        ", " +
        color.rgb.a +
        ")",
    });
    setSlideTemplate({ ...slideTemplate, zones: _zones });
  };

  const removeImage = () => {
    setSelectedZone({
      ...selectedZone,
      backgroundImage: null,
      backgroundImageName: null,
    });

    let _zones = _.cloneDeep(slideTemplate.zones);
    _zones.find((x) => x.zoneId === selectedZone.zoneId).backgroundImage = null;
    _zones.find((x) => x.zoneId === selectedZone.zoneId).backgroundImageName =
      null;
    setSlideTemplate({ ...slideTemplate, zones: _zones });
  };

  const handleImageChange = (e) => {
    e.preventDefault();

    let _selectedZone = _.cloneDeep(selectedZone);
    let _zones = _.cloneDeep(slideTemplate.zones);

    let file = e.target.files[0];

    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    const apiUrl = process.env.REACT_APP_API + "/image";

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    api
      .post(apiUrl, formData, config)
      .then((response) => {
        _selectedZone.backgroundImageName = file.name;
        _selectedZone.backgroundImageId = response.data;
        _zones.find((x) => x.zoneId === selectedZone.zoneId).backgroundImageId =
          _selectedZone.backgroundImageId;
        _zones.find(
          (x) => x.zoneId === selectedZone.zoneId
        ).backgroundImageName = _selectedZone.backgroundImageName;
        setSelectedZone(_selectedZone);
        setSlideTemplate({ ...slideTemplate, zones: _zones });
      })
      .catch((err) => {
        console.log("upload error", err);
      });
  };

  const handleTitleChange = (e) => {
    setSlideTemplate({ ...slideTemplate, title: e.target.value });
  };

  const handleDurationChange = (_event, newValue) => {
    setSlideTemplate({ ...slideTemplate, estimatedDuration: newValue });
  };

  const handleVideoChange = (e) => {
    e.preventDefault();

    let _selectedZone = _.cloneDeep(selectedZone);
    let _zones = _.cloneDeep(slideTemplate.zones);
    let file = e.target.files[0];
    _selectedZone.videoFile = file;
    _zones.find((x) => x.zoneId === selectedZone.zoneId).videoFile = file;
    setSelectedZone(_selectedZone);
    setSlideTemplate({ ...slideTemplate, zones: _zones });
  };

  const uploadFile = (id) => {
    const formData = new FormData();
    formData.append(
      "body",
      slideTemplate.zones.find((x) => x.zoneId === id).videoFile
    );
    const input = {
      formData,
      itemId: parseInt(id),
      slideTemplateId: slideTemplate.id,
    };

    props.uploadSlideTemplateFile(input);
  };

  const deleteMediaFileFromZone = (id) => {
    props.deleteMediaFile(slideTemplate.id, id);
  };

  const saveSlideTemplate = () => {
    props.saveSlideTemplate(slideTemplate);
  };

  const copySlideTemplateToMeetingTemplate = () => {
    props.copySlideTemplate(
      meetingTemplate === 0 ? props.meetingTemplate.id : meetingTemplate,
      slideTemplate.id
    );
  };

  const handleMandatoryChange = (e) => {
    setSlideTemplate({ ...slideTemplate, mandatory: e.target.checked });
  };

  const handleLoopChange = (e, zoneId) => {
    let _media = _.cloneDeep(slideTemplate.media);
    let mediaEntry = _media.find((x) => x.zoneId === zoneId);
    mediaEntry.loop = e.target.checked;
    setSlideTemplate({ ...slideTemplate, media: _media });
  };

  return (
    <>
      <div className="right-content">
        <div className="slide-section-toolbox">
          <Button
            disabled={!counter || counter > 6}
            style={{ marginBottom: "20px" }}
            onClick={() => addItem()}
          >
            Legg til sone
          </Button>
        </div>
        <div className="slide-edit-btns">
          <Button
            variant="contained"
            className="sbmPrimary"
            onClick={saveSlideTemplate}
          >
            Lagre
          </Button>
          <Button
            variant="contained"
            className="sbmSecondary"
            onClick={props.clearSlideTemplate}
          >
            Lukk
          </Button>
          <Button
            variant="contained"
            className="sbmPrimary"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              setOpen(true);
            }}
          >
            Kopier slide
          </Button>
        </div>

        <div className="slide-layout-container" ref={ref}>
          {slideTemplate ? (
            <ReactGridLayout
              // className='layout'
              autoSize={false}
              cols={48}
              rowHeight={height / 100}
              margin={[0, 0]}
              rows={100}
              preventCollision={false}
              onLayoutChange={layoutChange}
            >
              {slideTemplate.layout.map((el, c) => {
                let zone = slideTemplate.zones.find((z) => z.zoneId === el.i);
                return (
                  <div
                    key={el.i}
                    data-grid={el}
                    id={"zone_" + el.i}
                    style={{
                      background: zone.backgroundImageId
                        ? `center / cover no-repeat url(${process.env.REACT_APP_API}/image/${zone.backgroundImageId})`
                        : null,
                      backgroundColor: zone.backgroundColor,
                      overflow: "hidden",
                    }}
                    className={
                      selectedItem && selectedItem.i === el.i
                        ? "zone selected"
                        : "zone"
                    }
                    onClick={() => toggleSelected(el.i)}
                  >
                    <span
                      className="remove"
                      style={removeStyle}
                      onClick={() => removeItem(el.i)}
                    >
                      x
                    </span>
                    {
                      // tekster
                      slideTemplate.texts.filter((x) => x.zoneId === el.i)
                        .length > 0 ? (
                        slideTemplate.texts
                          .filter((x) => x.zoneId === el.i)
                          .map((data, c) => {
                            return !data.isParagraph ? (
                              <div
                                key={`text_wrapper_${el.id}_${c}`}
                                className="slide-text-wrapper"
                                style={{
                                  textAlign: data.alignment
                                    ? data.alignment
                                    : "left",
                                  top: data.marginTop + "%",
                                  position: "absolute",
                                }}
                              >
                                <div
                                  key={`text_${el.id}_${c}`}
                                  style={{
                                    display: "inline-block",
                                    fontStyle: data.fontStyle
                                      ? data.fontStyle
                                      : "normal",
                                    fontWeight: data.fontWeight,
                                    fontFamily: data.font,
                                    fontSize:
                                      parseInt(data.fontSize) / 2 + "px",
                                    color: data.fontColor,
                                    marginLeft: data.marginLeft + "%",
                                    marginRight: data.marginRight + "%",
                                    whiteSpace: "pre-wrap",
                                  }}
                                >
                                  {data.text}
                                </div>
                              </div>
                            ) : (
                              <div
                                key={`text_wrapper_${el.id}_${c}`}
                                className="slide-text-wrapper"
                                style={{
                                  textAlign: data.alignment
                                    ? data.alignment
                                    : "left",
                                  top: data.marginTop + "%",
                                  position: "absolute",
                                }}
                              >
                                <div
                                  key={`text_${el.id}_${c}`}
                                  style={{
                                    display: "block",
                                    fontFamily: data.font,
                                    whiteSpace: "pre-wrap",
                                  }}
                                >
                                  <ParagraphComponent
                                    color={data.fontColor}
                                    size={parseInt(data.fontSize) / 2 + "px"}
                                    weight={data.fontWeight}
                                    fontFamily={data.font}
                                    fontStyle={data.fontStyle}
                                    text={data.text}
                                    alignment={data.alignment}
                                    marginLeft={data.marginLeft + "%"}
                                    marginRight={data.marginRight + "%"}
                                  />
                                </div>
                              </div>
                            );
                          })
                      ) : (
                        <></>
                      )
                    }

                    {
                      // bilder
                      slideTemplate.images.filter((x) => x.zoneId === el.i)
                        .length > 0 ? (
                        slideTemplate.images
                          .filter((x) => x.zoneId === el.i)
                          .map((data, c) => {
                            return (
                              <div
                                key={`img_wrapper_${el.id}_${c}`}
                                className="slide-text-wrapper"
                                style={{
                                  textAlign: data.alignment
                                    ? data.alignment
                                    : "left",
                                  top: data.marginTop + "%",
                                  position: "fixed",
                                  marginLeft: data.marginLeft + "%",
                                  marginRight: data.marginRight + "%",
                                }}
                              >
                                <img
                                  key={c + "_img_" + el.i}
                                  alt=""
                                  style={{
                                    width: data.width
                                      ? data.width + "%"
                                      : "auto",
                                    height: data.height
                                      ? data.height + "%"
                                      : "auto",
                                    objectFit: "cover",
                                  }}
                                  src={`${process.env.REACT_APP_API}/image/${data.imageId}`}
                                />
                              </div>
                            );
                          })
                      ) : (
                        <></>
                      )
                    }
                    {
                      // video
                      slideTemplate.media.filter((x) => x.zoneId === el.i)
                        .length > 0 ? (
                        slideTemplate.media
                          .filter((x) => x.zoneId === el.i)
                          .map((data, c) => {
                            return (
                              <div
                                className="player-wrapper"
                                key={"pw_" + el.i}
                              >
                                <video
                                  controls
                                  width="100%"
                                  height="100%"
                                  loop
                                >
                                  <source
                                    src={`${process.env.REACT_APP_API}/videos/${data.id}`}
                                    type="video/mp4"
                                  />
                                </video>
                              </div>
                            );
                          })
                      ) : (
                        <></>
                      )
                    }
                  </div>
                );
              })}
            </ReactGridLayout>
          ) : (
            <></>
          )}
          {props.meetingTemplate ? (
            <div>
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="draggable-dialog-title"
              >
                <DialogTitle
                  style={{ width: "450px" }}
                  id="draggable-dialog-title"
                >
                  Velg møtemal å kopiere til
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    <FormControl style={{ width: "100%" }}>
                      <InputLabel id="mt-selector-label">Møtemal</InputLabel>
                      <Select
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                        }}
                        defaultValue={props.meetingTemplate.id}
                        id="mt-selector"
                        onChange={(e) => setMeetingTemplate(e.target.value)}
                      >
                        {props.meetingTemplates &&
                          props.meetingTemplates.map((mt) => {
                            return (
                              <MenuItem key={`mt_${mt.id}`} value={mt.id}>
                                {mt.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    onClick={() => copySlideTemplateToMeetingTemplate()}
                    className="sbmPrimary"
                  >
                    Kopier
                  </Button>
                  <Button
                    variant="contained"
                    autoFocus
                    onClick={handleClose}
                    className="sbmSecondary"
                  >
                    Avbryt
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          ) : null}
        </div>

        {selectedItem ? (
          <div className="slidetemplate-form-options">
            <Tabs
              value={selectedTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTabChange}
            >
              <Tab label="Sone" />
              <Tab label="Tekst" />
              <Tab label="Bilde" />
              <Tab label="Video" />
            </Tabs>
            <TabPanel value={selectedTab} index={0}>
              <div className="zone-div">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div>
                    <label className="slideTemplateFormElement">
                      Bakgrunnsfarge:
                    </label>
                    <div
                      style={styles.swatch}
                      onClick={() => toggleShowPicker()}
                    >
                      <div
                        style={{
                          width: "36px",
                          height: "14px",
                          borderRadius: "2px",
                          backgroundColor: selectedZone.backgroundColor,
                        }}
                      />
                    </div>
                    {displayColorPicker ? (
                      <div style={styles.popover}>
                        <div style={styles.cover} onClick={hidePicker} />
                        <SketchPicker
                          color={selectedZone.backgroundColor}
                          onChange={setZoneColor}
                          presetColors={presetColors}
                        />
                      </div>
                    ) : null}
                  </div>
                  <label className="slideTemplateFormElement">
                    Bakgrunnsbilde:
                  </label>
                  {selectedZone.backgroundImageName ? (
                    <>
                      <div>{selectedZone.backgroundImageName}</div>
                      <Button
                        variant="contained"
                        style={{
                          marginTop: "10px",
                          marginLeft: "0px !important",
                        }}
                        className="sbmPlain"
                        onClick={() => removeImage()}
                      >
                        Fjern
                      </Button>
                    </>
                  ) : (
                    <div>
                      <input
                        key={"bg" + selectedZone.zoneId}
                        type="file"
                        onChange={(e) => handleImageChange(e)}
                      ></input>
                    </div>
                  )}
                </div>
              </div>
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
              <div className="texts-div">
                <div style={{ width: "100%" }}>
                  <MaterialTable
                    title=""
                    columns={textTableColumns}
                    icons={tableIcons}
                    data={slideTemplate.texts.filter(
                      (x) => x.zoneId === selectedItem.i
                    )}
                    options={{ paging: false, search: false }}
                    localization={{
                      header: { actions: "Handlinger" },
                      body: {
                        emptyDataSourceMessage: "Ingen tekster",
                        editRow: {
                          deleteText:
                            "Er du sikker på at du vil slette teksten?",
                        },
                      },
                    }}
                    editable={{
                      onRowAdd: (newData) =>
                        new Promise((resolve) => {
                          setTimeout(() => {
                            resolve();
                            newData.zoneId = selectedItem.i;
                            setSlideTemplate((prevState) => {
                              const texts = [...prevState.texts];
                              texts.push(newData);
                              return { ...slideTemplate, texts };
                            });
                          }, 600);
                        }),
                      onRowUpdate: (newData, oldData) =>
                        new Promise((resolve) => {
                          setTimeout(() => {
                            resolve();
                            if (oldData) {
                              setSlideTemplate((prevState) => {
                                const texts = [...prevState.texts];
                                texts[texts.indexOf(oldData)] = newData;
                                return { ...prevState, texts };
                              });
                            }
                          }, 600);
                        }),
                      onRowDelete: (oldData) =>
                        new Promise((resolve) => {
                          setTimeout(() => {
                            resolve();
                            setSlideTemplate((prevState) => {
                              const texts = [...prevState.texts];
                              texts.splice(texts.indexOf(oldData), 1);
                              return { ...prevState, texts };
                            });
                          }, 600);
                        }),
                    }}
                  />
                </div>
              </div>
            </TabPanel>
            <TabPanel value={selectedTab} index={2}>
              <div className="images-div">
                <div style={{ width: "100%" }}>
                  <MaterialTable
                    title=""
                    columns={imageTableColumns}
                    icons={tableIcons}
                    data={slideTemplate.images.filter(
                      (x) => x.zoneId === selectedItem.i
                    )}
                    options={{ paging: false, search: false }}
                    localization={{
                      header: { actions: "Handlinger" },
                      body: {
                        emptyDataSourceMessage: "Ingen bilder",
                        editRow: {
                          deleteText:
                            "Er du sikker på at du vil slette bildet?",
                        },
                      },
                    }}
                    editable={{
                      onRowAdd: (newData) =>
                        new Promise((resolve) => {
                          setTimeout(() => {
                            resolve();
                            newData.zoneId = selectedItem.i;
                            setSlideTemplate((prevState) => {
                              const images = [...prevState.images];
                              images.push(newData);
                              return { ...slideTemplate, images };
                            });
                          }, 600);
                        }),
                      onRowUpdate: (newData, oldData) =>
                        new Promise((resolve) => {
                          setTimeout(() => {
                            resolve();
                            if (oldData) {
                              setSlideTemplate((prevState) => {
                                const images = [...prevState.images];
                                images[images.indexOf(oldData)] = newData;
                                return { ...prevState, images };
                              });
                            }
                          }, 600);
                        }),
                      onRowDelete: (oldData) =>
                        new Promise((resolve) => {
                          setTimeout(() => {
                            resolve();
                            setSlideTemplate((prevState) => {
                              const images = [...prevState.images];
                              images.splice(images.indexOf(oldData), 1);
                              return { ...prevState, images };
                            });
                          }, 600);
                        }),
                    }}
                  />
                </div>
              </div>
            </TabPanel>
            <TabPanel value={selectedTab} index={3}>
              <div className="zone-div">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "left",
                  }}
                >
                  <label style={{ paddingRight: "20px" }}>Video:</label>
                  {slideTemplate.media.filter(
                    (z) => z.zoneId === selectedItem.i
                  ).length > 0 ? (
                    <>
                      <div style={{ paddingRight: "20px" }}>
                        {
                          slideTemplate.media.find(
                            (x) => x.zoneId === selectedItem.i
                          ).fileName
                        }
                      </div>
                      <div style={{ paddingRight: "20px" }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                slideTemplate.media.find(
                                  (x) => x.zoneId === selectedItem.i
                                ).loop
                              }
                              style={{ paddingLeft: 0 }}
                              onChange={(e) =>
                                handleLoopChange(e, selectedItem.i)
                              }
                              color="primary"
                            />
                          }
                          label="Loop"
                        />
                      </div>
                      <div>
                        <Button
                          variant="contained"
                          className="sbmDelete"
                          onClick={() =>
                            deleteMediaFileFromZone(
                              slideTemplate.media.find(
                                (x) => x.zoneId === selectedItem.i
                              ).id
                            )
                          }
                        >
                          Fjern video
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <input
                          key={"v_" + selectedZone.zoneId}
                          type="file"
                          onChange={(e) => handleVideoChange(e)}
                        ></input>
                      </div>
                      <div>
                        <Button
                          variant="contained"
                          className="sbmPrimary"
                          disabled={!selectedZone.videoFile}
                          style={{ marginLeft: "30px" }}
                          onClick={() => uploadFile(selectedZone.zoneId)}
                        >
                          Last opp
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </TabPanel>
          </div>
        ) : slideTemplate ? (
          <div className="slide-properties">
            <div className="zone-div">
              <div style={{ display: "flex", flexDirection: "row" }}>
                <TextField
                  id="slide-title"
                  label="Tittel"
                  value={slideTemplate.title}
                  onChange={(e) => handleTitleChange(e)}
                  maxLength="255"
                />
              </div>
              <div style={{}}>
                <FormControlLabel
                  style={{ marginTop: "20px" }}
                  control={
                    <Checkbox
                      checked={slideTemplate.mandatory}
                      style={{ paddingLeft: 0 }}
                      onChange={(e) => handleMandatoryChange(e)}
                      color="primary"
                    />
                  }
                  label="Obligatorisk"
                />
              </div>

              <div style={{ marginTop: "25px" }} className="duration-label">
                Antatt varighet:{" "}
                {slideTemplate.estimatedDuration
                  ? slideTemplate.estimatedDuration
                  : 0}{" "}
                min
              </div>

              <div className="duration-slider">
                <SMBSlider
                  className="slide-duration"
                  value={
                    slideTemplate.estimatedDuration
                      ? slideTemplate.estimatedDuration
                      : 0
                  }
                  getAriaValueText={valuetext}
                  aria-labelledby="sbm-slider"
                  step={1}
                  min={0}
                  max={20}
                  onChange={handleDurationChange}
                  valueLabelDisplay="on"
                  marks={marks}
                  ThumbComponent={SliderThumb}
                />
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default connect(
  (state) => state.templates, // Selects which state properties are merged into the component's props
  TemplateStore.actionCreators // Selects which action creators are merged into the component's props
)(SlideTemplateEdit);

function addZone(slideTemplate, counter) {
  let _zones = _.cloneDeep(slideTemplate.zones);
  _zones.push({
    zoneId: counter.toString(),
    backgroundColor: "rgba(255, 255, 255, 1.0)",
    backgroundImage: null,
    backgroundImageName: null,
  });
  return _zones;
}

function addLayoutItem(slideTemplate, counter) {
  let layout = _.cloneDeep(slideTemplate.layout);
  let maxX = Math.max.apply(
    Math,
    layout.map(function (o) {
      return o.x;
    })
  );
  layout.push({
    i: counter.toString(),
    x: maxX + 6,
    y: 50,
    w: 6,
    h: 50,
    maxH: 100,
  });
  return layout;
}
