import React from "react";

const EndSlidePrint = (props) => {
  const queryString = require("query-string");
  const parsed = queryString.parse(props.location.search);

  let username = parsed.username;
  let email = parsed.email;
  let phone = parsed.phone;
  let title = parsed.title;

  return (
    <>
      <div style={{ backgroundColor: "#01052F", color: "#fff", width: "100vw", height: "100vh", display: "flex", zIndex: 2, position: "absolute" }}>
        <div className='slide-layout-header'></div>
        <div className='end-slide-dark'>
          <div className='end-slide-nice-day'>Vi hjelper deg gjerne!</div>
        </div>
        <div className='end-slide-brighter'>
          <div className='end-slide-contact-2'>{username}</div>
          <div className='end-slide-contact-3'>{title}</div>
          <div className='end-slide-contact-4'>{phone}</div>
          <div className='end-slide-contact-5'>{email}</div>
          <div className='end-slide-SOME'>
            <img alt='logo' src={require("../../img/engasjert-hvit.png")} width='50px' style={{marginRight: '30px'}} height='auto' />
            <img alt='logo' src={require("../../img/facebook-hvit.png")} width='50px' style={{marginRight: '30px'}} height='auto' />
            <img alt='logo' src={require("../../img/instagram-hvit.png")} width='50px' style={{marginRight: '30px'}} height='auto' />
            <img alt='logo' src={require("../../img/linkedin-hvit.png")} width='50px' style={{marginRight: '30px'}} height='auto' />
          </div>
        </div>
        <div className='end-slide-white'>
          <div className='end-slide-logo'>
            <img alt='logo' src={require("../../img/logo_slutt.png")} width='50%' height='auto' />
          </div>
        </div>
      </div>
    </>
  );
};

export default EndSlidePrint;
