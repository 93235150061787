import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as MeetingsStore from "../../store/Meeting/MeetingsStore";
import StartSlide from "./StartSlide";
import api from "../../utils/api";

const Presentation = (props) => {
  const presentationId = props.match.params.id;
  const [meeting, setMeeting] = useState(undefined);
  const [meetingLog, setMeetingLog] = useState(undefined);

  useEffect(() => {
    const url = `${process.env.REACT_APP_API}/meetings/startInfo/${presentationId}`;
    api
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        setMeeting(data);
      });

    const url2 = `${process.env.REACT_APP_API}/meetings/log/${presentationId}`;
    api
      .get(url2)
      .then((response) => response.data)
      .then((data) => {
        setMeetingLog(data);
      });

    // unmount replacement
  }, [presentationId]);

  return (
    <>
      {meeting && meetingLog ? <StartSlide meeting={meeting} /> : <div style={{ backgroundColor: "#01052F", width: "100vw", height: "100vh" }}></div>}
    </>
  );
};

export default connect(
  (state) => ({
    meetings: state.meetings,
    slides: state.slides,
  }),
  Object.assign(MeetingsStore.actionCreators)
)(withRouter(Presentation));
