import React, { useState } from "react";
import * as MeetingStore from "../../../store/Meeting/MeetingsStore";
import Loader from "../../Loader";
import { connect } from "react-redux";
import { SortableComponent } from "../../SortableComponent";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import Button from "@material-ui/core/Button/Button";
import CloseIcon from "@material-ui/icons/Close";
import DescriptionIcon from '@material-ui/icons/Description';
import AddIcon from '@material-ui/icons/Add';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton,
  Tooltip
} from "@material-ui/core";
import api from "../../../utils/api";


const MeetingLayout = (props) => {
  const [hoverId, setHoverId] = useState(undefined);
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [optionalSlides, setOptionalSlides] = useState([]);
  const [optionalMeetings, setOptionalMeetings] = useState([]);

  const handleStateChange = (id) => {
    setHoverId(id);
  };

  const handleRedirect = (slide) => {
    if (slide.isSectionSlide) return;
    const _slide = _.cloneDeep(slide);
    _slide.id = 0;
    props.setCurrentSlide(_slide);
    history.push("/slide/" + slide.id);
  };

  const goBack = () => {
    history.goBack();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const addOptionalSlide = (slideId) => {
    const url = `${process.env.REACT_APP_API}/meetings/AddOptionalSlide`;
    const payload = { meetingId: props.meetings.currentMeeting.id, templateId: slideId }
    api
      .put(url, payload)
      .then((response) => response.data)
      .then(() => {
        setOpen(false);
        setOptionalSlides([]);
        props.requestMeeting(props.meetings.currentMeeting.id);
      })
      .catch(() => {
        alert("Feil oppstod i henting av data");
      });
  }

  const handleAddSlideClick = () => {
    const url = `${process.env.REACT_APP_API}/meetingtemplate/allPublishedTemplateInfo`;
    api
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        setOptionalMeetings(data);
        setOpen(true);
      })
      .catch(() => {
        alert("Feil oppstod i henting av data");
      });
  }

  const handleMeetingClick = (id) => {
    const url = `${process.env.REACT_APP_API}/slidetemplate/meetingSlides?meetingTemplateId=${id}`;
    api
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        setOptionalSlides(data);
        setOpen(true);
      })
      .catch(() => {
        alert("Feil oppstod i henting av data");
      });
  }

  return (
    <>
      <div className='main-meeting-layout'>
        <div className='header'>Agenda</div>
        <div onClick={goBack} style={{ position: "absolute", top: "4rem", right: "10rem", color: "#888888", cursor: "pointer" }}>
          <CloseIcon style={{ fontSize: "2.5rem" }}></CloseIcon>
        </div>
        {props.loadingMeeting ? (
          <Loader></Loader>
        ) : (
          <>
            <div>
              <Button className='sbmPrimary wide' style={{ marginLeft: "10px" }} onClick={() => handleAddSlideClick()}>
                Legg til slide
              </Button>
            </div>
            <div>
              <SortableComponent
                updateState={handleStateChange}
                state={{ hoverId: hoverId }}
                isMeetingTemplate={false}
                meetingId={props.meetings.currentMeeting ? props.meetings.currentMeeting.id : 0}
                sections={props.meetings.currentMeeting ? props.meetings.currentMeeting.sections : []}
                redirect={handleRedirect}
                onSortEnd={props.reorderMeetingSlidesAndSave}
                onSortStart={props.markAffectedMeetingSlides}></SortableComponent>
            </div>
          </>
        )}
      </div>
      {open ? (
        <div>
          <Dialog open={open} onClose={handleClose} aria-labelledby='draggable-dialog-title'>
            <DialogTitle style={{ width: "450px" }} id='draggable-dialog-title'>
              Velg møtemal
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel id='mt-selector-label'>Møtemal</InputLabel>
                  <Select
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                    }}
                    defaultValue=""
                    id='mt-selector'
                    onChange={(e) => handleMeetingClick(e.target.value)}>
                    {optionalMeetings.map((os) => {
                      return (
                        <MenuItem key={`mt_${os.id}`} value={os.id}>
                          {os.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </DialogContentText>

              <List dense>
                {optionalSlides?.map((slide, index) => (
                  <ListItem key={index} button>
                    <ListItemIcon>
                      <Tooltip title="Slide">
                        <DescriptionIcon />
                      </Tooltip>
                    </ListItemIcon>
                    <ListItemText primary={slide.title} />
                    <ListItemSecondaryAction>
                      <Tooltip title="Legg til slide">
                        <IconButton edge="end" onClick={() => addOptionalSlide(slide.id)}>
                          <AddIcon />
                        </IconButton>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </DialogContent>

            <DialogActions>
              <Button variant='contained' autoFocus onClick={handleClose} className='sbmSecondary'>
                Avbryt
              </Button>
            </DialogActions>

          </Dialog>
        </div>
      ) : null}
    </>
  );
};

export default connect(
  (state) => ({
    meetings: state.meetings,
  }),
  Object.assign(MeetingStore.actionCreators)
)(MeetingLayout);
