import api from "../utils/api";

// action types
export const SET_USER_INFO = "SET_USER_INFO";
export const REQUEST_USER_INFO = "REQUEST_USER_INFO";
export const SET_SIGNALR_CONNECTION_ID = "SET_SIGNALR_CONNECTION_ID";

// action creators
export const actionCreators = {
  setUserInfo: () => (dispatch) => {
    const url = process.env.REACT_APP_API + "/Auth/userinfo";
    api
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        localStorage.setItem("username", data.username);
        localStorage.setItem("isAdmin", data.isAdministrator ? data.isAdministrator.toString() : "false");
        dispatch({ type: SET_USER_INFO, userInfo: data });
      });
    dispatch({ type: REQUEST_USER_INFO });
  },
  setSignalRConnectionId: (id) => (dispatch) => {
    dispatch({ type: SET_SIGNALR_CONNECTION_ID, id: id });
  }
};

// reducer
const initialState = {
  username: null,
  isAdministrator: false,
  loadingUserInfo: false,
  signalRConnectionId: null
};

export const reducer = (state, action) => {
  if (state === undefined) {
    return { ...initialState };
  }

  switch (action.type) {
    case SET_USER_INFO: {
      return {
        ...state,
        username: action.userInfo.username,
        isAdministrator: action.userInfo.isAdministrator,
        loadingUserInfo: false
      };
    }
    case REQUEST_USER_INFO: {
      return {
        ...state,
        username: null,
        isAdministrator: false,
        loadingUserInfo: true
      };
    }

    case SET_SIGNALR_CONNECTION_ID: {
      return {
        ...state,
        signalRConnectionId: action.id
      };
    }
    default:
      return { ...state };
  }
};
