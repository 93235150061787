import * as React from "react";

export default (props) => {
  return (
    <>
      <div className='background' />
      <div className='full-content'>{props.children}</div>
    </>
  );
};
