import React, { useEffect, useState } from "react";
import JsxParser from "react-jsx-parser";
import {ParagraphComponent2 as ParagraphComponent} from "../ParagraphComponent2";
import CloseIcon from "@material-ui/icons/Close";
import api from "../../utils/api";
import { useHistory } from "react-router-dom";

const SlideView = (props) => {
  const [slide, setSlide] = useState(undefined);
  const history = useHistory();

  useEffect(() => {
    const url = `${process.env.REACT_APP_API}/slides/${props.match.params.id}`;
    api
      .get(url)
      .then((response) => response.data)
      .then((data) => {
        setSlide(data);
      });
  }, [props.match.params.id]);

  const goBack = () => {
    history.goBack();
  };

  return !slide ? (
    <></>
  ) : (
    <div style={{ width: "100vw", height: "100vh", display: "content", backgroundColor: "#000000" }}>
      <div onClick={goBack} style={{ position: "absolute", top: "1rem", right: "2rem", color: "#888888", cursor: "pointer", zIndex: 10 }}>
          <CloseIcon style={{ fontSize: "2.5rem" }}></CloseIcon>
        </div>
      <JsxParser showWarnings={true} jsx={slide.layout} components={{ ParagraphComponent }} />
    </div>
  );
};

export default SlideView;
