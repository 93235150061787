import api from "../utils/api";
import arrayMove from "array-move";
import _ from "lodash";

// action types
export const REQUEST_MEETING_TEMPLATES = "REQUEST_MEETING_TEMPLATES";
export const RECEIVE_MEETING_TEMPLATES = "RECEIVE_MEETING_TEMPLATES";

export const RECEIVE_PUBLISHED_MEETING_TEMPLATES = "RECEIVE_PUBLISHED_MEETING_TEMPLATES";
export const REQUEST_PUBLISHED_MEETING_TEMPLATES = "REQUEST_PUBLISHED_MEETING_TEMPLATES";

export const REQUEST_SLIDE_TEMPLATE = "REQUEST_SLIDE_TEMPLATE";
export const RECEIVE_SLIDE_TEMPLATE = "RECEIVE_SLIDE_TEMPLATE";

export const REQUEST_MEETING_TEMPLATE = "REQUEST_MEETING_TEMPLATE";
export const RECEIVE_MEETING_TEMPLATE = "RECEIVE_MEETING_TEMPLATE";
export const RECEIVE_MEETING_TEMPLATE_UPDATED = "RECEIVE_MEETING_TEMPLATE_UPDATED";

export const SAVE_MEETING_TEMPLATE = "SAVE_MEETING_TEMPLATE";
export const RECEIVE_SAVED_MEETING_TEMPLATE = "RECEIVE_SAVED_MEETING_TEMPLATE";

export const RECEIVE_UPLOADED_MEDIA_FILE = "RECEIVE_UPLOADED_MEDIA_FILE";

export const SET_SLIDE_TEMPLATE_LAYOUT = "SET_SLIDE_TEMPLATE_LAYOUT";

export const SAVE_SLIDE_TEMPLATE = "SAVE_SLIDE_TEMPLATE";


export const REQUEST_MARITAL_STATUSES = "REQUEST_MARITAL_STATUSES";
export const RECEIVE_MARITAL_STATUSES = "RECEIVE_MARITAL_STATUSES";

export const REQUEST_MEETING_TYPES = "REQUEST_MEETING_TYPES";
export const RECEIVE_MEETING_TYPES = "RECEIVE_MEETING_TYPES";

export const REQUEST_TOPICS = "REQUEST_TOPICS";
export const RECEIVE_TOPICS = "RECEIVE_TOPICS";

export const REQUEST_SLIDE_TYPES = "REQUEST_SLIDE_TYPES";
export const RECEIVE_SLIDE_TYPES = "RECEIVE_SLIDE_TYPES";

export const CLEAR_MEETING_TEMPLATE = "CLEAR_MEETING_TEMPLATE";

export const SET_SLIDE_TEMPLATE = "SET_SLIDE_TEMPLATE";
export const CLEAR_SLIDE_TEMPLATE = "CLEAR_SLIDE_TEMPLATE";

export const SET_SLIDE_TEMPLATE_DURATION = "SET_SLIDE_TEMPLATE_DURATION";

export const SET_MEETING_TEMPLATE = "SET_MEETING_TEMPLATE";

export const MARK_AFFECTED_SLIDE_TEMPLATES = "MARK_AFFECTED_SLIDE_TEMPLATES";
export const REORDER_SLIDE_TEMPLATES = "REORDER_SLIDE_TEMPLATES";
export const REMOVE_SLIDE_OR_SECTION_TEMPLATE = "REMOVE_SLIDE_OR_SECTION_TEMPLATE";
export const ADD_NEW_SECTION_TEMPLATE = "ADD_NEW_SECTION_TEMPLATE";
export const ADD_NEW_SLIDE_TO_TEMPLATE_SECTION = "ADD_NEW_SLIDE_TO_TEMPLATE_SECTION";

export const SET_SHOW_NEW_MEETING_TEMPLATE_FORM = "SET_SHOW_NEW_MEETING_TEMPLATE_FORM";
export const SET_SHOW_EDIT_MEETING_TEMPLATE_FORM = "SET_SHOW_EDIT_MEETING_TEMPLATE_FORM";
export const TOGGLE_PUBLISH_MEETING_TEMPLATE = "TOGGLE_PUBLISH_MEETING_TEMPLATE";

export const UPLOAD_SLIDETEMPLATE_FILE = "UPLOAD_SLIDETEMPLATE_FILE";
export const DELETE_MEDIA_FROM_TEMPLATE = "DELETE_MEDIA_FROM_TEMPLATE";

// action creators
export const actionCreators = {
  requestMeetingTemplates: () => (dispatch, getState) => {
    const appState = getState();

    if (appState.templates && !appState.templates.loadingMeetingTemplates) {
      const apiUrl = process.env.REACT_APP_API + "/meetingTemplate/allTemplates";
      api
        .get(apiUrl)
        .then((response) => response.data)
        .then((data) => {
          dispatch({
            type: RECEIVE_MEETING_TEMPLATES,
            meetingTemplates: data,
          });
        });

      dispatch({ type: REQUEST_MEETING_TEMPLATES });
    }
  },
  requestPublishedMeetingTemplates: () => (dispatch, getState) => {
    const appState = getState();

    if (appState.templates && !appState.templates.loadingPublishedMeetingTemplates) {
      const apiUrl = process.env.REACT_APP_API + "/meetingTemplate/allPublishedTemplates";
      api
        .get(apiUrl)
        .then((response) => response.data)
        .then((data) => {
          dispatch({
            type: RECEIVE_PUBLISHED_MEETING_TEMPLATES,
            publishedTemplates: data,
          });
        });

      dispatch({ type: REQUEST_PUBLISHED_MEETING_TEMPLATES });
    }
  },
  copyTemplate: (id, name) => (dispatch) => {
    const apiUrl = process.env.REACT_APP_API + "/MeetingTemplate/copy/" + id + "/" + name;

    api
      .get(apiUrl)
      .then((response) => response.data)
      .then((data) => {
        dispatch({
          type: RECEIVE_MEETING_TEMPLATES,
          meetingTemplates: data,
        });
      });
  },
  deleteMeetingTemplate: (id) => (dispatch) => {
    const apiUrl = process.env.REACT_APP_API + "/MeetingTemplate/" + id;

    api
      .delete(apiUrl)
      .then((response) => response.data)
      .then((data) => {
        dispatch({
          type: RECEIVE_MEETING_TEMPLATES,
          meetingTemplates: data,
        });
        dispatch({ type: CLEAR_MEETING_TEMPLATE });
      });
  },
  requestTemplate: (id) => (dispatch, getState) => {
    const appState = getState();
    if (appState.templates && !appState.templates.loadingMeetingTemplate) {
      const apiUrl = process.env.REACT_APP_API + "/meetingTemplate/" + id;
      api
        .get(apiUrl)
        .then((response) => response.data)
        .then((data) => {
          dispatch({
            type: RECEIVE_MEETING_TEMPLATE,
            meetingTemplate: data,
          });
        });

      dispatch({ type: REQUEST_MEETING_TEMPLATE, id: id });
    }
  },
  changeTemplateName: (templateId, templateName) => (dispatch) => {
    let apiUrl = process.env.REACT_APP_API + "/meetingTemplate/changeName/" + templateId;

    api
      .put(apiUrl, '"' + templateName + '"', { headers: { "Content-Type": "application/json" } })
      .then((response) => response.data)
      .then((data) => {
        dispatch({ type: RECEIVE_SAVED_MEETING_TEMPLATE, meetingTemplate: data });
      });

    dispatch({ type: SAVE_MEETING_TEMPLATE, meetingTemplate: {} });
  },
  saveMeetingTemplate: (meetingTemplate) => (dispatch, getState) => {
    let apiUrl = process.env.REACT_APP_API + "/meetingTemplate/updateMeetingTemplate";

    if (!meetingTemplate.id) {
      apiUrl = process.env.REACT_APP_API + "/meetingTemplate/createMeetingTemplate";
    }

    meetingTemplate.sections.forEach((section) => {
      section.slides.forEach((slide) => {
        if (slide.layout) slide.layout = JSON.stringify(slide.layout);
        else {
          slide.layout = [
            { i: "1", x: 0, y: 0, w: 6, h: 50, maxH: 100 },
            { i: "2", x: 6, y: 0, w: 6, h: 50, maxH: 100 },
          ];
          slide.layout = JSON.stringify(slide.layout);
        }
      });
    });

    if (meetingTemplate.maritalStatusId === "") meetingTemplate.maritalStatusId = null;
    if (meetingTemplate.aboveAge === 0) meetingTemplate.aboveAge = null;
    if (meetingTemplate.belowAge === 0) meetingTemplate.belowAge = null;
    if (meetingTemplate.gender === "") meetingTemplate.gender = null;

    api
      .post(apiUrl, meetingTemplate)
      .then((response) => response.data)
      .then((data) => {
        dispatch({ type: RECEIVE_SAVED_MEETING_TEMPLATE, meetingTemplate: data });
      });

    dispatch({ type: SAVE_MEETING_TEMPLATE, meetingTemplate: meetingTemplate });
  },
  saveSlideTemplate: (template, expandedNodes) => (dispatch, getState) => {
    const apiUrl = process.env.REACT_APP_API + "/slideTemplate/saveslidetemplate";
    
    template.layout = JSON.stringify(template.layout);
    api
      .post(apiUrl, template)
      .then((response) => response.data)
      .then((data) => {
        dispatch({ type: RECEIVE_SLIDE_TEMPLATE, template: data, expandedNodes });
      });
    dispatch({ type: SAVE_SLIDE_TEMPLATE });
  },
  copySlideTemplate: (meetingTemplateId, slideTemplateId) => (dispatch) => {
    const apiUrl = process.env.REACT_APP_API + "/SlideTemplate/copyToMeeting/" + slideTemplateId + "/" + meetingTemplateId;
    api
      .get(apiUrl)
      .then((response) => response.data)
      .then((data) => {
        dispatch({ type: RECEIVE_MEETING_TEMPLATE_UPDATED, meetingTemplate: data });
      });
    dispatch({ type: SAVE_SLIDE_TEMPLATE });
  },

  requestMaritalStatuses: () => (dispatch, getState) => {
    const appState = getState();
    const apiUrl = process.env.REACT_APP_API + "/meetingTemplate/maritalStatuses";
    if (appState.templates && !appState.templates.loadingMaritalStatuses) {
      api
        .get(apiUrl)
        .then((response) => response.data)
        .then((data) => {
          dispatch({
            type: RECEIVE_MARITAL_STATUSES,
            maritalStatuses: data,
          });
        });

      dispatch({ type: REQUEST_MARITAL_STATUSES });
    }
  },
  requestTopics: () => (dispatch, getState) => {
    const appState = getState();

    if (appState.templates && !appState.templates.loadingTopics) {
      const apiUrl = `${process.env.REACT_APP_API}/Topic/allTopics`;
      api
        .get(apiUrl)
        .then((response) => response.data)
        .then((data) => {
          dispatch({
            type: RECEIVE_TOPICS,
            topics: data,
          });
        });
      dispatch({ type: REQUEST_TOPICS });
    }
  },
  clearMeetingTemplate: () => (dispatch) => {
    dispatch({ type: CLEAR_MEETING_TEMPLATE });
  },
  setSlideTemplate: (slideTemplate) => (dispatch) => {
    dispatch({ type: SET_SLIDE_TEMPLATE, slideTemplate });
  },
  clearSlideTemplate: () => (dispatch) => {
    dispatch({ type: CLEAR_SLIDE_TEMPLATE });
  },
  setSlideTemplateDuration: (duration) => (dispatch, getState) => {
    const state = getState();
    if (state.templates.slideTemplate) {
      dispatch({ type: SET_SLIDE_TEMPLATE_DURATION, duration: duration });
    }
  },
  setMeetingTemplate: (meetingTemplate, callback) => (dispatch) => {
    dispatch({ type: SET_MEETING_TEMPLATE, meetingTemplate });
    if (callback) callback(meetingTemplate.name);
  },
  markAffectedSlideTemplates: (sortStart) => (dispatch) => {
    dispatch({ type: MARK_AFFECTED_SLIDE_TEMPLATES, index: sortStart.index });
  },
  reorderSlideTemplates: (sortEnd) => (dispatch, getState) => {
    const state = getState();

    let tmpMeetingTemplate = _.cloneDeep(state.templates.meetingTemplate);

    let slides = [];

    tmpMeetingTemplate.sections.forEach((section) => {
      if (!section.slides.some((s) => s.isSectionSlide)) {
        let secSlide = {};
        secSlide.header = section.topic.name;
        secSlide.meetingTemplateSectionId = section.id;
        secSlide.isSectionSlide = true;
        slides.push(secSlide);
      }
      slides = slides.concat(section.slides);
    });

    let c = 0;
    slides.forEach((s) => {
      s.dragId = c;
      s.isLastSlide = false;
      s.mark = false;
      c++;
    });

    let slideToMove = slides.find((s) => s.dragId === sortEnd.oldIndex);

    if (slideToMove.isSectionSlide) {
      // MOVE ALL SLIDES IN THE SECTION
      let slidesToMove = slides
        .filter((s) => s.meetingTemplateSectionId === slideToMove.meetingTemplateSectionId)
        .sort(function (a, b) {
          return a.dragId - b.dragId;
        });

      let newIx = sortEnd.newIndex;
      let oldIx = sortEnd.oldIndex;
      if (newIx > oldIx) {
        slidesToMove.forEach((s) => {
          slides = arrayMove(slides, oldIx, newIx);
        });
      } else {
        slidesToMove.forEach((s) => {
          if (s.id) {
            slides = arrayMove(slides, slides.indexOf(slides.find((x) => x.id === s.id)), newIx);
          } else {
            slides = arrayMove(slides, oldIx, newIx);
          }
          newIx++;
        });
      }

      let posCounter = 1;
      let dragCounter = 0;
      slides.forEach((s) => {
        if (s.isSectionSlide) {
          tmpMeetingTemplate.sections.find((x) => x.id === s.meetingTemplateSectionId).positionInMeetingTemplate = posCounter;
          posCounter++;
        }
        s.dragId = dragCounter;
        dragCounter++;
      });
    } else {
      // MOVE SINGLE SLIDE
      if (sortEnd.newIndex === 0)
        return {
          ...state,
          meetingTemplate: tmpMeetingTemplate,
        };

      let prevSlide = {};
      if (sortEnd.newIndex < slides.length - 1) {
        if (sortEnd.newIndex > sortEnd.oldIndex + 1) {
          prevSlide = slides.find((s) => s.dragId === sortEnd.newIndex);
          slideToMove.meetingTemplateSectionId = prevSlide.meetingTemplateSectionId;
        } else if (sortEnd.newIndex < sortEnd.oldIndex) {
          prevSlide = slides.find((s) => s.dragId === sortEnd.newIndex - 1);
          slideToMove.meetingTemplateSectionId = prevSlide.meetingTemplateSectionId;
        } else {
          prevSlide = slides.find((s) => s.dragId === sortEnd.newIndex);
          slideToMove.meetingTemplateSectionId = prevSlide.meetingTemplateSectionId;
        }
      } else {
        prevSlide = slides.find((s) => s.dragId === sortEnd.newIndex);
        if (prevSlide.isSectionSlide) {
          slideToMove.meetingTemplateSectionId = prevSlide.id;
        } else {
          slideToMove.meetingTemplateSectionId = prevSlide.meetingTemplateSectionId;
        }
      }

      slides = arrayMove(slides, sortEnd.oldIndex, sortEnd.newIndex);

      let slidesInSection = slides.filter((sl) => sl.meetingTemplateSectionId === slideToMove.meetingTemplateSectionId && sl.isSectionSlide !== true);

      let counter = 1;
      slidesInSection.forEach((s) => {
        s.positionInSection = counter;
        counter++;
      });

      tmpMeetingTemplate.sections.forEach((s) => {
        let slidesInSection = slides.filter((sl) => sl.meetingTemplateSectionId === s.id);
        s.slides = slidesInSection;
        s.mark = false;
      });
    }

    let updatedSections = [];

    let sectionPos = 1;
    let tmpSection = undefined;

    slides.forEach((slide) => {
      if (!slide.id) {
        if (tmpSection) {
          updatedSections.push(tmpSection);
        }

        tmpSection = {
          sectionId: slide.meetingTemplateSectionId,
          position: sectionPos,
          slides: [],
        };
        sectionPos++;
      } else {
        tmpSection.slides.push({
          slideId: slide.id,
          positionInSection: slide.positionInSection,
        });
      }

      if (slides[slides.length - 1] === slide) {
        updatedSections.push(tmpSection);
      }
    });

    const apiUrl = process.env.REACT_APP_API + "/meetingTemplate/reorderMeeting";
    const payload = { templateId: tmpMeetingTemplate.id, sections: updatedSections };

    api.post(apiUrl, payload).then((data) => {
      dispatch({ type: RECEIVE_SAVED_MEETING_TEMPLATE, meetingTemplate: data.data });
    });

    dispatch({ type: REORDER_SLIDE_TEMPLATES, oldIndex: sortEnd.oldIndex, newIndex: sortEnd.newIndex });
  },
  removeSlideOrSectionTemplate: (dragId) => (dispatch, getState) => {
    const appState = getState();
    const apiUrl = process.env.REACT_APP_API + "/meetingTemplate/";
    let slideId = 0;
    let sectionId = 0;
    let _meetingTemplate = appState.templates.meetingTemplate;
    let _slides = [];

    _meetingTemplate.sections.forEach((section) => {
      if (!section.slides.some((s) => s.isSectionSlide)) {
        let secSlide = {};
        secSlide.meetingTemplateSectionId = section.id;
        secSlide.isSectionSlide = true;
        _slides.push(secSlide);
      }
      _slides = _slides.concat(section.slides);
    });

    let _counter = 0;
    _slides.forEach((s) => {
      s.dragId = _counter;
      _counter++;
    });

    let clickedSlide = _slides.find((x) => x.dragId === dragId);

    if (clickedSlide.isSectionSlide) {
      sectionId = clickedSlide.meetingTemplateSectionId;
    } else {
      slideId = clickedSlide.id;
    }

    if (sectionId !== 0) {
      api
        .delete(apiUrl + "section/" + sectionId)
        .then((response) => response.data)
        .then((data) => {
          dispatch({
            type: RECEIVE_MEETING_TEMPLATE,
            meetingTemplate: data,
          });
        });
    } else {
      api
        .delete(apiUrl + "slide/" + slideId)
        .then((response) => response.data)
        .then((data) => {
          dispatch({
            type: RECEIVE_MEETING_TEMPLATE,
            meetingTemplate: data,
          });
        });
    }
  },
  addNewTopic: (topic) => (dispatch) => {
    const apiUrl = process.env.REACT_APP_API + "/topic/newTopic/" + topic;
    api.get(apiUrl).then((data) => {
      dispatch({ type: "RECEIVE_NEW_TOPIC", topic: data.data });
    });
    dispatch({ type: "CREATE_NEW_TOPIC" });
  },
  addNewSectionTemplate: (topic) => (dispatch, getState) => {
    const appState = getState();
    const apiUrl = process.env.REACT_APP_API + "/meetingTemplate/addSection";
    const templateId = appState.templates.meetingTemplate.id;
    const payload = { templateId: templateId, topic: topic };

    api.post(apiUrl, payload).then((data) => {
      dispatch({ type: RECEIVE_SAVED_MEETING_TEMPLATE, meetingTemplate: data.data });
    });

    dispatch({ type: ADD_NEW_SECTION_TEMPLATE, topic });
  },
  addNewSlideToTemplateSection: (sectionId, slide) => (dispatch) => {
    dispatch({ type: ADD_NEW_SLIDE_TO_TEMPLATE_SECTION, sectionId, slide });
  },
  setShowNewMeetingTemplateForm: (show) => (dispatch) => {
    dispatch({ type: SET_SHOW_NEW_MEETING_TEMPLATE_FORM, show });
  },
  setShowEditMeetingTemplateForm: (show) => (dispatch) => {
    dispatch({ type: SET_SHOW_EDIT_MEETING_TEMPLATE_FORM, show });
  },
  togglePublishMeetingTemplate: (id) => (dispatch) => {
    const apiUrl = process.env.REACT_APP_API + "/meetingTemplate/togglePublish/" + id.toString();
    api
      .get(apiUrl)
      .then((response) => response.data)
      .then((data) => {
        dispatch({
          type: RECEIVE_SAVED_MEETING_TEMPLATE,
          meetingTemplate: data,
        });
      });

    dispatch({ type: TOGGLE_PUBLISH_MEETING_TEMPLATE });
  },
  uploadSlideTemplateFile: (input) => (dispatch) => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const apiUrl = process.env.REACT_APP_API + "/SlideTemplate/" + input.slideTemplateId + "/" + input.itemId;
    api.post(apiUrl, input.formData, config).then((data) => {
      dispatch({ type: RECEIVE_UPLOADED_MEDIA_FILE, media: data.data });
    });
  },
  deleteMediaFile: (slideId, id) => (dispatch) => {
    const apiUrl = process.env.REACT_APP_API + "/SlideTemplate/" + slideId + "/DeleteMedia/" + id;
    api
      .get(apiUrl)
      .then((response) => response.data)
      .then((data) => {
        dispatch({
          type: RECEIVE_SLIDE_TEMPLATE,
          template: data,
        });
      });

    dispatch({ type: DELETE_MEDIA_FROM_TEMPLATE });
  },
  requestSlideTemplate: (id) => (dispatch) => {
    const apiUrl = process.env.REACT_APP_API + "/SlideTemplate/" + id;
    api
      .get(apiUrl)
      .then((response) => response.data)
      .then((data) => {
        dispatch({
          type: RECEIVE_SLIDE_TEMPLATE,
          template: data,
        });
      });
    dispatch({ type: REQUEST_SLIDE_TEMPLATE });
  },
  setSlideTemplateLayout: (layout) => (dispatch) => {
    dispatch({ type: SET_SLIDE_TEMPLATE_LAYOUT, layout });
  },
};

// initial state
export const templateState = {
  meetingTemplates: [],
  publishedMeetingTemplates: [],
  meetingTemplate: undefined,
  slideTemplate: undefined,
  chartTypes: [],
  images: [],
  videos: [],
  maritalStatuses: [],
  meetingTypes: [],
  topics: [],
  slideTypes: [],
  loadingMeetingTemplates: false,
  loadingPublishedMeetingTemplates: false,
  loadingMeetingTemplate: false,
  loadingChartTypes: false,
  loadingImages: false,
  loadingVideos: false,
  loadingMaritalStatuses: false,
  loadingMeetingTypes: false,
  loadingTopics: false,
  loadingSlideTypes: false,
};

// reducer
export const reducer = (state, action) => {
  if (state === undefined) {
    return templateState;
  }

  switch (action.type) {
    case REQUEST_MEETING_TEMPLATES: {
      return {
        ...state,
        loadingMeetingTemplates: true,
        meetingTemplates: [],
      };
    }

    case RECEIVE_MEETING_TEMPLATES: {
      return {
        ...state,
        loadingMeetingTemplates: false,
        meetingTemplates: action.meetingTemplates,
      };
    }

    case RECEIVE_PUBLISHED_MEETING_TEMPLATES: {
      return {
        ...state,
        loadingPublishedMeetingTemplates: false,
        publishedMeetingTemplates: action.publishedTemplates,
      };
    }

    case REQUEST_PUBLISHED_MEETING_TEMPLATES: {
      return {
        ...state,
        loadingPublishedMeetingTemplates: true,
        publishedMeetingTemplates: [],
      };
    }

    case REQUEST_MEETING_TEMPLATE: {
      return {
        ...state,
        loadingMeetingTemplate: true,
        meetingTemplate: undefined,
      };
    }

    case RECEIVE_MEETING_TEMPLATE: {
      action.meetingTemplate.sections.map((s) => {
        return s.slides.map((sl) => {
          if (!s.duration) s.duration = 0;
          return (s.duration += sl.estimatedDuration || 0);
        });
      });

      return {
        ...state,
        loadingMeetingTemplate: false,
        meetingTemplate: action.meetingTemplate,
        slideTemplate: undefined,
      };
    }

    case RECEIVE_MEETING_TEMPLATE_UPDATED: {
      let meetingTemplates = _.cloneDeep(state.meetingTemplates);

      if (meetingTemplates.length > 0) {
        meetingTemplates = meetingTemplates.map((x) => (x.id === action.meetingTemplate.id ? action.meetingTemplate : x));
        if (!meetingTemplates.find((x) => x.id === action.meetingTemplate.id)) meetingTemplates.push(action.meetingTemplate);
      } else {
        meetingTemplates.push(action.meetingTemplate);
      }

      action.meetingTemplate.sections.map((s) => {
        return s.slides.map((sl) => {
          if (!s.duration) s.duration = 0;
          return (s.duration += sl.estimatedDuration || 0);
        });
      });

      let _meetingTemplate = _.cloneDeep(state.meetingTemplate);
      if (_meetingTemplate.id === action.meetingTemplate.id) {
        _meetingTemplate = action.meetingTemplate;
      }

      return {
        ...state,
        loadingMeetingTemplate: false,
        meetingTemplate: _meetingTemplate,
        meetingTemplates: meetingTemplates,
        showMeetingTemplateForm: false,
      };
    }

    case SAVE_MEETING_TEMPLATE: {
      return {
        ...state,
        loadingMeetingTemplate: true,
      };
    }

    case RECEIVE_SAVED_MEETING_TEMPLATE: {
      let meetingTemplates = _.cloneDeep(state.meetingTemplates);

      if (meetingTemplates.length > 0) {
        meetingTemplates = meetingTemplates.map((x) => (x.id === action.meetingTemplate.id ? action.meetingTemplate : x));
        if (!meetingTemplates.find((x) => x.id === action.meetingTemplate.id)) meetingTemplates.push(action.meetingTemplate);
      } else {
        meetingTemplates.push(action.meetingTemplate);
      }

      action.meetingTemplate.sections.map((s) => {
        return s.slides.map((sl) => {
          if (!s.duration) s.duration = 0;
          return (s.duration += sl.estimatedDuration || 0);
        });
      });

      return {
        ...state,
        loadingMeetingTemplate: false,
        meetingTemplate: action.meetingTemplate,
        meetingTemplates: meetingTemplates,
        showMeetingTemplateForm: false,
      };
    }

    case SAVE_SLIDE_TEMPLATE: {
      return {
        ...state,
        loadingMeetingTemplate: true,
      };
    }

    case REQUEST_MARITAL_STATUSES: {
      return {
        ...state,
        loadingMaritalStatuses: true,
      };
    }

    case RECEIVE_MARITAL_STATUSES: {
      return {
        ...state,
        maritalStatuses: action.maritalStatuses,
        loadingMaritalStatuses: false,
      };
    }

    case REQUEST_MEETING_TYPES: {
      return {
        ...state,
        loadingMeetingTypes: true,
      };
    }

    case RECEIVE_MEETING_TYPES: {
      return {
        ...state,
        meetingTypes: action.meetingTypes,
        loadingMeetingTypes: false,
      };
    }

    case REQUEST_TOPICS: {
      return {
        ...state,
        loadingTopics: true,
      };
    }

    case "RECEIVE_NEW_TOPIC": {
      return {
        ...state,
        topics: state.topics.concat(action.topic)
      }
    }

    case RECEIVE_TOPICS: {
      return {
        ...state,
        topics: action.topics,
        loadingTopics: false,
      };
    }

    case REQUEST_SLIDE_TYPES: {
      return {
        ...state,
        loadingSlideTypes: true,
      };
    }

    case RECEIVE_SLIDE_TYPES: {
      return {
        ...state,
        slideTypes: action.slideTypes,
        loadingSlideTypes: false,
      };
    }

    case CLEAR_MEETING_TEMPLATE: {
      return {
        ...state,
        meetingTemplate: undefined,
      };
    }

    case SET_SLIDE_TEMPLATE: {
      return {
        ...state,
        slideTemplate: action.slideTemplate,
      };
    }


    case CLEAR_SLIDE_TEMPLATE: {
      return {
        ...state,
        slideTemplate: undefined,
      };
    }


    case SET_SLIDE_TEMPLATE_DURATION: {
      return {
        ...state,
        slideTemplate: Object.assign(
          { ...state.slideTemplate },
          {
            estimatedDuration: action.duration,
          }
        ),
      };
    }

    case SET_SLIDE_TEMPLATE_LAYOUT: {
      return {
        ...state,
        slideTemplate: Object.assign(
          { ...state.slideTemplate },
          {
            layout: action.layout,
          }
        ),
      };
    }

    case SET_MEETING_TEMPLATE: {
      return {
        ...state,
        meetingTemplate: action.meetingTemplate,
        slideTemplate: undefined,
        loadingMeetingTemplate: false,
        loadingMeetingTemplates: false,
      };
    }

    case MARK_AFFECTED_SLIDE_TEMPLATES: {
      let _meetingTemplate = JSON.parse(JSON.stringify(state.meetingTemplate));
      let _slides = [];

      _meetingTemplate.sections.forEach((section) => {
        if (!section.slides.some((s) => s.isSectionSlide)) {
          let secSlide = {};
          secSlide.header = section.topic.name;
          secSlide.meetingTemplateSectionId = section.id;
          secSlide.isSectionSlide = true;
          _slides.push(secSlide);
        }
        _slides = _slides.concat(section.slides);
      });

      let co = 0;

      _slides.forEach((s) => {
        s.dragId = co;
        co++;
      });

      let affectedSlide = _slides.find((x) => x.dragId === action.index);
      let affectedSection = null;

      if (affectedSlide && affectedSlide.isSectionSlide) {
        affectedSection = _meetingTemplate.sections.find((x) => x.id === affectedSlide.meetingTemplateSectionId);
        affectedSection.mark = true;

        affectedSection.slides.forEach((s) => {
          s.mark = true;
        });
      }
      return {
        ...state,
        meetingTemplate: _meetingTemplate,
      };
    }

    case REORDER_SLIDE_TEMPLATES: {
      return {
        ...state,
      };
    }

    case REMOVE_SLIDE_OR_SECTION_TEMPLATE: {
      let _meetingTemplate = JSON.parse(JSON.stringify(state.meetingTemplate));
      let _slides = [];

      _meetingTemplate.sections.forEach((section) => {
        if (!section.slides.some((s) => s.isSectionSlide)) {
          let secSlide = {};
          secSlide.header = section.topic.name;
          secSlide.meetingTemplateSectionId = section.id;
          secSlide.isSectionSlide = true;
          _slides.push(secSlide);
        }
        _slides = _slides.concat(section.slides);
      });

      let _counter = 0;
      _slides.forEach((s) => {
        s.dragId = _counter;
        _counter++;
      });

      let clickedSlide = _slides.find((x) => x.dragId === action.dragId);

      if (clickedSlide.isSectionSlide) {
        _slides = _slides.filter((s) => s.meetingTemplateSectionId !== clickedSlide.meetingTemplateSectionId);
        _meetingTemplate.sections = _meetingTemplate.sections.filter((x) => x.id !== clickedSlide.meetingTemplateSectionId);
      }

      _slides = _slides.filter((s) => s.dragId !== action.dragId);

      _counter = 0;
      _slides.forEach((s) => {
        s.dragId = _counter;
        _counter++;
      });

      _meetingTemplate.sections.forEach((s) => {
        let slidesInSection = _slides.filter((sl) => sl.meetingTemplateSectionId === s.id);
        s.slides = slidesInSection;
      });

      return {
        ...state,
        meetingTemplate: _meetingTemplate,
      };
    }

    case ADD_NEW_SECTION_TEMPLATE: {
      return {
        ...state,
        loadingMeetingTemplate: true,
      };
    }

    case ADD_NEW_SLIDE_TO_TEMPLATE_SECTION: {
      let template = _.cloneDeep(state.meetingTemplate);
      template.sections.find((x) => x.id === action.sectionId).slides.push(action.slide);

      return {
        ...state,
        meetingTemplate: template,
      };
    }

    case SET_SHOW_NEW_MEETING_TEMPLATE_FORM: {
      return {
        ...state,
        meetingTemplate: {},
        showMeetingTemplateForm: action.show,
      };
    }

    case RECEIVE_UPLOADED_MEDIA_FILE: {
      let template = _.cloneDeep(state.slideTemplate);
      template.media.push(action.media);

      return {
        ...state,
        slideTemplate: template,
      };
    }

    case SET_SHOW_EDIT_MEETING_TEMPLATE_FORM: {
      return {
        ...state,
        showMeetingTemplateForm: action.show,
      };
    }

    case TOGGLE_PUBLISH_MEETING_TEMPLATE: {
      return {
        ...state,
      };
    }

    case RECEIVE_SLIDE_TEMPLATE: {
      action.template.layout = JSON.parse(action.template.layout);
      let meetingTemplate = _.cloneDeep(state.meetingTemplate);
      let meetingTemplates = _.cloneDeep(state.meetingTemplates);

      if (state.slideTemplate && state.slideTemplate.estimatedDuration !== action.template.estimatedDuration) {
        // duration changed -> update section & meeting
        if (state.slideTemplate.estimatedDuration < action.template.estimatedDuration) {
          meetingTemplate.sections.find((s) => s.id === action.template.meetingTemplateSectionId).duration +=
            action.template.estimatedDuration - state.slideTemplate.estimatedDuration;
          meetingTemplate.sumDuration += action.template.estimatedDuration - state.slideTemplate.estimatedDuration;
          meetingTemplates.find((m) => m.id === meetingTemplate.id).sumDuration +=
            action.template.estimatedDuration - state.slideTemplate.estimatedDuration;
        } else {
          meetingTemplate.sections.find((s) => s.id === action.template.meetingTemplateSectionId).duration -=
            state.slideTemplate.estimatedDuration - action.template.estimatedDuration;
          meetingTemplate.sumDuration -= state.slideTemplate.estimatedDuration - action.template.estimatedDuration;
          meetingTemplates.find((m) => m.id === meetingTemplate.id).sumDuration -=
            state.slideTemplate.estimatedDuration - action.template.estimatedDuration;
        }

        meetingTemplate.sections.find((s) => s.id === action.template.meetingTemplateSectionId).slides = meetingTemplate.sections
          .find((s) => s.id === action.template.meetingTemplateSectionId)
          .slides.map((s) => {
            if (s.positionInSection === action.template.positionInSection) return action.template;
            else return s;
          });

          meetingTemplate.expandedNodes = action.expandedNodes;

        return {
          ...state,
          loadingMeetingTemplate: false,
          slideTemplate: action.template,
          meetingTemplate: meetingTemplate,
          meetingTemplates: meetingTemplates,
        };
      } else {
        meetingTemplate.sections.find((s) => s.id === action.template.meetingTemplateSectionId).slides = meetingTemplate.sections
          .find((s) => s.id === action.template.meetingTemplateSectionId)
          .slides.map((s) => {
            if (s.positionInSection === action.template.positionInSection) return action.template;
            else return s;
          });

          meetingTemplate.expandedNodes = action.expandedNodes;

        return {
          ...state,
          loadingMeetingTemplate: false,
          meetingTemplate: meetingTemplate,
          slideTemplate: action.template,
        };
      }
    }

    case DELETE_MEDIA_FROM_TEMPLATE: {
      return {
        ...state,
        loadingMeetingTemplate: true,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
