import * as React from "react";
import { Route, Switch } from "react-router-dom";
import FullLayout from "./components/FullLayout";
import Home from "./components/Home";
import LinkHandler from "./components/slideView/LinkHandler";
import SlideLayout from "./components/slideView/SlideLayoutComponent";
import SlideView from "./components/slideView/SlideViewComponent";
import Meetings from "./components/Meeting/Components/MeetingsComponent";

import "./custom.css";
import "./styles/styles.scss";
import MeetingDetails from "./components/Meeting/Components/MeetingDetails";
import MeetingTemplates from "./components/Administration/MeetingTemplates";
import Presentation from "./components/Presentation/Presentation";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import MeetingMap from "./components/Meeting/Components/MeetingMap";
import EndSlide from "./components/Presentation/EndSlide";
import StartSlidePrint from "./components/Presentation/startSlidePrint";
import EndSlidePrint from "./components/Presentation/EndSlidePrint";
import NotesPrint from "./components/Presentation/NotesPrint";
import AgendaSlide from "./components/Presentation/AgendaSlide";
import AgendaSlidePrint from "./components/Presentation/AgendaSlidePrint";


export default () => (
  <React.Fragment>
    <Route
      render={({ location }) => (
        <>
          {location.pathname.toLowerCase().indexOf("presentation") > -1 ? (
            <FullLayout>
              <TransitionGroup className='full-content-2'>
                <CSSTransition key={location.key} timeout={750} classNames='fade2'>
                  <Switch location={location}>
                    <Route exact path='/presentation/:id/agenda'   component={AgendaSlide} />
                    <Route exact path='/presentation/:id/end'      component={EndSlide} />
                    <Route exact path='/presentation/:id/map'      component={MeetingMap} />
                    <Route exact path='/presentation/:id/:slideId' component={SlideLayout} />
                    <Route exact path='/presentation/:id'          component={Presentation} />
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
            </FullLayout>
          ) : location.pathname.toLowerCase().indexOf("/slide/")      > -1 ||
              location.pathname.toLowerCase().indexOf("/start")       > -1 ||
              location.pathname.toLowerCase().indexOf("/agendaprint") > -1 ||
              location.pathname.toLowerCase().indexOf("/endprint")    > -1 ||
              location.pathname.toLowerCase().indexOf("/notesprint")  > -1  ? (
            <>
              <Route exact path='/notesprint/:id' component={NotesPrint} />
              <Route exact path='/slide/:id'      component={SlideView} />
              <Route exact path='/start'          component={StartSlidePrint} />
              <Route exact path='/agendaprint'    component={AgendaSlidePrint} />
              <Route exact path='/endprint'       component={EndSlidePrint} />
            </>
          ) : (
            <FullLayout>
              <TransitionGroup className='full-content'>
                <CSSTransition key={location.key} timeout={300} classNames='fade'>
                  <Switch location={location}>
                    <Route exact path='/' component={Home} />
                    <Route path='/linkHandler/:id/:meetingId' component={LinkHandler} />
                    <Route path='/admin/meetingTemplates' component={MeetingTemplates} />
                    <Route path='/meetings' component={Meetings} />
                    <Route path='/meeting/:id' component={MeetingDetails} />
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
            </FullLayout>
          )}
        </>
      )}
    />
  </React.Fragment>
);
