import React, { useEffect, forwardRef } from "react";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import * as MeetingsStore from "../../../store/Meeting/MeetingsStore";
import MaterialTable from "material-table";
import GetApp from "@material-ui/icons/GetApp";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Notes from "@material-ui/icons/Notes";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Search from "@material-ui/icons/Search";
import Clear from "@material-ui/icons/Clear";
import moment from "moment";
import Remove from "@material-ui/icons/Remove";
import Check from "@material-ui/icons/Check";

const DownLoadIcon = withStyles({
  root: { color: "#0066FF" },
})(GetApp);

const DownLoadIconDisabled = withStyles({
  root: { color: "#d4d7dc" },
})(GetApp);

const DownLoadNotesIcon = withStyles({
  root: { color: "#0066FF" },
})(Notes);

const DownLoadNotesIconDisabled = withStyles({
  root: { color: "#d4d7dc" },
})(Notes);

const tableCols = [
  {
    title: "Kunde",
    field: "customerName",
  },
  {
    title: "Møtemal",
    field: "meetingTemplateName",
    editable: "never",
  },
  {
    title: "Dato",
    field: "meetingDate",
    render: rowData => moment(rowData.meetingDate).format("DD.MM.YYYY HH:mm")
  },
];

const tableIcons = {
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Remove: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
};

const PastMeetings = ({ meetings, requestPastMeetings, deleteMeeting }) => {
  useEffect(() => {
    requestPastMeetings();
    // eslint-disable-next-line
  }, []);

  const goToPdf = (id) => {
    window.open(process.env.REACT_APP_API + "/meetings/pdf/" + id, "_blank");
  };

  const goToNotesPdf = (id) => {
    window.open(process.env.REACT_APP_API + "/meetings/pdfnotes/" + id, "_blank");
  };

  const deleteMeetingHandler = (id) => {
    deleteMeeting(id);
  }

  return (
    <>
      <div style={{ marginTop: "30px" }}>
        <MaterialTable
          title='Tidligere møter'
          columns={tableCols}
          icons={tableIcons}
          data={meetings}
          options={{ paging: true, search: true, actionsColumnIndex: -1 }}
          localization={{
            header: { actions: "" },
            body: { emptyDataSourceMessage: "Ingen møter", editRow: { deleteText: "Er du sikker på at du vil slette møtet?" } },
            pagination: { labelDisplayedRows: "{from}-{to} av {count}", labelRowsSelect: "rader" },
            toolbar: {searchPlaceholder: "Søk", searchTooltip: "Søk"}
          }}
          editable={{
            onRowDelete: (oldData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  deleteMeetingHandler(oldData.id);
                }, 600);
              }),
          }}
          actions={[
            (rowData) => ({
              icon: !rowData.pdfDone ? DownLoadIconDisabled : DownLoadIcon,
              disabled: !rowData.pdfDone,
              tooltip: !rowData.pdfDone ? "Pdf er ikke ferdig produsert" : "Last ned pdf",
              onClick: (event, rowData) => goToPdf(rowData.id),
            }),
            (rowData) => ({
              icon: !rowData.pdfDone ? DownLoadNotesIconDisabled : DownLoadNotesIcon,
              disabled: !rowData.pdfDone,
              tooltip: !rowData.pdfDone ? "Pdf er ikke ferdig produsert" : "Last ned notater",
              onClick: (event, rowData) => goToNotesPdf(rowData.id),
            })
          ]}
        />
      </div>
    </>
  );
};

export default connect(
  (state) => ({
    meetings: state.meetings.pastMeetings,
  }),
  Object.assign(MeetingsStore.actionCreators)
)(PastMeetings);
