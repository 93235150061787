import { Action, Reducer } from "redux";
import { AppThunkAction } from "..";
import { SlideModel, SlideTypeModel, MediaModel, ChartTypeModel, BulletPoint } from "../models/Slide";
import api from "../../utils/api";

// STATE
export interface SlideState {
  isLoading: boolean;
  currentSlideId: number | undefined;
  currentSlide: SlideModel;
  slideTypes: SlideTypeModel[];
  images: MediaModel[];
  videos: MediaModel[];
  chartTypes: ChartTypeModel[];
}

// TYPES
enum Types {
  //root
  REQUEST_SLIDE = "REQUEST_SLIDE",
  RECEIVE_SLIDE = "RECEIVE_SLIDE",
  CREATE_SLIDE = "CREATE_SLIDE",
  UPDATE_SLIDE = "UPDATE_SLIDE",
  DELETE_SLIDE = "DELETE_SLIDE",
  //slide-type
  REQUEST_ALL_SLIDE_TYPES = "REQUEST_ALL_SLIDE_TYPES",
  RECEIVE_ALL_SLIDE_TYPES = "RECEIVE_ALL_SLIDE_TYPES",
  SET_CURRENT_SLIDE_TYPE = "SET_CURRENT_SLIDE_TYPE",
  //image
  REQUEST_ALL_IMAGES = "REQUEST_ALL_IMAGES",
  RECEIVE_ALL_IMAGES = "RECEIVE_ALL_IMAGES",
  SET_CURRENT_IMAGE = "SET_CURRENT_IMAGE",
  //video
  REQUEST_ALL_VIDEOS = "REQUEST_ALL_VIDEOS",
  RECEIVE_ALL_VIDEOS = "RECEIVE_ALL_VIDEOS",
  SET_CURRENT_VIDEO = "SET_CURRENT_VIDEO",
  //chart-type
  REQUEST_ALL_CHART_TYPES = "REQUEST_ALL_CHART_TYPES",
  RECEIVE_ALL_CHART_TYPES = "RECEIVE_ALL_CHART_TYPES",
  SET_CURRENT_CHART_TYPE = "SET_CURRENT_CHART_TYPE",
  //info-text(s)
  SET_HEADER = "SET_HEADER",
  SET_SUB_HEADER = "SET_SUB_HEADER",
  SET_PARAGRAPH = "SET_PARAGRAPH",
  //bulletPoints
  ADD_BULLET_POINT = "ADD_BULLET_POINT",
  REMOVE_BULLET_POINT = "REMOVE_BULLET_POINT",
  SET_BULLET_POINT_VALUE = "SET_BULLET_POINT_VALUE",
  //userInputFields
  ENABLE_INPUT_FIELD = "ENABLE_INPUT_FIELD",
  DISABLE_INPUT_FIELD = "DISABLE_INPUT_FIELD",
  SET_USER_INPUT_FIELD_LABEL = "SET_USER_INPUT_FIELD_LABEL"
}
//#region ACTIONS
//#region Slide
interface RequestSlideAction {
  type: Types.REQUEST_SLIDE;
  slideId: number;
}

interface ReceiveSlideAction {
  type: Types.RECEIVE_SLIDE;
  slide: SlideModel;
}

interface PostSlideAction {
  type: Types.CREATE_SLIDE;
}

interface UpdateSlideAction {
  type: Types.UPDATE_SLIDE;
}

interface DeleteSlideAction {
  type: Types.DELETE_SLIDE;
}
//#endregion Slide

//#region SlideType
interface RequestAllSlideTypesAction {
  type: Types.REQUEST_ALL_SLIDE_TYPES;
}

interface ReceiveAllSlideTypesAction {
  type: Types.RECEIVE_ALL_SLIDE_TYPES;
  slideTypes: SlideTypeModel[];
}

interface SetCurrentSlideTypeAction {
  type: Types.SET_CURRENT_SLIDE_TYPE;
  // updateSlide: SlideModel;
  currentSlideType: SlideTypeModel;
}
//#endregion SlideType

//#region Image
interface RequestAllImagesAction {
  type: Types.REQUEST_ALL_IMAGES;
}

interface ReceiveAllImagesAction {
  type: Types.RECEIVE_ALL_IMAGES;
  images: MediaModel[];
}

interface SetCurrentImageAction {
  type: Types.SET_CURRENT_IMAGE;
  selected: MediaModel | undefined;
}
//#endregion Image

//#region Video
interface RequestAllVideosAction {
  type: Types.REQUEST_ALL_VIDEOS;
}

interface ReceiveAllVideosAction {
  type: Types.RECEIVE_ALL_VIDEOS;
  videos: MediaModel[];
}

interface SetCurrentVideoAction {
  type: Types.SET_CURRENT_VIDEO;
  selected: MediaModel | undefined;
}
//#endregion Video

//#region ChartType
interface RequestAllChartTypesAction {
  type: Types.REQUEST_ALL_CHART_TYPES;
}

interface ReceiveAllChartTypesAction {
  type: Types.RECEIVE_ALL_CHART_TYPES;
  chartTypes: ChartTypeModel[];
}

interface SetCurrentChartTypeAction {
  type: Types.SET_CURRENT_CHART_TYPE;
  selected: ChartTypeModel;
}
//#endregion ChartType

//#region InfoText
interface SetHeaderAction {
  type: Types.SET_HEADER;
  change: string;
}

interface SetSubHeaderAction {
  type: Types.SET_SUB_HEADER;
  change: string;
}

interface SetParagraphAction {
  type: Types.SET_PARAGRAPH;
  change: string;
}
//#endregion InfoText

//#region BulletPoints
interface AddBulletpointAction {
  type: Types.ADD_BULLET_POINT;
  bulletPoint: BulletPoint;
}

interface RemoveBulletpointAction {
  type: Types.REMOVE_BULLET_POINT;
  bulletPoint: BulletPoint;
}

interface BulletPointValueInputAction {
  type: Types.SET_BULLET_POINT_VALUE;
  bulletPoint: BulletPoint;
  change: string;
}
//#endregion BulletPoints

//#region UserInputField(s)
interface EnableInputFieldAction {
  type: Types.ENABLE_INPUT_FIELD;
}

interface DisableInputFieldAction {
  type: Types.DISABLE_INPUT_FIELD;
}

interface InputFieldLabelInputAction {
  type: Types.SET_USER_INPUT_FIELD_LABEL;
  bulletPoint: BulletPoint;
  change: string;
}
//#endregion UserInputField(s)

//#endregion ACTION

// KNOWN ACTIONS
export type KnownAction =
  | RequestSlideAction
  | ReceiveSlideAction
  | PostSlideAction
  | UpdateSlideAction
  | DeleteSlideAction
  | RequestAllSlideTypesAction
  | ReceiveAllSlideTypesAction
  | SetCurrentSlideTypeAction
  | RequestAllImagesAction
  | ReceiveAllImagesAction
  | SetCurrentImageAction
  | RequestAllVideosAction
  | ReceiveAllVideosAction
  | SetCurrentVideoAction
  | RequestAllChartTypesAction
  | ReceiveAllChartTypesAction
  | SetCurrentChartTypeAction
  | SetHeaderAction
  | SetSubHeaderAction
  | SetParagraphAction
  | AddBulletpointAction
  | RemoveBulletpointAction
  | BulletPointValueInputAction
  | EnableInputFieldAction
  | DisableInputFieldAction
  | InputFieldLabelInputAction;

const skipVideos = true; //TODO: When any videos remove.

// ACTION CREATORS
export const actionCreators = {
  //#region Slide
  requestSlide: (slideId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const appState = getState();
    if (appState && appState.slides && appState.slides.currentSlide && !appState.slides.currentSlide.loading) {
      const url = `${process.env.REACT_APP_API}/slides/${slideId}`;
      api
        .get(url)
        .then((response) => response.data)
        .then((data) => {
          dispatch({ type: Types.RECEIVE_SLIDE, slide: data });
        });
      dispatch({
        type: Types.REQUEST_SLIDE,
        slideId: slideId
      });
    }
  },
  handleSubmit: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const state = getState();
    const slide = state.slides.currentSlide;
    if (slide.id && slide.id > 0) {
      const url = `${process.env.REACT_APP_API}/slides/${slide.id}`;
      api
        .put(url, slide)
        .then((response) => response.data)
        .then((data) => {
          dispatch({ type: Types.RECEIVE_SLIDE, slide: data });
        });
      dispatch({ type: Types.UPDATE_SLIDE });
    } else {
      //create
      const url = `${process.env.REACT_APP_API}/slides`;
      api
        .post(url, slide)
        .then((response) => response.data)
        .then((data) => {
          dispatch({ type: Types.RECEIVE_SLIDE, slide: data });
        });
      dispatch({
        type: Types.CREATE_SLIDE
      });
    }
  },
  deleteSlide: (slide: SlideModel): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const appState = getState();
    if (appState && appState.slides && appState.slides.currentSlide) {
      const url = `${process.env.REACT_APP_API}/slides/${slide.id}`;
      api
        .delete(url)
        .then((response) => response.data)
        .then((data) => {
          //TODO: navigate back....
        });
      dispatch({ type: Types.DELETE_SLIDE });
    }
  },
  //#endregion Slide

  //#region SlideType
  requestAllSlideTypes: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const appState = getState();
    if (appState.slides.slideTypes.length === 0) {
      const apiUrl = `${process.env.REACT_APP_API}/slideType`;
      api
        .get(apiUrl)
        .then((response) => response.data)
        .then((data) => {
          dispatch({
            type: Types.RECEIVE_ALL_SLIDE_TYPES,
            slideTypes: data
          });
        });
      dispatch({ type: Types.REQUEST_ALL_SLIDE_TYPES });
    }
  },
  setCurrentSlideType: (selectedSlideType: SlideTypeModel): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const state = getState();
    if (state && state.slides && state.slides.currentSlide) {
      dispatch({
        type: Types.SET_CURRENT_SLIDE_TYPE,
        currentSlideType: selectedSlideType
      });
    }
  },
  //#endregion SlideType

  //#region Image
  requestAllImages: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const state = getState();
    if (state.slides.images && state.slides.images.length === 0) {
      const url = `${process.env.REACT_APP_API}/media/images`;
      api
        .get(url)
        .then((response) => response.data)
        .then((data) => {
          const images = data ? data : [];
          dispatch({ type: Types.RECEIVE_ALL_IMAGES, images: images });
        });
      dispatch({ type: Types.REQUEST_ALL_IMAGES });
    }
  },
  setCurrentImage: (selectedImage: MediaModel): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const state = getState();
    const currentImage = state.slides.currentSlide.image;
    //toggle logic
    const imageToSet = currentImage === undefined || currentImage.id !== selectedImage.id ? selectedImage : undefined;
    dispatch({
      type: Types.SET_CURRENT_IMAGE,
      selected: imageToSet
    });
  },
  //#endregion Image

  //#region Video
  requestAllVideos: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const state = getState();
    if (state.slides.videos.length === 0 && !skipVideos) {
      const url = `${process.env.REACT_APP_API}/media/videos`;
      api
        .get(url)
        .then((response) => response.data as Promise<MediaModel[]>)
        .then((data) => {
          const videos = data ? data : [];
          dispatch({ type: Types.RECEIVE_ALL_VIDEOS, videos: videos });
        });
      dispatch({ type: Types.REQUEST_ALL_VIDEOS });
    }
  },
  setCurrentVideo: (selectedVideo: MediaModel): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const state = getState();
    const currentVideo = state.slides.currentSlide.video;
    const videoToSet = currentVideo === undefined || currentVideo.id !== selectedVideo.id ? selectedVideo : undefined;
    dispatch({
      type: Types.SET_CURRENT_VIDEO,
      selected: videoToSet
    });
  },
  //#endregion Video

  //#region ChartType
  requestAllChartTypes: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const state = getState();
    if (state.slides.chartTypes.length === 0) {
      const apiUrl = `${process.env.REACT_APP_API}/chartType`;
      api
        .get(apiUrl)
        .then((response) => response.data as Promise<ChartTypeModel[]>)
        .then((data) => {
          dispatch({
            type: Types.RECEIVE_ALL_CHART_TYPES,
            chartTypes: data
          });
        });
      dispatch({ type: Types.REQUEST_ALL_CHART_TYPES });
    }
  },
  setCurrentChartType: (selectedChartType: ChartTypeModel): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const state = getState();
    const currentSlideType = state.slides.currentSlide.slideType;
    if (!currentSlideType || currentSlideType.id !== selectedChartType.id) {
      dispatch({
        type: Types.SET_CURRENT_CHART_TYPE,
        selected: selectedChartType
      });
    }
  },
  //#endregion ChartType

  //#region InfoText
  onChangeHeader: (change: string): AppThunkAction<KnownAction> => (dispatch) => {
    dispatch({ type: Types.SET_HEADER, change });
  },
  onChangeSubHeader: (change: string): AppThunkAction<KnownAction> => (dispatch) => {
    dispatch({ type: Types.SET_SUB_HEADER, change });
  },
  onChangeParagraph: (change: string): AppThunkAction<KnownAction> => (dispatch) => {
    dispatch({ type: Types.SET_PARAGRAPH, change });
  },
  //#endregion InfoText

  //#region BulletPoints
  addBulletpoint: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const state = getState();
    const list = state.slides.currentSlide.bulletPoints;
    const allFilled = list.find((bp) => bp.value === "");
    const notUppeLimit = list.length < 10;
    if (!allFilled && notUppeLimit) {
      const newPos =
        list.length > 0
          ? Math.max.apply(
              Math,
              list.map((i) => {
                return i.position;
              })
            ) + 1
          : 0;
      const newBulletPoint = getNewBulletPoint(newPos);
      dispatch({
        type: Types.ADD_BULLET_POINT,
        bulletPoint: newBulletPoint
      });
    }
  },
  removeBulletpoint: (bulletpoint: BulletPoint): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const state = getState();
    const found = state.slides.currentSlide.bulletPoints.find((bp) => bp.id === bulletpoint.id);
    if (found) {
      dispatch({ type: Types.REMOVE_BULLET_POINT, bulletPoint: found });
    }
  },
  handleBulletPointTextChange: (id: number, change: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const state = getState();

    const found = state.slides.currentSlide.bulletPoints.find((point) => point.id === id);
    if (found) {
      dispatch({
        type: Types.SET_BULLET_POINT_VALUE,
        bulletPoint: found,
        change: change
      });
    }
  },
  handleBulletPointNumberChange: (id: number, change: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const state = getState();
    const found = state.slides.currentSlide.bulletPoints.find((point: BulletPoint) => point.id === id);
    const numberTester = RegExp(/^[0-9]*$/g);
    const isNumber = numberTester.test(change);
    if (found && isNumber) {
      dispatch({
        type: Types.SET_BULLET_POINT_VALUE,
        bulletPoint: found,
        change: change
      });
    }
  },
  //#endregion BulletPoints

  //#region UserInputField(s)
  toggleUserInputField: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const state = getState();
    if (state.slides.currentSlide.inputFields.length === 0) {
      dispatch({ type: Types.ENABLE_INPUT_FIELD });
    } else {
      dispatch({ type: Types.DISABLE_INPUT_FIELD });
    }
  },
  handleInputFieldChange: (id: number, change: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const state = getState();
    const found = state.slides.currentSlide.inputFields.find((field: BulletPoint) => field.id === id);
    if (found) {
      dispatch({
        type: Types.SET_USER_INPUT_FIELD_LABEL,
        bulletPoint: found,
        change: change
      });
    }
  }
  //#endregion UserInputField(s)
};

//#region HELPERS
var internalId = -1;

function getNewBulletPoint(pos: number, isInputField: boolean = false): BulletPoint {
  return {
    id: internalId--,
    value: "",
    inputField: isInputField,
    label: "",
    position: pos
  };
}
//#endregion HELPERS

// REDUCER
const unloadedState: SlideState = {
  currentSlide: {
    id: -1,
    slideGroupId: 1,
    slideType: undefined,
    positionInSection: 0,
    estimatedDuration: 3,
    image: undefined,
    bulletPoints: [getNewBulletPoint(0)],
    inputFields: [],
    chartType: undefined,
    header: "",
    subHeader: "",
    paragraph: "",
    video: undefined,
    note: undefined,
    dragId: 0,
    loading: false,
    isLastSlide: false,
    isSectionSlide: false,
    mark: false,
    meetingSectionId: undefined,
    layout: undefined
  },
  currentSlideId: undefined,
  slideTypes: [],
  images: [],
  videos: [],
  chartTypes: [],
  isLoading: false
};

export const reducer: Reducer<SlideState> = (state: SlideState | undefined, incomingAction: Action): SlideState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    //#region Slide
    case Types.REQUEST_SLIDE: {
      let currentSlide = JSON.parse(JSON.stringify(state.currentSlide));
      currentSlide.loading = true;
      currentSlide.note = null;
      return {
        ...state,
        currentSlideId: action.slideId,
        currentSlide: currentSlide
      };
    }
    case Types.RECEIVE_SLIDE: {
      let currentSlide = JSON.parse(JSON.stringify(action.slide));
      // currentSlide.layout = JSON.parse(action.slide.layout);
      currentSlide.loading = false;
      return {
        ...state,
        isLoading: false,
        currentSlide: currentSlide
      };
    }
    case Types.CREATE_SLIDE:
      return {
        ...state,
        isLoading: true
      };
    case Types.UPDATE_SLIDE:
      return {
        ...state,
        isLoading: true
      };
    case Types.DELETE_SLIDE:
      return {
        ...state,
        isLoading: true
      };
    //#endregion Slide

    //#region SlideType
    case Types.REQUEST_ALL_SLIDE_TYPES: {
      return {
        ...state,
        isLoading: true
      };
    }
    case Types.RECEIVE_ALL_SLIDE_TYPES: {
      return {
        ...state,
        isLoading: false,
        slideTypes: [...action.slideTypes]
      };
    }
    case Types.SET_CURRENT_SLIDE_TYPE: {
      return {
        ...state,
        currentSlide: Object.assign(
          { ...state.currentSlide },
          {
            slideType: action.currentSlideType,
            bulletPoints: [getNewBulletPoint(0)],
            inputFields: []
          }
        )
      };
    }
    //#endregion SlideType

    //#region Image
    case Types.SET_CURRENT_IMAGE:
      return {
        ...state,
        currentSlide: Object.assign(
          { ...state.currentSlide },
          {
            image: action.selected
          }
        )
      };
    case Types.REQUEST_ALL_IMAGES:
      return {
        ...state,
        isLoading: true
      };
    case Types.RECEIVE_ALL_IMAGES:
      return {
        ...state,
        isLoading: false,
        images: action.images
      };
    //#endregion Image

    //#region Video
    case Types.REQUEST_ALL_VIDEOS:
      return {
        ...state,
        isLoading: true
      };
    case Types.RECEIVE_ALL_VIDEOS:
      return {
        ...state,
        isLoading: false,
        videos: action.videos
      };
    case Types.SET_CURRENT_VIDEO:
      return {
        ...state,
        currentSlide: Object.assign(
          { ...state.currentSlide },
          {
            video: action.selected
          }
        )
      };
    //#endregion Video

    //#region ChartType
    case Types.REQUEST_ALL_CHART_TYPES:
      return {
        ...state,
        isLoading: true
      };
    case Types.RECEIVE_ALL_CHART_TYPES:
      return {
        ...state,
        isLoading: false,
        chartTypes: action.chartTypes
      };
    case Types.SET_CURRENT_CHART_TYPE:
      return {
        ...state,
        currentSlide: Object.assign(
          { ...state.currentSlide },
          {
            chartType: action.selected
          }
        )
      };
    //#endregion ChartType

    //#region Header
    case Types.SET_HEADER:
      return {
        ...state,
        currentSlide: Object.assign(
          { ...state.currentSlide },
          {
            header: action.change
          }
        )
      };
    case Types.SET_SUB_HEADER:
      return {
        ...state,
        currentSlide: Object.assign(
          { ...state.currentSlide },
          {
            subHeader: action.change
          }
        )
      };
    case Types.SET_PARAGRAPH:
      return {
        ...state,
        currentSlide: Object.assign(
          { ...state.currentSlide },
          {
            paragraph: action.change
          }
        )
      };
    //#endregion Header

    //#region BulletPoints
    case Types.ADD_BULLET_POINT: {
      const list = [...state.currentSlide.bulletPoints, action.bulletPoint];
      return {
        ...state,
        currentSlide: Object.assign(
          { ...state.currentSlide },
          {
            bulletPoints: list
          }
        )
      };
    }
    case Types.REMOVE_BULLET_POINT: {
      const list = state.currentSlide.bulletPoints.filter((bp) => bp.id !== action.bulletPoint.id);
      return {
        ...state,
        currentSlide: Object.assign(
          { ...state.currentSlide },
          {
            bulletPoints: list
          }
        )
      };
    }
    case Types.SET_BULLET_POINT_VALUE: {
      action.bulletPoint.value = action.change;
      const list = [...state.currentSlide.bulletPoints];
      return {
        ...state,
        currentSlide: Object.assign(
          { ...state.currentSlide },
          {
            bulletPoints: list
          }
        )
      };
    }
    //#endregion BulletPoints
    case Types.ENABLE_INPUT_FIELD: {
      const list = [getNewBulletPoint(0, true)];
      return {
        ...state,
        currentSlide: Object.assign(
          { ...state.currentSlide },
          {
            inputFields: list
          }
        )
      };
    }
    case Types.DISABLE_INPUT_FIELD: {
      const list: BulletPoint[] = [];
      return {
        ...state,
        currentSlide: Object.assign(
          { ...state.currentSlide },
          {
            inputFields: list
          }
        )
      };
    }
    case Types.SET_USER_INPUT_FIELD_LABEL: {
      action.bulletPoint.label = action.change;
      const list = [...state.currentSlide.inputFields];
      return {
        ...state,
        currentSlide: Object.assign(
          { ...state.currentSlide },
          {
            inputFields: list
          }
        )
      };
    }
    //#region UserInputFields
  }

  return state;
};
