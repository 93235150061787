import React from "react";
import moment from "moment";
import "moment/locale/nb";

const StartSlidePrint = (props) => {
  const queryString = require("query-string");
  const parsed = queryString.parse(props.location.search);

  let office = parsed.office;
  let userTitle = parsed.title;
  let username = parsed.username;
  let email = parsed.email;
  let phone = parsed.phone;
  let frontText = parsed.frontText;
  let customerName = parsed.customer;
  let meetingDate = parsed.meetingDate;
  
  return (
    <>
      <div style={{ backgroundColor: "#01052F", color: "#fff", width: "100vw", height: "100vh", display: "flex", zIndex: 2, position: "absolute" }}>
        <div className='start-slide-left'>
          <div className='start-slide-left-logo'>
            <img src={require("../../img/logo-start.png")} alt='' style={{ width: "22%", height: "auto", display: "flex" }} />
          </div>
          <div className='start-slide-left-middle'>
            <div className='start-slide-left-text'>
              Velkommen
              <br />
              {frontText ? frontText : customerName}
            </div>
          </div>
          <div className='start-slide-left-bottom'>
            <div className='start-slide-left-signature'>
              <p>
                {office} {moment(meetingDate).format("D")}. {moment(meetingDate).format("MMMM")} {moment(meetingDate).format("YYYY")}
                <br></br>
                {username + ", " + userTitle}
                <br></br>
                {email}, tlf. {phone}
                <br></br>
              </p>
            </div>
          </div>
        </div>
        <div className='start-slide-right'>
          <div style={{ display: "flex", flexDirection: "column", height: "100%", width: "50%" }}>
            <div className='start-slide-right-1'></div>
            <div className='start-slide-right-1-bottom'></div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", height: "100%", width: "50%" }}>
            <div className='start-slide-right-2'></div>
            <div className='start-slide-right-2-middle'></div>
            <div className='start-slide-right-2-bottom'></div>
          </div>
        </div>
      </div>
      <div className='logo-footer' style={{ zIndex: 2 }}>
        <img alt='logo' src={require("../../img/logo.png")} width='60px' height='60px' />
      </div>
    </>
  );
};

export default StartSlidePrint;
