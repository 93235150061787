import DateFnsUtils from "@date-io/date-fns";
import { Button, createStyles, FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as MeetingsStore from "../../../store/Meeting/MeetingsStore";
import * as TemplateStore from "../../../store/TemplateStore";
import { List, ListItem, IconButton, ListItemIcon, ListItemText, Tooltip, ListItemSecondaryAction, Dialog, DialogTitle, DialogContent, DialogActions  } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import TextFieldsIcon from '@material-ui/icons/TextFields';

const useStyles = makeStyles((theme) =>
  createStyles({
    newMeetingContent: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
    },
    col1: {
      display: "flex",
      flex: "10",
    },
    col2: {
      display: "flex",
      flex: "10",
    },
    col3: {
      display: "flex",
      flex: "5",
    },
    col4: {
      display: "flex",
      flex: "5",
    },
    col5: {
      display: "flex",
      flex: "10",
      alignItems: "flex-start",
    },
    spacer: {
      display: "flex",
      flex: "2 2",
    },
    fullWidth: {
      minWidth: "100%",
    },
    meetingTemplatePicker: {
      marginTop: "14px !important",
    },
  })
);

function getModalStyle() {
  return {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#ffffff',
    padding: '10px 10px 10px',
  };
}

const NewMeetingComponent = ({ templates, createMeeting, requestPublishedMeetingTemplates }) => {
  const classes = useStyles({});

  useEffect(() => {
    requestPublishedMeetingTemplates();
    // eslint-disable-next-line
  }, []);



  function getInitDate() {
    const date = new Date();
    switch (date.getDay()) {
      case 5:
        date.setHours(date.getHours() + 24 * 3);
        break;
      case 6:
        date.setHours(date.getHours() + 24 * 2);
        break;
      default:
        date.setHours(date.getHours() + 24);
        break;
    }
    return date;
  }

  const [selectedDate, setSelectedDate] = useState(getInitDate());
  const [selectedMeetingTemplate, setSelectedTemplate] = useState(0);
  const [customerName, setCustomerName] = useState("");
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [currentAgendaItems, setAgendaItems] = useState([]);
  const [currentAgendaItem, setAgendaItem] = useState("");

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleMeetingTemplateChange = (e) => {
    setSelectedTemplate(e.target.value);
  };

  const handleCustomerNameChange = (e) => {
    setCustomerName(e.target.value);
  };

  const createNewMeeting = (e) => {
    createMeeting(selectedMeetingTemplate, customerName, selectedDate, currentAgendaItems);

    // state cleanup
    setCustomerName("");
    setAgendaItem("");
    setAgendaItems([]);
    setSelectedDate(getInitDate());
    setSelectedTemplate(0);
  };

  // Agenda modal
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleAgendaItemNameChange = (e) => {
    setAgendaItem(e.target.value);
  };
  const removeAgendaItemByIndex = (index) => {
    setAgendaItems(agendaItems => agendaItems.filter((item, i) => i !== index));
  };

  const addAgendaItem = () => {
    setAgendaItems([...currentAgendaItems, currentAgendaItem]);
    setAgendaItem("");
  };

  function renderDatePicker() {
    return (
      <DateTimePicker
        autoOk
        ampm={false}
        disablePast
        minutesStep={5}
        format='dd.MM.yyyy HH.mm'
        value={selectedDate}
        onChange={handleDateChange}
        label='Dato / Klokkeslett'
      />
    );
  }

  function renderMeetingTemplateSelector() {
    return (
      <>
        <FormControl className={classes.fullWidth}>
          <InputLabel>Møtemal</InputLabel>
          <Select
            className={classes.meetingTemplatePicker}
            value={selectedMeetingTemplate === 0 ? "" : selectedMeetingTemplate}
            onChange={(e) => {
              handleMeetingTemplateChange(e);
            }}>
            {templates &&
              templates.map((template) => {
                return (
                  <MenuItem key={`template_${template.id}`} value={template.id}>
                    {template.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </>
    );
  }
  function renderMeetingAgendaModal() {
    return (
       <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle style={{ width: "450px" }} id='draggable-dialog-title'>
          Dagens møte
        </DialogTitle>
        <DialogContent  >
          <TextField autoFocus label="Agenda for møte" variant="outlined" fullWidth
            value={currentAgendaItem}
            onChange={(e) => handleAgendaItemNameChange(e)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                addAgendaItem()
              }
            }}  />
        </DialogContent>
        <DialogContent>
          <List>
            {currentAgendaItems?.map((agendaItem, index) => (
              <ListItem key={index}>
                <ListItemIcon> <TextFieldsIcon /> </ListItemIcon>
                <ListItemText style={{overflow: 'hidden'}} primary={agendaItem} />
                <ListItemSecondaryAction> <IconButton onClick={() => removeAgendaItemByIndex(index)}> <CloseIcon /> </IconButton> </ListItemSecondaryAction >
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
         <Button variant="contained" className="sbmPrimary" onClick={addAgendaItem}>Legg til</Button>
         <Button variant="contained" className="sbmSecondary" onClick={handleClose}>Lukk</Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <>
      <div style={{ width: "100%" }}>
        <div className='small-title' style={{ paddingTop: "2rem" }}>
          Nytt møte
        </div>
        <div className={classes.newMeetingContent}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <TextField className={classes.col1} value={customerName} onChange={(e) => handleCustomerNameChange(e)} label='Kundenavn'></TextField>
            <div className={classes.spacer}></div>
            <div className={classes.col2}>{renderMeetingTemplateSelector()}</div>
            <div className={classes.spacer}></div>
            <div className={classes.col3}>{renderDatePicker()}</div>
            <div className={classes.spacer}></div>
            <div className={classes.col4}>
              <Tooltip title="Legg til agenda">
                <IconButton className="sbmSecondaryIcon" onClick={handleOpen}> <PlaylistAddIcon /></IconButton>
              </Tooltip>
              {renderMeetingAgendaModal()}
            </div>
            <div className={classes.spacer}></div>
            <div className={classes.col5}>
              <Button style={{height: '100%'}} className='sbmPrimary' variant='contained' onClick={(e) => createNewMeeting(e)} label="Legg til">
                Opprett møte
              </Button>
            </div>
          </MuiPickersUtilsProvider>
        </div>
      </div>
    </>
  );
};

const conn = connect(
  (state) => ({
    templates: state.templates.publishedMeetingTemplates,
  }),
  Object.assign(MeetingsStore.actionCreators, TemplateStore.actionCreators)
)(NewMeetingComponent);

export default conn;
