import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { createStyles } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Fade } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      display: "flex",
      flex: "0 0 100%",
      flexDirection: "row",
      position: "relative",
    },
    iconContainer: {
      margin: "0 auto",
    },
    icon: {
      marginRight: "1rem",
      marginTop: "1rem",
    },
    paragraphContainer: {
      display: "flex",
      flex: "0 0 100%",
      position: "absolute",
    },
  })
);

export const ParagraphComponent2 = ({ text, size, color, weight, fontFamily, fontStyle, alignment, marginLeft, marginRight, top, presentation }) => {
  const [isOpen, toggleOpen] = useState(true);
  const classes = useStyles({});
  function handleClick() {
    toggleOpen(!isOpen);
  }

  return (
    <React.Fragment>
      {/* <div style={{ marginLeft: marginLeft, marginRight: marginRight, top: top }}> */}
        <div className={classes.iconContainer} style={{ marginLeft: marginLeft, marginRight: marginRight, top: top }}>
          {!isOpen && <InfoOutlinedIcon style={{fontSize: presentation ? 85 : 60}} className={`${classes.icon} semi-transparant-white`} onClick={handleClick} />}
        </div>

        <div
          style={{
            textAlign: alignment ? alignment : "left",
            top: top + "%",
            width: 100 - ((marginLeft ? marginLeft : 0) + (marginRight ? marginRight : 0)) + '%',
            paddingLeft: marginLeft ? marginLeft : "0",
            paddingRight: marginRight ? marginRight : "0",
          }}
          onClick={handleClick}>
          {text && isOpen && (
            <Fade in={true} timeout={1000}>
              <p
                className={`semi-transparant-white`}
                style={{
                  fontSize: size,
                  color: color,
                  whiteSpace: "pre-wrap",
                  fontWeight: weight,
                  fontFamily: fontFamily,
                  fontStyle: fontStyle,
                  textAlign: alignment,
                }}>
                {text}
              </p>
            </Fade>
          )}
        </div>
      {/* </div> */}
    </React.Fragment>
  );
}

ParagraphComponent2.defaultProps = {
  text: "",
  presentation: false,
  size: "20px",
  color: "#000000",
  weight: "normal",
  fontFamily: "SBMSansWeb",
  fontStyle: "normal",
  alignment: "left",
  marginLeft: "0%",
  marginRight: "0%",
  top: "0%",
};

