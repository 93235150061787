import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { createStyles } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Fade } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      display: "flex",
      flex: "0 0 100%",
      flexDirection: "row",
      position: "relative",
    },
    iconContainer: {
      margin: "0 auto",
    },
    icon: {
      marginRight: "1rem",
      marginTop: "1rem",
    },
    paragraphContainer: {
      display: "flex",
      flex: "0 0 100%",
      position: "absolute",
    },
  })
);

function ParagraphComponent({ text, size, color, weight, fontFamily, fontStyle, alignment, marginLeft, marginRight, top, presentation, link, newTab }) {
  const [isOpen, toggleOpen] = useState(false);
  const classes = useStyles({});
  function handleClick() {
    toggleOpen(!isOpen);
  }


  return (
    <React.Fragment>
        <div className={classes.iconContainer} style={{ marginLeft: marginLeft, marginRight: marginRight, top: top }}>
          {!isOpen &&  <InfoOutlinedIcon style={{fontSize: presentation ? 85 : 60}} className={`${classes.icon} semi-transparant-white`} onClick={handleClick} />}
        </div>

        <div
          style={{
            textAlign: alignment ? alignment : "left",
            top: top + "%",
            width: 100 - ((marginLeft ? marginLeft : 0) + (marginRight ? marginRight : 0)) + '%',
            paddingLeft: marginLeft ? marginLeft : "0",
            paddingRight: marginRight ? marginRight : "0",
          }}
          onClick={handleClick}>
          { isOpen && link && (
            <Fade in={true} timeout={1000}>
                  <a className={`semi-transparant-white`}
                    href={link}
                    target={newTab ? '_blank' : ''}
                    style={{ fontSize: size, color: color, whiteSpace: "pre-wrap", fontWeight: weight, fontFamily: fontFamily, fontStyle: fontStyle, textAlign: alignment, }}>
                    {text} 
                  </a>
           </Fade>
          )}
          { isOpen && !link && (
            <Fade in={true} timeout={1000}>
              <p className={`semi-transparant-white`}
                 style={{ fontSize: size, color: color, whiteSpace: "pre-wrap", fontWeight: weight, fontFamily: fontFamily, fontStyle: fontStyle, textAlign: alignment, }}>
                {text}
              </p>
           </Fade>
          )}
        </div>
    </React.Fragment>
  );
}

ParagraphComponent.defaultProps = {
  text: "",
  presentation: false,
  size: "20px",
  color: "#000000",
  weight: "normal",
  fontFamily: "SBMSansWeb",
  fontStyle: "normal",
  alignment: "left",
  marginLeft: "0%",
  marginRight: "0%",
  top: "0%",
  link: "",
  newTab: false
};

export default ParagraphComponent;
