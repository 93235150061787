import * as SlidesStore from './slides/SlidesStore';
import * as MeetingsStore from './Meeting/MeetingsStore';
import * as UserStore from './UserStore';
import * as TemplateStore from './TemplateStore';

// The top-level state object
export interface ApplicationState {
    slides: SlidesStore.SlideState;
    meetings: any;
    user: any;
    templates: any;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    slides: SlidesStore.reducer,
    meetings: MeetingsStore.reducer,
    user: UserStore.reducer,
    templates: TemplateStore.reducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
