import React from "react";
import { makeStyles } from "@material-ui/styles";
import { createStyles, Container } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import NewMeeting from "./NewMeetingComponent";
import UpcommingMeetings from "./UpcomingMeetingsComponent";
import PastMeetings from "./PastMeetingsComponent";

const useStyles = makeStyles((theme) =>
  createStyles({
    meetingsContent: {
      display: "flex",
      flexDirection: "column",
      background: "white",
      justifyContent: "space-between",
      width: "100%",
    },
  })
);

const MeetingsComponent = () => {
  const classes = useStyles({});

  return (
    <div className='meetings-main'>
      <Container maxWidth={false}>
        <div className='main-content'>
          <div className={classes.meetingsContent}>
            <div className='header'>Kundemøter</div>
            <NewMeeting />
            <UpcommingMeetings />
            <div style={{ paddingTop: "40px" }}></div>
            <PastMeetings />
          </div>
        </div>
      </Container>
      <div className='logo-footer'>
        <Link className='main-link' component={RouterLink} to='/'>
          <img alt='logo' src={require("../../../img/logo.png")} width='60px' height='60px' />
        </Link>
      </div>
      {localStorage.getItem("username") && localStorage.getItem("username") !== "" ? (
        <div className='user-top-div'>
          {localStorage.getItem("isAdmin") ? <span>{localStorage.getItem("username")}&nbsp;(Administrator)</span> : <></>}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default MeetingsComponent;
