import axios from "axios";

let api = axios.create({
  baseURL: process.env.REACT_APP_API,
  withCredentials: true,
  headers: {'Content-Language': 'no, nb, nn'}
});



export default api;
